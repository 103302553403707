<h3 class="e11-font-primary-demibold">{{ 'Company Logo' | translate }}</h3>
<p>
  {{
    'If a logo is added here, it will appear on the job posts seen by candidates both within the platform and on your public careers page.'
      | translate
  }}
</p>
<e11-panel [hasBorder]="true" [hasShadow]="false">
  <div class="e11-w-full e11-text-center">
    <e11-loader-dots [size]="'lg'" [color]="'e11-bg-skin-secondary'" *ngIf="loading || !customer"></e11-loader-dots>
    <div *ngIf="!loading && customer">
      <!-- Upload image -->
      <div id="imagePreview" class="e11-w-full e11-border e11-border-skin-app-border e11-mb-4">
        <img class="e11-w-full e11-max-h-[400px] e11-object-contain" *ngIf="logoUrl" src="{{ logoUrl }}" />
      </div>

      <e11-button
        *ngIf="logoUrl"
        value="{{ 'View/Edit company photo' | translate }}"
        [style]="'pill outlined'"
        (click)="openUploadModal(uploadLogoModal)"
        [color]="'primary-accent'"
      ></e11-button>

      <e11-empty-state
        *ngIf="!logoUrl"
        [icon]="'file_upload'"
        [iconSize]="'md-36'"
        [iconType]="''"
        title="{{ 'Upload a company logo' | translate }}"
        subtitle="{{ 'Click here to upload company logo' | translate }}"
        (click)="openUploadModal(uploadLogoModal)"
      >
      </e11-empty-state>
    </div>
  </div>
</e11-panel>

<!-- Modal -->
<ng-template #uploadLogoModal>
  <modal-company-logo-upload
    [logoUrl]="logoUrl"
    (logoUploaded)="upload($event); closeModal()"
    (logoRemoved)="removeUploadedFile(); closeModal()"
  ></modal-company-logo-upload>
</ng-template>
