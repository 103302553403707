// Framework
import { Component, OnInit } from '@angular/core'

@Component({
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss'],
})
export class FileNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
