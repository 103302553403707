import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { DegreeLevel } from '../../model/enum'
import { IEducation } from '../../model/interfaces'

@Component({
  selector: 'ui-education-line-item',
  template: `
    <div class="e11-block e11-pb-4">
      <div class="e11-flex">
        <div class="e11-w-3/4">
          <h4 class="">{{ education.schoolName }}</h4>
        </div>
        <div class="e11-w-1/4 e11-flex e11-justify-end">
          <span
            *ngIf="userMode === userModeEnum.Candidate"
            class="e11-flex e11-items-center e11-cursor-pointer e11-text-skin-primary-accent hover:e11-brightness-50 e11-text-sm"
            (click)="viewDetail.emit(education); editView.emit(true)"
          >
            <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary e11-mr-1"> edit </span>
            Edit
          </span>
        </div>
      </div>
      <div class="e11-break-all e11-line-clamp-2">{{ stateToStatus.get(education.degreeLevel) || '' | translate }}</div>
      <div class="e11-break-all e11-line-clamp-2">{{ education.degreeTitle }}</div>
      <div class="e11-break-all e11-line-clamp-2" *ngIf="education.degreeType">{{ education.degreeType }}</div>
      <div *ngIf="education.notCompleted" class="e11-text-sm">*{{ 'Not yet Completed' | translate }}</div>
      <date-display-start-end class="e11-text-skin-light e11-text-sm" [item]="education"> </date-display-start-end>
    </div>
  `,
  styleUrls: ['./ui-education-line-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiEducationLineItemComponent {
  userModeEnum = UserMode

  @Input()
  userMode: UserMode = UserMode.Candidate

  @Input()
  education!: IEducation

  @Input()
  removable: boolean = false

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IEducation>()

  degreeLevel = DegreeLevel

  stateToStatus = new Map<DegreeLevel, string>()
    .set(this.degreeLevel.NotSpecified, myDegreeLevel('Not Specified'))
    .set(this.degreeLevel.HighSchool, myDegreeLevel('High School'))
    .set(this.degreeLevel.VocationalHsDiploma, myDegreeLevel('Vocational High School Diploma'))
    .set(this.degreeLevel.VocationalDegree, myDegreeLevel('Vocational Degree'))
    .set(this.degreeLevel.Associates, myDegreeLevel("Associate's Degree"))
    .set(this.degreeLevel.Bachelors, myDegreeLevel("Bachelor's Degree"))
    .set(this.degreeLevel.Masters, myDegreeLevel("Master's Degree"))
    .set(this.degreeLevel.PhD, myDegreeLevel('Doctorate/PhD'))
}

const myDegreeLevel = (str: string) => `${str}`
