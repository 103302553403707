import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { VirtualDialogueSubmittedNotificationForEmployer } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class VirtualDialogueSubmittedEmployerHandler
  implements INotificationHandler<VirtualDialogueSubmittedNotificationForEmployer, INotificationView>
{
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  notificationType = 'virtual-dialogue-submitted-for-employer'

  toViewModel(notification: VirtualDialogueSubmittedNotificationForEmployer): INotificationView {
    return {
      viewed: notification.viewed,
      description: `${notification.candidateName} has sent a Dialogue response for the ${notification.jobTitle} job.`,
      title: 'Dialogue',
      originalNotification: notification,
      logoUrl: '',
    }
  }

  onClick(notification: VirtualDialogueSubmittedNotificationForEmployer) {
    this.markAsRead(notification)
    return this.router.navigate(['/jobs/candidates/', notification.jobPostId], { queryParams: { candidateId: notification.candidateApplicationId } })
  }

  private markAsRead(notification: VirtualDialogueSubmittedNotificationForEmployer) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
