import { Injectable } from '@angular/core'
import { environment } from '@employer/environments/environment'
import {
  BaseConversationCustomDataProvider,
  IConversationVMNoCustomData,
  MessagingConfigurationProvider,
  MessagingMappingConfig,
} from '@engineering11/messaging-web'
import { Store } from '@ngrx/store'
import { getCustomerKey$, getCurrentToken, getCurrentUserId } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class MessagingConfigurationStore {
  constructor(private store: Store) {}

  getConfig() {
    return {
      baseUrl: environment.services.messaging,
      baseRoute: '/c/m',
      token$: this.store.select(getCurrentToken),
      userId$: this.store.pipe(getCurrentUserId),
    }
  }
}
@Injectable({ providedIn: 'root' })
export class MessagingConfigFromApp implements MessagingConfigurationProvider {
  constructor(private configStore: MessagingConfigurationStore) {}

  get config(): MessagingMappingConfig {
    return this.configStore.getConfig()
  }
}

@Injectable({ providedIn: 'root' })
export class ConversationCustomDataProvider extends BaseConversationCustomDataProvider {
  conversationSubtitle(conversation: IConversationVMNoCustomData): string {
    return conversation.clientMetadata ? conversation.clientMetadata['jobPostName'] : ''
  }
}
