// Framework
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

// Platform specific
import { TokenStorage } from 'shared-lib'

// SDK
import { CustomerService, ICustomerDepartment } from '@engineering11/customer-web'
import { IEmployerUser } from '@cnect/user-shared'

// Third party
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'

@Component({
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class CustomerDepartmentsListComponent implements OnInit {
  departments$?: Observable<Array<ICustomerDepartment>>
  user?: IEmployerUser
  loading: boolean = true

  constructor(private tokenStorage: TokenStorage, private customerService: CustomerService, private router: Router) {}

  ngOnInit(): void {
    this.user = this.tokenStorage.getItem('user')
    if (this.user) {
      this.departments$ = this.customerService.departments.getAll(this.user.customerKey).pipe(finalize(() => (this.loading = false)))
    }
  }

  addDepartment() {
    this.router.navigate(['/customer/departments/detail'])
  }
}
