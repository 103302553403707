<div class="header-container e11-w-full">
  <header class="header-landing e11-container-full e11-mx-auto">
    <div class="e11-w-full e11-flex e11-items-center">
      <div class="e11-w-2/3 e11-flex e11-items-center" [routerLink]="['/home']">
        <app-logo-blue [height]="'64px'"></app-logo-blue>
      </div>
      <div class="col-xs-4 flex-align-center-justify-end" *ngIf="!loggedIn">
        <e11-button
          color="secondary"
          [routerLink]="['/auth/login']"
          [style]="'pill'"
          value="{{ 'Sign In' | translate | lowercase }}"
          [buttonGroup]="true"
        >
        </e11-button>
      </div>
      <div class="e11-w-2/3 e11-flex e11-items-center e11-justify-end" *ngIf="loggedIn">
        <e11-button color="secondary" (click)="signOut()" [style]="'pill'" value="{{ 'Sign Out' | translate | lowercase }}" [buttonGroup]="true">
        </e11-button>
      </div>
    </div>
  </header>
</div>
