<e11-panel [hasBorder]="true" [hasShadow]="false">
  <h3>{{ 'Notification Preferences' | translate }}</h3>
  <div class="e11-w-full e11-flex e11-items-center">
    <ui-switch size="medium" [checked]="userEmailEnabled" (valueChange)="toggleNotificationEmail(userEmailEnabled)"> </ui-switch>
    <div class="message-container">
      <div class="message" [ngClass]="{ 'on active': userEmailEnabled }">
        {{ 'Email notifications will be sent to the email associated with your account' | translate }}
      </div>
      <div class="message" [ngClass]="{ 'off active': !userEmailEnabled }">
        {{ 'Email notifications will NOT be sent to the email associated with your account' | translate }}
      </div>
    </div>
  </div>
</e11-panel>
