<div class="row">
  <div class="col-xs-12 text-center">
    <div class="avatar-upload">
      <div class="avatar-preview" [ngClass]="{ loading: loading }">
        <ui-loader-line *ngIf="loading" style="position: absolute; width: 100%" [size]="'small'"> </ui-loader-line>
        <span class="material-icons e11-text-6xl" *ngIf="!currentPhotoUrl">person</span>
        <div *ngIf="currentPhotoUrl" id="imagePreview" [style.backgroundImage]="'url(' + currentPhotoUrl + ')'"></div>
      </div>
    </div>
  </div>
</div>

<div class="e11-w-full">
  <div class="e11-mb-4 e11-px-6 lg:e11-px-16">
    <input class="input-file-uploader" type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="addNewPhoto($event)" />
    <e11-button
      id="button_upload"
      value="{{ loading ? 'Loading...' : ('Upload from computer' | translate) }}"
      color="primary-accent"
      [type]="'button'"
      [style]="'pill outlined'"
      [icon]="'file_upload'"
      [iconPosition]="'left'"
      (click)="fileInput.click()"
      [disabled]="loading"
      [display]="'block'"
    >
    </e11-button>
  </div>
  <div class="e11-mb-1 e11-px-6 lg:e11-px-16">
    <e11-button
      id="button_submit"
      value="{{ 'Remove photo' | translate }}"
      color="secondary"
      [type]="'button'"
      [style]="'pill outlined'"
      [icon]="'cancel'"
      [iconPosition]="'left'"
      (click)="removeCurrentPhoto()"
      [disabled]="!currentPhotoUrl || loading"
      [display]="'block'"
    >
    </e11-button>
  </div>
</div>
