import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable, of } from 'rxjs'
import { environment } from '@employer/environments/environment'

@Injectable({ providedIn: 'root' })
export class EmailActionsGuard implements CanActivate {
  constructor(private router: Router) {}

  /**
   * This is here to reroute the user if they ended up in the wrong place to deal with any email actions
   * (verify email, reset password) we also have a cloudflare rule that should not allow users to get here
   * but it here as a last resort or for lower environments that don't have cloudflare workers implemented.
   *
   * The requirement of having this stems from the limitation on firebase auth email templates where there is only
   * one option to set one single action url and we have 2 apps as of now, probably more later.
   *
   * @param route
   * @param state
   * @returns
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const queryParamMap = route.queryParamMap
    const tenantId = queryParamMap.get('tenantId')
    const mode = queryParamMap.get('mode')
    const oobCode = queryParamMap.get('oobCode')
    if (tenantId && tenantId !== environment.tenantId) {
      const baseUrl = environment.candidateEmailActionRoute
      if (mode && oobCode) {
        const queryParams = new URLSearchParams({
          mode: mode,
          oobCode: oobCode,
          tenantId: tenantId,
        })
        window.location.href = `${baseUrl}?${queryParams.toString()}`
      }
    }

    return of(true)
  }
}
