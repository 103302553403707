// Framework
import { Injectable } from '@angular/core'
import { AbstractControl, ValidatorFn } from '@angular/forms'
import { E11ErrorHandlerService, toE11Error } from '@engineering11/web-api-error'
import { E11FormValidationService } from '@engineering11/web-utilities'
import { isValid, parse } from 'date-fns'
import { CNECT_ERRORS } from './errors/error-handler.mapping'

@Injectable({
  providedIn: 'root',
})
export class FormCustomValidationService extends E11FormValidationService {
  constructor(private errorHandlerService: E11ErrorHandlerService) {
    super()
  }

  // TODO: call super class methods, catch error, handle

  /**
   * Determines if the date is parsable by our formatting library
   * @param format a format acceptable by date-fns parse method
   * @returns
   */
  dateIsParsable(format: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      try {
        const date = parse(control.value, format, new Date())
        if (!isValid(date)) {
          return {
            NotParsable: true,
          }
        }
        return null
      } catch (error) {
        const e11Error = toE11DateError(error, control.value)
        this.errorHandlerService.handleError(e11Error)
        return {
          ErrorParsing: true,
        }
      }
    }
  }

  dateIsPast(format: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formValue = control.value
      try {
        const date = parse(formValue, format, new Date())
        if (date > new Date()) {
          return { DateFuture: true }
        }

        return null
      } catch (error) {
        const e11Error = toE11DateError(error, control.value)
        this.errorHandlerService.handleError(e11Error)
        return {
          ErrorParsing: true,
        }
      }
    }
  }
}

const toE11DateError = (error: any, dateStr: string) =>
  toE11Error(error, {
    type: CNECT_ERRORS.DATE_UNPARSABLE,
    message: `Error parsing date: ${dateStr}`,
    title: 'Error parsing date',
  })
