// SDK
import { IEmployerUser } from '@cnect/user-shared'
import { ICustomerDepartment, ICustomerLocation } from '@engineering11/customer-web'

// Third party
import { Action } from '@ngrx/store'

export enum CustomerActionTypes {
  get = '[Customer] get',
  getDepartment = '[Customer Department] get customer department',
  getDepartmentSuccess = '[Customer Department] get customer department success',
  getLocation = '[Customer Location] get customer location',
  getLocationSuccess = '[Customer Location] get customer location success',
  getActiveCustomerUsers = '[Customer Users] get active customer users',
  getCustomerUsersSuccess = '[Customer Users] get customer users success',
  reset = '[Customer Department] customer reset',
  error = '[Customer Department] error',
}

export class GetCustomer implements Action {
  readonly type = CustomerActionTypes.get
  constructor(public payload: any) {}
}

export class GetCustomerDepartment implements Action {
  readonly type = CustomerActionTypes.getDepartment
  constructor() {}
}

export class GetCustomerDepartmentSuccess implements Action {
  readonly type = CustomerActionTypes.getDepartmentSuccess
  constructor(public payload: ICustomerDepartment[]) {}
}

export class GetCustomerLocation implements Action {
  readonly type = CustomerActionTypes.getLocation
  constructor() {}
}

export class GetCustomerLocationSuccess implements Action {
  readonly type = CustomerActionTypes.getLocationSuccess
  constructor(public payload: ICustomerLocation[]) {}
}

export class GetActiveCustomerUsers implements Action {
  readonly type = CustomerActionTypes.getActiveCustomerUsers
  constructor() {}
}

export class GetCustomerUsersSuccess implements Action {
  readonly type = CustomerActionTypes.getCustomerUsersSuccess
  constructor(public payload: IEmployerUser[]) {}
}

export class Reset implements Action {
  readonly type = CustomerActionTypes.reset
  constructor() {}
}

export class ErrorAction implements Action {
  readonly type = CustomerActionTypes.error
  constructor(public payload?: []) {}
}

export type CustomerActions =
  | GetCustomer
  | GetCustomerDepartment
  | GetCustomerDepartmentSuccess
  | GetCustomerLocation
  | GetCustomerLocationSuccess
  | GetActiveCustomerUsers
  | GetCustomerUsersSuccess
  | Reset
  | ErrorAction
