import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ERROR_HANDLING } from '@candidate/app/app.constants'
import { IEmployerUser } from '@cnect/user-shared'
import { SubscriptionManagementService } from '@employer/app/services/subscription.management.service'
import { CustomerService, ICustomerLocation } from '@engineering11/customer-web'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { TranslateService } from '@ngx-translate/core'
import { AsYouType, format, parse } from 'libphonenumber-js'
import { FormCustomValidationService, FormSelectModel, FormSelectService, TokenStorage } from 'shared-lib'
import { Validation } from '@engineering11/ui/ui-input-errors'
@Component({
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss'],
})
export class CustomerLocationDetailComponent implements OnInit {
  // Forms:
  formLocation = new UntypedFormGroup({})
  formLocationSubmitted = false
  successMessage: string = ''
  locationId: string = ''
  user?: IEmployerUser
  location?: ICustomerLocation
  subs: SubscriptionManagementService = new SubscriptionManagementService()

  stateList: FormSelectModel[] = []
  selectDefaultAddressState: any

  asYouType: any
  format: any
  parse: any

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formSelectService: FormSelectService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private router: Router,
    private customValidator: FormCustomValidationService,
    private notificationsService: E11NotificationsService // private formValidationService: E11FormValidationService
  ) {
    this.stateList = this.formSelectService.getAddressStates()
    translate.get('Success! You have added a company location.').subscribe((res: string) => {
      this.successMessage = res
    })
  }

  ngOnInit(): void {
    this.asYouType = new AsYouType('US').input('2133734')
    this.format = format('2133734253', 'US', 'INTERNATIONAL') // Case sensitive / CAPS
    this.parse = parse('(0777) 844 822', 'RO')

    this.createFormLocation()
    this.activatedRoute.queryParams.subscribe(params => {
      this.locationId = params['id']
      this.user = this.tokenStorage.getItem('user')
      if (this.locationId) {
        this.subs.next = this.customerService.locations.get(this.user?.customerKey!, this.locationId).subscribe(l => {
          this.location = l
          this.selectDefaultAddressState = this.location?.state
          this.formLocation.patchValue(this.location!)
        })
      } else {
        this.location = { customerKey: this.user?.customerKey, active: true } as ICustomerLocation
      }
    })
  }

  createFormLocation() {
    this.formLocation = this.formBuilder.group({
      name: new UntypedFormControl(this.location?.name, [Validators.required, this.customValidator.whitespaceValidator()]),
      secondaryName: new UntypedFormControl(this.location?.secondaryName),
      internalCode: new UntypedFormControl(this.location?.internalCode),
      address1: new UntypedFormControl(this.location?.address1, [Validators.required, this.customValidator.whitespaceValidator()]),
      address2: new UntypedFormControl(this.location?.address2),
      city: new UntypedFormControl(this.location?.city, [Validators.required, this.customValidator.validateAddressCity()]),
      state: new UntypedFormControl(this.selectDefaultAddressState, [
        Validators.required,
        Validators.minLength(ERROR_HANDLING.STATE_REQUIRED_LENGTH),
      ]),
      zip: new UntypedFormControl(this.location?.zip, [Validators.required, this.customValidator.validateAddressZip()]),
      countryCode: new UntypedFormControl(this.location?.countryCode, [this.customValidator.validateAddressCountry()]),
    })
    this.formLocation.valueChanges.subscribe(changes => {
      if (this.location) {
        this.location.name = changes.name
        this.location.internalCode = changes.internalCode
        this.location.secondaryName = changes.secondaryName
        this.location.address1 = changes.address1
        this.location.address2 = changes.address2
        this.location.city = changes.city
        this.location.state = changes.state
        this.location.zip = changes.zip
        this.location.countryCode = changes.countryCode
      }
    })
  }

  setActive(active: boolean) {
    if (this.location) {
      this.location.active = active
    }
  }

  get f() {
    return this.formLocation.controls as {
      [key: string]: UntypedFormControl
    }
  }

  async onSubmitformLocation() {
    this.formLocationSubmitted = true
    if (this.formLocation.valid && this.location) {
      if (this.location.id) {
        await this.customerService.locations.set(this.location)
      } else {
        await this.customerService.locations.add(this.location)
      }
      this.formLocationSubmitted = false
      // Get translations for the title and message
      let title = '',
        message = ''
      this.translate.get('Saved').subscribe((res: string) => {
        title = res
      })
      this.translate.get('Your location information has been updated.').subscribe((res: string) => {
        message = res
      })
      this.notificationsService.popNotificationMessage(new E11NotificationMessage(title, message, 'success', true, ''))
    } else {
      this.formLocation.updateValueAndValidity()
      Validation.validateAll(this.formLocation)
    }
  }
}
