import { InjectionToken } from '@angular/core'
import { IEnvironment } from './model/environment.model'
import { ErrorHandlingConfig } from './model/error-handling-config-model'

export enum COLLECTIONS {
  CANDIDATE_APPLICATION = 'jobs_candidate_application/',
  CANDIDATE_APPLICATION_STASH = 'candidate_application_stash/',
  CANDIDATE_APPLICATION_STAGE = 'jobs_candidate_application_stage/',
  CANDIDATE_APPLICATION_UPDATE_STAGE = 'jobs_candidate_application_update_stage/',
  JOB_PROFILE = 'jobs_job_profile/',
  JOB_POST = 'jobs_job_post/',
  JOB_POST_APPLICATION = '/jobs_job_application/', // subcollection of job_post
  JOB_APPLICATION_COMMENT = '/jobs_job_application_comment/', // subcollection of job_application
  JOB_APPLICATION_REVIEW = '/jobs_job_application_review/', // subcollection of job_application
  EMPLOYER = 'employer/',
  REPORTED_CONTENT = 'reported_content/',
  VIRTUAL_DIALOGUE_EMPLOYER = 'virtual-dialogue_employer/',
  VIRTUAL_DIALOGUE_CANDIDATE = 'virtual-dialogue_candidate/',
  CANDIDATE_RESUME = 'candidate_resume/',
  CANDIDATE_VIDEO = 'candidate_video/',
  CANDIDATE_JOB_VM = 'candidate_job_vm/',
  JOB_APPLICATION_VIEWS = 'job_application_views/',
}

export enum SEARCH_INDICES {
  JOB_POST_APPLICATION = 'job_post_application',
  APPLICATION_JOB_SCORE_ASC = 'application_job_score_asc',
  APPLICATION_DATE_DESC = 'application_date_desc',
  APPLICATION_DATE_ASC = 'application_date_asc',
  APPLICATION_LAST_NAME_DESC = 'application_last_name_desc',
  APPLICATION_LAST_NAME_ASC = 'application_last_name_asc',
  APPLICATION_EXPERIENCE_DESC = 'application_experience_desc',
  APPLICATION_EXPERIENCE_ASC = 'application_experience_asc',

  JOB_PROFILE = 'job_profile',
  JOB_POST = 'job_post',
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('environment')
export const ERROR_HANDLING = new InjectionToken<ErrorHandlingConfig>('error_handling')

export const NEW_PROFILE_TITLE = 'New Profile'

export const NOTIFICATION_DROPDOWN_COUNT = 5
export const CONVERSATION_DROPDOWN_COUNT = 5

export const IDENTICON = {
  ROOT_PATH: 'assets/identicon/',
  TAIL: '_identity.svg',
}

// Video
export const MIN_DURATION_VIDEO = 10 // seconds

export enum ConversationTypes {
  DirectMessage = 'direct-message',
  JobApplication = 'application-conversation',
  JobApplicationDM = 'application-conversation-dm',
  SocialDm = 'social-dm',
}

export enum InputsContext {
  Name = 'name',
  Description = 'description',
  LargeText = 'largeText',
}

export const INPUTS_MAXLENGTH_BY_CONTEXT: { [key in InputsContext]: number } = {
  name: 100,
  description: 200,
  largeText: 2000,
}
