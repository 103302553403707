<!-- Request Information -->
<div class="e11-w-full e11-text-center e11-my-4" *ngIf="!workHistory || workHistory.length === 0">
  <e11-button
    *ngIf="!processRequests() && userMode == userModeEnum.Employer"
    color="primary-accent"
    [style]="'pill outlined'"
    value="{{ 'Request Work History' | translate }}"
    (click)="!requestsDisabled && requestModal()"
    [disabled]="requestsDisabled"
  ></e11-button>
</div>

<!-- Pending/open request -->
<request-open *ngIf="processRequests() && userMode === userModeEnum.Employer" [request]="openRequest"></request-open>
