// Framework
import { Component } from '@angular/core'

// Platform specific
import { TokenStorage } from 'shared-lib'

// SDK
import { IAuthService } from '@engineering11/auth-web'
import { Router } from '@angular/router'
@Component({
  selector: 'app-header-register',
  templateUrl: './header-register.component.html',
  styleUrls: ['./header-register.component.scss'],
})
export class AppHeaderRegisterComponent {
  constructor(private tokenStorage: TokenStorage, private authService: IAuthService, private router: Router) {}

  signOut(): void {
    this.authService.signOut()
    this.tokenStorage.clear()
    this.router.navigate(['/home'])
  }
}
