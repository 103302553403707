// Framework
import { Component, OnInit } from '@angular/core'

@Component({
  templateUrl: './layout-sidebar.component.html',
  styleUrls: ['./layout-sidebar.component.scss'],
})
export class LayoutSidebarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
