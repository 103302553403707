// Framework
import { Component } from '@angular/core'

@Component({
  selector: 'app-nav-container',
  templateUrl: './nav-container.component.html',
  styleUrls: ['./nav-container.component.scss'],
})
export class AppNavContainerComponent {
  constructor() {}
}
