// Framework
import { Component, OnInit } from '@angular/core'

@Component({
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
