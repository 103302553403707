import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { DegreeLevel } from '../../model/enum'
import { IEducation } from '../../model/interfaces'

@Component({
  selector: 'ui-education-line-item-card',
  template: `
    <div
      class="e11-flex e11-flex-col e11-relative e11-h-full e11-border e11-border-skin-app-borders e11-rounded-lg e11-p-4 e11-cursor-pointer hover:e11-bg-gray-50 hover:e11-shadow-md"
    >
      <span
        *ngIf="removable"
        class="e11-line-item-delete e11-flex e11-items-center e11-justify-center e11-bg-white e11-rounded-full e11-border e11-border-skin-app-borders"
      >
        <span
          class="material-icons md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary"
          (click)="deleteItem.emit(education)"
          *ngIf="removable"
        >
          close
        </span>
      </span>
      <span class="e11-icon-with-attitude" (click)="viewDetail.emit(education); editView.emit(true)">
        <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary"> edit </span>
      </span>
      <span (click)="viewDetail.emit(education); editView.emit(true)">
        <h4 class="e11-line-item-title e11-break-words e11-line-clamp-2">{{ education.schoolName }}</h4>
        <div class="e11-break-words e11-line-clamp-2">{{ stateToStatus.get(education.degreeLevel) || '' | translate }}</div>
        <div class="e11-break-words e11-line-clamp-2">{{ education.degreeTitle }}</div>
        <div class="e11-break-words e11-line-clamp-2" *ngIf="education.degreeType">{{ education.degreeType }}</div>
        <div *ngIf="education.notCompleted" class="e11-text-sm">*{{ 'Not yet Completed' | translate }}</div>
      </span>
    </div>
  `,
  styleUrls: ['./ui-education-line-item-card.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiEducationLineItemCardComponent {
  @Input()
  education!: IEducation

  @Input()
  removable: boolean = false

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IEducation>()

  degreeLevel = DegreeLevel

  stateToStatus = new Map<DegreeLevel, string>()
    .set(this.degreeLevel.NotSpecified, myDegreeLevel('Not Specified'))
    .set(this.degreeLevel.HighSchool, myDegreeLevel('High School'))
    .set(this.degreeLevel.VocationalHsDiploma, myDegreeLevel('Vocational High School Diploma'))
    .set(this.degreeLevel.VocationalDegree, myDegreeLevel('Vocational Degree'))
    .set(this.degreeLevel.Associates, myDegreeLevel("Associate's Degree"))
    .set(this.degreeLevel.Bachelors, myDegreeLevel("Bachelor's Degree"))
    .set(this.degreeLevel.Masters, myDegreeLevel("Master's Degree"))
    .set(this.degreeLevel.PhD, myDegreeLevel('Doctorate/PhD'))
}

const myDegreeLevel = (str: string) => `${str}`
