import { UiButtonModule } from '@engineering11/ui/ui-button'
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { TranslateModule } from '@ngx-translate/core'

import { ConfigModule } from '@engineering11/config-web'

import { SharedLibComponent } from './shared-lib.component'
import { AvatarModule } from 'ngx-avatar'
import { EmptyAvatarComponent } from './components/empty-avatar/empty-avatar.component'

import { VersionCheckerComponent } from './components/version-checker/version-checker.component'
import { CandidateSkillsCertificationResultComponent } from './components/skills-certification/skills-certification.component'
import { CandidateSkillsResultComponent } from './components/skills-certification/skills/skills.component'
import { CandidateCertificationsResultComponent } from './components/skills-certification/certifications/certifications.component'
import { UiSkillComponent } from './components/ui-skill/skill.component'
import { UiSkillCountComponent } from './components/ui-skill-count/skill-count.component'

import { DateDisplayStartEndComponent } from './components/date-display-start-end/date-display-start-end.component'

import { UiEducationsComponent } from './components/ui-education/ui-education.component'
import { UiEducationSingleCardVerticalComponent } from './components/ui-education/ui-education-single-card-vertical'
import { UiEducationSingleCardHorizontalComponent } from './components/ui-education/ui-education-single-card-horizontal'

// Here
import { UiEducationLineItemComponent } from './components/ui-education/ui-education-line-item'
import { UiEducationLineItemCardComponent } from './components/ui-education/ui-education-line-item-card'

import { UiReferencesComponent } from './components/ui-reference/ui-reference.component'
import { UiReferenceSingleCardVerticalComponent } from './components/ui-reference/ui-reference-single-card-vertical'
import { UiReferenceSingleCardHorizontalComponent } from './components/ui-reference/ui-reference-single-card-horizontal'
import { UiReferenceLineItemCardComponent } from './components/ui-reference/ui-reference-line-item-card'
import { UiReferenceLineItemComponent } from './components/ui-reference/ui-reference-line-item'

import { UiWorkHistoryComponent } from './components/ui-work-history/ui-work-history.component'
import { UiWorkHistorySingleCardVerticalComponent } from './components/ui-work-history/ui-work-history-single-card-vertical'
import { UiWorkHistorySingleCardHorizontalComponent } from './components/ui-work-history/ui-work-history-single-card-horizontal'
import { UiWorkHistoryLineItemComponent } from './components/ui-work-history/ui-work-history-line-item'
import { UiWorkHistoryLineItemCardComponent } from './components/ui-work-history/ui-work-history-line-item-card'

import {
  ApplicationFieldPipe,
  ApplicationStatePipe,
  DegreeLevelPipe,
  DialogueQuestionTypePipe,
  EmploymentTypePipe,
  ExperienceLevelPipe,
} from './pipe/enum.pipes'
import { CnectConfig } from './config'
import { CandidateRequestOpenComponent } from './components/request-open/request-open.component'

import { CandidateJobMatchingComponent } from './components/job-matching-chart/job-matching-chart.component'
import { UiComponentLibraryModule } from './_component-library/component-library.module'
import { ENVIRONMENT, ERROR_HANDLING } from './constants'
import { IEnvironment, IPlatformAdminEnvironment } from './model/environment.model'

import {
  NotificationConfigurationProvider,
  NotificationHandlerModule,
  UiNotificationComponent,
  UiNotificationLongComponent,
} from '@engineering11/notifications-web'
import { UiBadgeModule } from '@engineering11/ui/ui-badge'
import { UiSwitchModule } from '@engineering11/ui/ui-switch'
import { RouterModule } from '@angular/router'
import { ReactiveComponentModule } from '@ngrx/component'
import { VideoRecordingComponent } from './components/video-recording/video-recording.component'
import { VideoRecordingSettingsComponent } from './components/video-recording/partials/settings/settings.component'
import { UiModalModule } from '@engineering11/ui/ui-modal'
import { UiTextareaModule } from '@engineering11/ui/ui-textarea'

import { VideoRecordingControlsComponent } from './components/video-recording/partials/controls/controls.component'
import { PhotoEditComponent } from './components/photo-edit/photo-edit.component'
import { AccountManageProfilePhotoComponent } from './components/manage-profile-photo/manage-profile-photo.component'
import { ErrorHandlingConfig } from './model/error-handling-config-model'
import { AccountDeactivateAccountComponent } from './components/deactivate-account/deactivate-account.component'

import { CandidatePersonalComponent } from './components/candidate-personal/candidate-personal.component'
import { MyJobsApplicationConfirmationsComponent } from './components/application-confirmations/application-confirmations.component'
import { CandidateWorkHistoryComponent } from './components/candidate-work-history/candidate-work-history.component'
import { CandidateEducationComponent } from './components/candidate-education/candidate-education.component'
import { CandidateReferencesComponent } from './components/candidate-references/candidate-references.component'
import { ProfilePicComponent } from './components/profile-pic/profile-pic.component'
import { Store } from '@ngrx/store'
import { AccountManageNotificationsComponent } from './components/account-manage-notifications/account-manage-notifications.component'
import { CopyrightComponent } from './components/copyright/copyright.component'

import { ApplicationHistoryComponent } from './components/application-history/application-history.component'
import { UiVideoCardComponent } from './components/ui-video/ui-video-card/ui-video-card.component'
import { UiVideoSkeletonCardComponent } from './components/ui-video/ui-video-skeleton-card/ui-video-skeleton-card.component'
import { UiVideoDetailComponent } from './components/ui-video/ui-video-detail/ui-video-detail.component'
import { FreeDraggingHandleDirective } from './directive/free-dragging-handle.directive'
import { FreeDraggingDirective } from './directive/free-dragging.directive'
import { NgxSummernoteModule } from 'ngx-summernote'
import { CandidateWorkHistoryProfileShareComponent } from './components/profile-share/candidate-work-history/candidate-work-history.component'
import { CandidateEducationProfileShareComponent } from './components/profile-share/candidate-education/candidate-education.component'
import { CandidateReferencesProfileShareComponent } from './components/profile-share/candidate-references/candidate-references.component'
import { AboutComponent } from './components/about/about.component'
import { SingleFirstImpressionCardComponent } from './components/single-first-impression-card/single-first-impression-card.component'
import { SingleFirstImpressionComponent } from './components/single-first-impression/single-first-impression.component'
import { AppLogoWhiteComponent } from './components/logo/white/logo.component'
import { AppLogoBlueComponent } from './components/logo/blue/logo.component'
import { AppLogoJobsBlueComponent } from './components/logo/cnect-jobs-blue/logo.component'
import { AppLogoJobsWhiteComponent } from './components/logo/cnect-jobs-white/logo.component'
import { JobScheduleComponent } from './components/job-schedule/job-schedule.component'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { MultimediaModule } from '@engineering11/multimedia-web'
// import { ModalBrowserDetectionComponent } from './components/modals/browser-detection/browser-detection.component'

const DeclareExportComponents = [
  SharedLibComponent,
  EmptyAvatarComponent,
  VersionCheckerComponent,
  DateDisplayStartEndComponent,
  CandidateSkillsCertificationResultComponent,
  CandidateSkillsResultComponent,
  CandidateCertificationsResultComponent,
  CandidateRequestOpenComponent,
  PhotoEditComponent,
  AccountManageProfilePhotoComponent,
  AccountManageNotificationsComponent,
  AccountDeactivateAccountComponent,
  ProfilePicComponent,

  UiSkillComponent,
  UiSkillCountComponent,
  UiEducationSingleCardVerticalComponent,
  UiEducationSingleCardHorizontalComponent,
  UiEducationLineItemComponent,
  UiEducationLineItemCardComponent,
  UiEducationsComponent,
  UiReferenceSingleCardVerticalComponent,
  UiReferenceLineItemComponent,
  UiReferenceLineItemCardComponent,
  UiReferenceSingleCardHorizontalComponent,
  UiReferencesComponent,
  UiWorkHistorySingleCardVerticalComponent,
  UiWorkHistoryLineItemComponent,
  UiWorkHistoryLineItemCardComponent,
  UiWorkHistorySingleCardHorizontalComponent,
  UiWorkHistoryComponent,
  UiVideoCardComponent,
  UiVideoSkeletonCardComponent,
  UiVideoDetailComponent,
  SingleFirstImpressionCardComponent,
  SingleFirstImpressionComponent,

  CandidateJobMatchingComponent,
  CandidatePersonalComponent,
  CandidateWorkHistoryComponent,
  CandidateEducationComponent,
  MyJobsApplicationConfirmationsComponent,
  CandidateReferencesComponent,
  CopyrightComponent,
  ApplicationHistoryComponent,
  AppLogoWhiteComponent,
  AppLogoBlueComponent,
  AppLogoJobsBlueComponent,
  AppLogoJobsWhiteComponent,

  // ModalBrowserDetectionComponent,

  //Video
  VideoRecordingComponent,
  VideoRecordingSettingsComponent,
  VideoRecordingControlsComponent,

  // Pipes
  EmploymentTypePipe,
  ExperienceLevelPipe,
  DegreeLevelPipe,
  ApplicationStatePipe,
  DialogueQuestionTypePipe,
  ApplicationFieldPipe,

  // Directive
  FreeDraggingDirective,
  FreeDraggingHandleDirective,

  // New for shared profile
  CandidateWorkHistoryProfileShareComponent,
  CandidateEducationProfileShareComponent,
  CandidateReferencesProfileShareComponent,

  AboutComponent,
  JobScheduleComponent,
]

@NgModule({
  declarations: [...DeclareExportComponents, AboutComponent],
  imports: [
    CommonModule,
    FormsModule,
    UiBadgeModule,
    UiSwitchModule,
    UiModalModule,
    UiTextareaModule,
    UiButtonModule,
    TranslateModule,
    RouterModule,
    AvatarModule,
    MultimediaModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CloudflareStreamModule,
    ReactiveComponentModule,
    UiComponentLibraryModule,
    WebUtilityModule,
    ConfigModule.forRoot(CnectConfig),
  ],
  exports: [...DeclareExportComponents, MultimediaModule, NgxSummernoteModule],
})
export class SharedLibModule {
  public static forRoot(env: IEnvironment | IPlatformAdminEnvironment, errorHandling: ErrorHandlingConfig): ModuleWithProviders<SharedLibModule> {
    return {
      ngModule: SharedLibModule,
      providers: [
        { provide: ENVIRONMENT, useValue: env },
        { provide: ERROR_HANDLING, useValue: errorHandling },
      ],
    }
  }
}
