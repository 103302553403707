import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ListMode, UserMode } from '../../model/component-modes.enum'

import { ISelectableWorkHistory } from './../../model/interfaces'

@Component({
  selector: 'ui-work-history',
  templateUrl: './ui-work-history.component.html',
  styleUrls: ['./ui-work-history.component.scss'],
})
export class UiWorkHistoryComponent {
  listModeEnum = ListMode
  userModeEnum = UserMode

  @Input()
  listMode: ListMode = ListMode.Horizontal

  @Input()
  userMode: UserMode = UserMode.Candidate

  @Input()
  workHistories: ISelectableWorkHistory[] = []

  @Input()
  loading = false

  @Input()
  selectable: boolean = false

  @Output()
  viewDetail = new EventEmitter<ISelectableWorkHistory>()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<ISelectableWorkHistory>()

  @Output()
  selectionChange: EventEmitter<ISelectableWorkHistory[]> = new EventEmitter()

  @Output()
  addItem = new EventEmitter()

  select(workHistory: ISelectableWorkHistory) {
    this.selectionChange.emit(this.workHistories)
  }

  selectAll() {
    this.workHistories.forEach(workHistory => (workHistory.selected = true))
    this.selectionChange.emit(this.workHistories)
  }

  deselectAll() {
    this.workHistories.forEach(workHistory => (workHistory.selected = false))
    this.selectionChange.emit(this.workHistories)
  }
}
