<ui-panel size="md" id="panel_registration" borderRadius="lg">
  <div class="row">
    <div class="col-xs-12 text-center">
      <h1 class="nomargin-bottom">{{ 'Register with your team' | translate }}</h1>
      <div class="spacer-2rem"></div>
    </div>
  </div>

  <!-- Email check -->
  <registration-invite-sdk
    *ngIf="registrationCode && !showError"
    [registrationCode]="registrationCode"
    (onError)="onError($event)"
    (onSubmit)="onSubmit($event)"
  ></registration-invite-sdk>

  <div class="loader" *ngIf="loading" class="active">
    <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary-accent'"></e11-loader-dots>
  </div>
</ui-panel>
