import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'email-actions',
  templateUrl: './email-actions.component.html',
  styleUrls: ['./email-actions.component.scss'],
})
export class EmailActionsComponent implements OnInit {
  mode: string | null = this.route.snapshot.queryParamMap.get('mode')
  oobCode: string | null = this.route.snapshot.queryParamMap.get('oobCode')
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  async onResetSubmit(resultStringified: string): Promise<void> {
    this.router.navigate(['/auth/action-success', this.mode])
  }

  async onVerifySubmit(): Promise<void> {
    this.router.navigate(['/auth/action-success', this.mode])
  }
}
