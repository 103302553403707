import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { IEmployerUser } from '@cnect/user-shared'
import { ERROR_HANDLING } from '@employer/app/app.constants'
import { SubscriptionManagementService } from '@employer/app/services/subscription.management.service'
import { CustomerService, IBusinessCustomer } from '@engineering11/customer-web'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { Validation } from '@engineering11/ui/ui-input-errors'
import { TranslateService } from '@ngx-translate/core'
import { FormCustomValidationService, FormSelectModel, FormSelectService, TokenStorage } from 'shared-lib'

@Component({
  selector: 'customer-manage-company-info',
  templateUrl: './manage-company-info.component.html',
  styleUrls: ['./manage-company-info.component.scss'],
})
export class CustomerManageCompanyInfoComponent implements OnInit, OnDestroy {
  customer = <IBusinessCustomer>{}
  loading: boolean = false
  subs: SubscriptionManagementService = new SubscriptionManagementService()

  formAccount = new UntypedFormGroup({})
  formAccountSubmitted = false
  formReadOnly = true

  stateList: FormSelectModel[] = []
  industryGroupList: FormSelectModel[] = []
  businessSizeRangeList: FormSelectModel[] = []
  businessTypesList: FormSelectModel[] = []

  constructor(
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private customValidator: FormCustomValidationService,
    private formBuilder: UntypedFormBuilder,
    private formSelectService: FormSelectService,
    private notificationsService: E11NotificationsService,
    private translate: TranslateService
  ) {
    this.stateList = this.formSelectService.getAddressStates()
    this.industryGroupList = this.formSelectService.getIndustryGroups()
    this.businessSizeRangeList = this.formSelectService.getBusinessSizesList()
    this.businessTypesList = this.formSelectService.getBusinessTypes()
  }

  ngOnInit(): void {
    const userLocalStorage = this.tokenStorage.getItem('user') as IEmployerUser
    if (userLocalStorage) {
      this.subs.next = this.customerService.get(userLocalStorage.customerKey).subscribe(data => {
        this.customer = data as IBusinessCustomer
        this.formAccount.patchValue(this.customer)
      })
    }
    this.createFormAccount()
  }

  get f() {
    return this.formAccount.controls as {
      [key: string]: UntypedFormControl
    }
  }

  createFormAccount(): void {
    this.formAccount = this.formBuilder.group({
      name: new UntypedFormControl(this.customer.name, [Validators.required, this.customValidator.whitespaceValidator()]),
      address: new UntypedFormControl(this.customer.address, [Validators.required, Validators.minLength(ERROR_HANDLING.ADDRESS_MIN_LENGTH)]),
      address2: new UntypedFormControl(this.customer.address2),
      city: new UntypedFormControl(this.customer.city, [Validators.required, this.customValidator.validateAddressCity()]),
      state: new UntypedFormControl(this.customer.state, [Validators.required, Validators.minLength(ERROR_HANDLING.STATE_REQUIRED_LENGTH)]),
      zip: new UntypedFormControl(this.customer.zip, [Validators.required, this.customValidator.validateAddressZip()]),
      websiteURL: new UntypedFormControl(this.customer.websiteURL, [this.customValidator.validateWebsite()]),
      industryGroup: new UntypedFormControl(this.customer.industryGroup, []),
      businessClassification: new UntypedFormControl(this.customer.businessClassification),
      businessSizeRange: new UntypedFormControl(this.customer.businessSizeRange),
    })
  }

  async onSubmitFormAccount() {
    if (this.formAccount.valid) {
      this.customer.name = this.formAccount.value.name
      this.customer.address = this.formAccount.value.address
      this.customer.address2 = this.formAccount.value.address2
      this.customer.city = this.formAccount.value.city
      this.customer.state = this.formAccount.value.state
      this.customer.zip = this.formAccount.value.zip
      this.customer.websiteURL = this.formAccount.value.websiteURL
      this.customer.industryGroup = this.formAccount.value.industryGroup
      this.customer.businessClassification = this.formAccount.value.businessClassification
      this.customer.businessSizeRange = this.formAccount.value.businessSizeRange
      await this.customerService.update(this.customer)
      // this.activateNotification('success', 'Your information has been saved.')
      // Get translations for the title and message
      let title = '',
        message = ''
      this.translate.get('Saved!').subscribe((res: string) => {
        title = res
      })
      this.translate.get('Your account information has been updated.').subscribe((res: string) => {
        message = res
      })
      this.notificationsService.popNotificationMessage(new E11NotificationMessage(title, message, 'success', true, ''))
      this.toggleFormState(true)
    } else {
      this.formAccount.updateValueAndValidity()
      Validation.validateAll(this.formAccount)
    }
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  toggleFormState(value: boolean): void {
    this.formReadOnly = value
  }

  cancel() {
    this.formReadOnly = true
    this.createFormAccount()
  }
}
