<!-- Layout or SDK wrapper -->
<div class="e11-pb-16 e11-w-full e11-flex e11-justify-center">
  <div class="e11-w-3/4 md:e11-w-1/2 lg:e11-w-1/3">
    <!-- Dynamic/Conditional Panel title -->
    <h1 class="e11-styles e11-text-center e11-text-skin-primary e11-font-skin-century-gothic e11-mb-4">
      {{ 'Our Terms of Use' | translate }}
    </h1>

    <e11-panel [hasBorder]="true" [hasShadow]="false" [contentClassOverrides]="'e11-px-8 e11-py-6'">
      <!-- SDK-->
      <sdk-terms-of-use [userId]="userId" (onSubmit)="onAccept($event)" (onDecline)="onDecline()"></sdk-terms-of-use>
    </e11-panel>
  </div>
</div>
