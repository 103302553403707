import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { IEmployerUser } from '@cnect/user-shared'
import { Permission } from '@employer/app/config/permission.config'
import { APP_NAVIGATION_LEFT_GROUPS, NavLeftService } from '@employer/app/services/nav-left.service'
import { EmployerNavigationService, NavItemNames, ROUTES } from '@employer/app/services/navigation.service'
import { selectors } from '@employer/app/store/selectors'
import { ConversationStore } from '@engineering11/messaging-web'
import { PermissionService } from '@engineering11/permissions-web'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { ConversationTypes } from 'shared-lib'
import { EMPLOYER_USER_ROLES } from '@cnect/user-shared'

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss'],
})
export class AppNavLeftComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('navContainer') navContainer?: ElementRef
  @ViewChild('appFooter') appFooter?: ElementRef

  subs = new SubscriptionManagementService()
  navLeftBottomPos: number = 0
  navTop: number = 112
  navGroups = APP_NAVIGATION_LEFT_GROUPS
  routes = ROUTES
  navItemNames = NavItemNames
  unreadConversationCount$ = this.conversationStore.getUnreadConversationCount
  UNREAD_LIMIT = 9
  conversationTypesEnum = ConversationTypes
  canManageTeam$ = this.permissionService.userHasPermission(Permission.TEAM_MANAGE)
  canManageCompany$ = this.permissionService.userHasPermission(Permission.COMPANY_MANAGE)
  currentUser$: Observable<IEmployerUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  EMPLOYER_USER_ROLES = EMPLOYER_USER_ROLES
  constructor(
    private conversationStore: ConversationStore,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private element: ElementRef<HTMLElement>,
    private navLeftService: NavLeftService,
    private employerNavigationService: EmployerNavigationService
  ) {}

  ngOnInit(): void {
    this.subs.next = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams['id']) {
        setTimeout(() => {
          this.scrollTo('#' + queryParams['id'])
        }, 250)
      }
    })
  }

  ngAfterViewInit() {
    let datas = this.navContainer?.nativeElement?.getBoundingClientRect()
    if (datas) {
      this.navLeftBottomPos = datas.top + this.navContainer?.nativeElement.offsetHeight
    }
  }

  getNavItem(name: NavItemNames) {
    return this.employerNavigationService.getNavItemByName(name)
  }

  getFooterPos() {
    let element = document?.getElementById('appFooter')
    return element!.getBoundingClientRect().top
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event: any[]) {
    if (this.navLeftBottomPos) {
      if (this.getFooterPos() - this.navLeftBottomPos < 112) {
        this.navTop = this.getFooterPos() - this.navLeftBottomPos
      } else {
        this.navTop = 112
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  scrollTo(id: string): void {
    let anchor = document.querySelector(id)
    anchor?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }

  isActiveNavGroup(group: APP_NAVIGATION_LEFT_GROUPS) {
    return this.navLeftService.isCurrentlyActiveGroup(group)
  }

  toggleNavGroup(group: APP_NAVIGATION_LEFT_GROUPS) {
    this.navLeftService.navToggleGroup(group)
  }
}
