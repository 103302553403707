import { Component, Input, Output, EventEmitter } from '@angular/core'
import { formatBytes } from '@engineering11/utility'
import { PlayerSize } from '@engineering11/multimedia-web'
import { ICandidateVideo } from '../../../model/candidate-video.model'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-video-detail',
  templateUrl: './ui-video-detail.component.html',
  styleUrls: ['./ui-vide-detail.component.scss'],
})
export class UiVideoDetailComponent {
  _video!: ICandidateVideo

  @Input()
  showContent = false

  @Output()
  closeFired: EventEmitter<boolean> = new EventEmitter()

  @Output()
  deleteFired: EventEmitter<boolean> = new EventEmitter()

  showDeleteModal = false
  playerSizeEnum = PlayerSize

  videoPlayerResource!: { thumb?: string; video?: string }

  videoSize = 'N/A'

  @Input()
  set video(value: ICandidateVideo) {
    if (value) {
      this._video = value
      if (this._video.video.size) {
        this.videoSize = formatBytes(this._video.video.size)
      }
      this.videoPlayerResource = {
        thumb: value.thumb.signedURL,
        video: value.video.signedURL,
      }
    }
  }

  constructor() {}

  onClose() {
    this.showContent = false
  }

  delete() {
    this.showDeleteModal = true
  }
}
