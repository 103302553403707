// Framework
import { Component, OnDestroy, OnInit } from '@angular/core'

// Platform specific
import { IUserService, UserPreferencesService } from '@engineering11/user-web'
import { TokenStorage } from '../../service/token-storage.service'
import { IUser } from '@engineering11/user-shared'
import { Subscription } from 'rxjs'
@Component({
  selector: 'account-manage-notifications',
  templateUrl: './account-manage-notifications.component.html',
  styleUrls: ['./account-manage-notifications.component.scss'],
})
export class AccountManageNotificationsComponent implements OnInit, OnDestroy {
  userEmailEnabled: boolean = false
  user!: IUser
  subscription: Subscription = new Subscription()
  constructor(private userService: IUserService, private tokenStorage: TokenStorage, private userPreferencesService: UserPreferencesService) {}

  ngOnInit(): void {
    this.user = this.tokenStorage.getItem('user')
    this.subscription = this.userPreferencesService.getUserPreferencesValueChanges(this.user.id).subscribe(userPreferences => {
      if (userPreferences) {
        this.userEmailEnabled = userPreferences.emailEnabled
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  toggleNotificationEmail(value: boolean) {
    this.userPreferencesService.setUserPreference('emailEnabled', !value, this.user.id)
  }

  async updateUser() {
    await this.userService.update(this.user)
    this.tokenStorage.setItem('user', JSON.stringify(this.user))
  }
}
