import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { UrlSerializer } from '@angular/router'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { TokenStorage } from 'shared-lib'
import { CustomerService, ICustomer } from '@engineering11/customer-web'
import { FilesService } from '@engineering11/files-web'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'

@Component({
  selector: 'customer-manage-company-logo',
  templateUrl: './manage-company-logo.component.html',
  styleUrls: ['./manage-company-logo.component.scss'],
})
export class ManageCompanyLogoComponent implements OnInit, OnDestroy {
  constructor(
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private filesService: FilesService,
    private urlSerialize: UrlSerializer,
    private modalService: E11ModalGlobalService
  ) {}

  customer?: ICustomer
  loading: boolean = false
  subs: SubscriptionManagementService = new SubscriptionManagementService()
  logoUrl?: string | null | undefined

  ngOnInit(): void {
    const userLocalStorage = this.tokenStorage.getItem('user')
    if (userLocalStorage) {
      this.subs.next = this.customerService.get(userLocalStorage.customerKey).subscribe(data => {
        this.customer = data
        this.logoUrl = this.customer!.logoImageURL
      })
    }
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  async upload(file: File) {
    if (this.customer) {
      this.loading = true
      if (file) {
        await this.removeUploadedFile()
        const receipt = await this.filesService.uploadPublicFile(file, 'logo')
        this.customer.logoImageURL = receipt.url
        this.logoUrl = receipt.url
        await this.customerService.update(this.customer)
      }
      this.loading = false
    }
  }

  async removeUploadedFile() {
    if (this.customer?.logoImageURL) {
      await this.filesService.deletePublicFile(this.customer.logoImageURL)
      this.customer.logoImageURL = null
      this.logoUrl = null
      await this.customerService.update(this.customer)
    }
  }

  openUploadModal(modalTemplate: TemplateRef<any>) {
    this.modalService.openModal({
      body: modalTemplate,
      footer: undefined,
      width: 'md',
      bodyHeight: 'auto',
      title: 'Manage company logo',
      showHeader: true,
      showFooter: false,
    })
  }

  closeModal() {
    this.modalService.closeModal()
  }
}
