import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IBetaMessage } from './beta-message.model'
import { BetaMessageRepository } from './beta-message.repository'

@Injectable({
  providedIn: 'root',
})
export class BetaMessageService {
  constructor(private repository: BetaMessageRepository) {}

  get(id: string): Observable<IBetaMessage | undefined> {
    return this.repository.get(id)
  }

  getValueChanges(id: string): Observable<IBetaMessage | undefined> {
    return this.repository.getValueChanges(id)
  }

  getAllValueChanges(): Observable<Array<IBetaMessage>> {
    return this.repository.getAllValueChanges()
  }

  set(model: IBetaMessage) {
    return this.repository.set(model)
  }

  delete(id: string) {
    return this.repository.delete(id)
  }
}
