// Framework
import { Router } from '@angular/router'
import { Component } from '@angular/core'

// Platform specific
import { TokenStorage } from 'shared-lib'

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class AuthenticationRegisterComponent {
  constructor(private router: Router, private tokenStorage: TokenStorage) {
    const code = this.tokenStorage.getItem('code')
    if (!code) {
      this.router.navigate(['/auth/code'])
    }
  }

  onSubmit(): void {
    this.router.navigate(['/auth/register-company'])
  }
}
