// Framework
import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms'

// Platform specific
import { CustomerService, ICustomer } from '@engineering11/customer-web'
import { IEmployer, TokenStorage } from 'shared-lib'
import { SubscriptionManagementService } from '@employer/app/services/subscription.management.service'
import { UiNotificationMessage, UiNotificationsService } from '@engineering11/ui/ui-notifications'
import { EmployerService } from 'shared-lib'

@Component({
  selector: 'manage-employer-settings',
  templateUrl: './manage-employer.component.html',
  styleUrls: ['./manage-employer.component.scss'],
})
export class ManageEmployerSettingsComponent implements OnInit, OnDestroy {
  customer?: ICustomer
  disclaimer?: string
  showDisclaimer = false
  employer?: IEmployer
  loading: boolean = false
  subs: SubscriptionManagementService = new SubscriptionManagementService()
  formDisclaimer = new UntypedFormGroup({})

  constructor(
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private formBuilder: UntypedFormBuilder,
    private employerService: EmployerService,
    private notificationsService: UiNotificationsService
  ) {}

  ngOnInit(): void {
    this.createForms()
    const user = this.tokenStorage.getItem('user')
    if (user) {
      this.subs.next = this.customerService.get(user.customerKey).subscribe(data => {
        this.customer = data
      })
      this.employerService.get(user.customerKey).subscribe(employer => {
        this.employer = employer
        if (employer) {
          this.disclaimer = employer.legalDisclaimer
          this.showDisclaimer = employer.showLegalDisclaimer
        }
        this.formDisclaimer?.patchValue({ disclaimer: this.employer?.legalDisclaimer || '' })
        this.showDisclaimer = this.showDisclaimer || false
      })
    }
  }

  createForms() {
    this.formDisclaimer = this.formBuilder.group({
      disclaimer: new UntypedFormControl(this.disclaimer),
    })
  }

  changeShowDisclaimer(event: any) {
    this.showDisclaimer = event
    if (this.formDisclaimer) {
      this.formDisclaimer.markAsDirty()
    }
  }

  async onSubmitDisclaimer() {
    const employer = this.createOrGetEmployer()
    employer.showLegalDisclaimer = this.showDisclaimer
    employer.legalDisclaimer = this.formDisclaimer?.value.disclaimer
    this.formDisclaimer?.markAsPristine()
    await this.saveAndNotify('The disclaimer text has been saved.')
    this.formDisclaimer?.patchValue({ disclaimer: this.employer?.legalDisclaimer })
  }

  async saveAndNotify(message: string) {
    if (this.employer) {
      await this.employerService.set(this.employer)
      const notification = new UiNotificationMessage(message, 'success', false, 'Close')
      this.notificationsService.popNotificationMessage(notification)
    }
  }

  createOrGetEmployer(): IEmployer {
    if (this.employer) {
      return this.employer
    }
    const user = this.tokenStorage.getItem('user')
    this.employer = {
      id: user.customerKey,
      showLegalDisclaimer: false,
      showAbout: false,
    }
    return this.employer
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }
}
