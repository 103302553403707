import { Injectable } from '@angular/core'

import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { firstValueFrom } from 'rxjs'
import { JobsApiService } from './jobs.service'
import { UserService } from './user.service'

@Injectable({
  providedIn: 'root',
})
export class SearchKeyService {
  constructor(private fns: AngularFireFunctions, private jobsApiService: JobsApiService, private userService: UserService) {}

  async getCustomerUsersSearchKey(customerKey: string): Promise<string> {
    const key = await firstValueFrom(this.userService.getCandidateUserSearchKey())
    return key!
  }

  async getJobProfileSearchKey(): Promise<string> {
    const key = await firstValueFrom(this.jobsApiService.getJobProfileSearchKey())
    return key!
  }
  async getJobPostSearchKey(): Promise<string> {
    const key = await firstValueFrom(this.jobsApiService.getJobPostSearchKey())
    return key!
  }

  async getJobPostCandidateKey(postingId: string): Promise<string> {
    const key = await firstValueFrom(this.jobsApiService.getJobPostCandidateKey(postingId))
    return key!
  }

  async getAllCandidatesSearchKey() {
    const key = await firstValueFrom(this.jobsApiService.getAllCandidatesSearchKey())
    return key!
  }
}
