import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { environment } from '@employer/environments/environment'
import { SubscriptionManagementService } from '@employer/app/services/subscription.management.service'
import { AvailableThemes, TokenStorage } from 'shared-lib'
import { CustomerService, ICustomer } from '@engineering11/customer-web'
import { UiNotificationMessage, UiNotificationsService } from '@engineering11/ui/ui-notifications'
import { EmployerService, IEmployer, IHeroSection, ITheme } from 'shared-lib'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'manage-careers-page',
  templateUrl: './careers-page.component.html',
  styleUrls: ['./careers-page.component.scss'],
})
export class CareersPageComponent implements OnInit {
  customer?: ICustomer
  aboutText?: string
  showAbout = false
  employer?: IEmployer
  subs: SubscriptionManagementService = new SubscriptionManagementService()
  // formAbout: FormGroup
  formAbout = new UntypedFormGroup({})
  formLink = new UntypedFormGroup({})
  linkToCareersPage = environment.careersPageBaseLink
  showCopiedMessage = false

  formHeroSection = new UntypedFormGroup({})

  heroSectionImages = [
    'https://content.cnected.com/assets/career-page/3d-render-low-poly-plexus-design-network-communications.jpeg',
    'https://content.cnected.com/assets/career-page/business-people-casual-meeting.jpeg',
    'https://content.cnected.com/assets/career-page/close-up-businesswoman-pointing-digital-tablet.jpeg',
    'https://content.cnected.com/assets/career-page/close-up-portrait-freelance-it-specialists-looking-laptop-screen-with-smile.jpeg',
    'https://content.cnected.com/assets/career-page/community-young-people-socializing.jpeg',
    'https://content.cnected.com/assets/career-page/front-view-people-having-meeting-office.jpeg',
    'https://content.cnected.com/assets/career-page/light-blue-3d-abstract-wave-pattern.jpeg',
    'https://content.cnected.com/assets/career-page/modern-buildings-toronto-city.jpeg',
    'https://content.cnected.com/assets/career-page/woman-having-video-call.jpeg',
  ]

  availableThemes = AvailableThemes

  activeTheme: string = 'Default'

  constructor(
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private formBuilder: UntypedFormBuilder,
    private employerService: EmployerService,
    private translate: TranslateService,
    private notificationsService: E11NotificationsService
  ) {}

  ngOnInit(): void {
    this.formAbout = this.formBuilder.group({
      about: new UntypedFormControl(this.aboutText),
    })

    this.formHeroSection = this.formBuilder.group({
      title: new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)]),
      headerImage: new UntypedFormControl(''),
    })

    this.formLink = this.formBuilder.group({
      link: new UntypedFormControl({ disabled: true, value: '' }),
    })

    const user = this.tokenStorage.getItem('user')
    if (user) {
      this.subs.next = this.customerService.get(user.customerKey).subscribe(data => {
        this.customer = data
      })
      this.employerService.get(user.customerKey).subscribe(employer => {
        this.employer = employer
        if (employer) {
          this.showAbout = employer.showAbout
          this.aboutText = employer.about
        }
        this.activeTheme = this.employer?.theme || 'Default'
        this.formHeroSection?.patchValue({
          title: this.employer?.heroSection?.title || '',
          description: this.employer?.heroSection?.description || '',
          headerImage: this.employer?.heroSection?.headerImage || '',
        })
        this.formAbout?.patchValue({ about: this.employer?.about || '' })
        this.formLink.patchValue({ link: `${this.linkToCareersPage}?client=${user.customerKey}` })
        this.linkToCareersPage = `${this.linkToCareersPage}?client=${user.customerKey}`
      })
    }
  }

  get formHero() {
    return this.formHeroSection.controls as {
      [key: string]: UntypedFormControl
    }
  }
  preview() {
    window.open(this.linkToCareersPage, '_blank')
  }
  setHeaderImage(img: string) {
    this.formHeroSection.get('headerImage')?.setValue(img)
  }
  async setActiveTheme(theme: ITheme) {
    this.activeTheme = theme
    const employer = this.createOrGetEmployer()
    employer.theme = theme

    // Notification (Success)
    let message = ''
    this.translate.get('Your theme selection has been updated.').subscribe((res: string) => {
      message = res
    })
    await this.saveAndNotify(message)
  }
  async onSubmitHero() {
    const heroSection: IHeroSection = {
      title: this.formHero.title.value,
      description: this.formHero.description.value,
      headerImage: this.formHero.headerImage.value,
    }
    const employer = this.createOrGetEmployer()
    employer.heroSection = heroSection
    // Notification (Success)
    let message = ''
    this.translate.get("Your 'Hero area' information has been updated.").subscribe((res: string) => {
      message = res
    })
    await this.saveAndNotify(message)
  }
  changeShowAbout(event: any) {
    this.showAbout = event
    if (this.formAbout) {
      this.formAbout.markAsDirty()
    }
  }

  async onSubmitAbout() {
    const employer = this.createOrGetEmployer()
    employer.showAbout = this.showAbout
    employer.about = this.formAbout?.value.about
    this.formAbout?.markAsPristine()
    // Notification (Success)
    let message = ''
    this.translate.get('Your About Us text has been saved.').subscribe((res: string) => {
      message = res
    })
    await this.saveAndNotify(message)
  }

  async saveAndNotify(message: string) {
    if (this.employer) {
      await this.employerService.set(this.employer)
      let title = ''
      this.translate.get('Saved').subscribe((res: string) => {
        title = res
      })
      this.notificationsService.popNotificationMessage(new E11NotificationMessage(title, message, 'success', true, ''))
    }
  }

  createOrGetEmployer(): IEmployer {
    if (this.employer) {
      return this.employer
    }
    const user = this.tokenStorage.getItem('user')
    this.employer = {
      id: user.customerKey,
      showLegalDisclaimer: false,
      showAbout: false,
    }
    return this.employer
  }

  async copyLink() {
    if (!this.linkToCareersPage) {
      return
    }
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = this.linkToCareersPage!
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
    this.showCopiedMessage = true
    await this.delay(2000)
    this.showCopiedMessage = false
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }
}
