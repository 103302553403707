export interface IHeroSection {
  title: string
  description: string
  headerImage: string
}

export enum ITheme {
  Default = 'Default',
  One = 'One',
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  Five = 'Five',
  Six = 'Six',
  Seven = 'Seven',
  Eight = 'Eight',
  Nine = 'Nine',
  Ten = 'Ten',
  Eleven = 'Eleven',
  Twelve = 'Twelve',
  Thirteen = 'Thirteen',
  Fourteen = 'Fourteen',
}

export interface IEmployerTheme {
  name: ITheme
  primary: string
  secondary: string
  text: string
  buttonText: string
}

export const EmployerDefaultTheme: IEmployerTheme = {
  name: ITheme.Default,
  primary: '#F7F5F2',
  secondary: '#DFDFDE',
  text: '#000000',
  buttonText: '#000000',
}

export const AvailableThemes: IEmployerTheme[] = [
  EmployerDefaultTheme,
  {
    name: ITheme.One,
    primary: '#223F99',
    secondary: '#1B75BB',
    text: '#ffffff',
    buttonText: '#ffffff',
  },
  {
    name: ITheme.Two,
    primary: '#FF8D29',
    secondary: '#FFCD38',
    text: '#ffffff',
    buttonText: '#000000',
  },
  {
    name: ITheme.Three,
    primary: '#238DC1',
    secondary: '#DF1683',
    text: '#FFFFFF',
    buttonText: '#FFFFFF',
  },
  {
    name: ITheme.Four,
    primary: '#54BAB9',
    secondary: '#18978F',
    text: '#fff',
    buttonText: '#fff',
  },
  {
    name: ITheme.Five,
    primary: '#FFBCD1',
    secondary: '#A267AC',
    text: '#fff',
    buttonText: '#000',
  },
  {
    name: ITheme.Six,
    primary: '#D3DEDC',
    secondary: '#7C99AC',
    text: '#000',
    buttonText: '#fff',
  },
  {
    name: ITheme.Seven,
    primary: '#519259',
    secondary: '#064635',
    text: '#fff',
    buttonText: '#fff',
  },
  {
    name: ITheme.Eight,
    primary: '#A3D2CA',
    secondary: '#056676',
    text: '#fff',
    buttonText: '#fff',
  },
  {
    name: ITheme.Nine,
    primary: '#39A2DB',
    secondary: '#A2DBFA',
    text: '#fff',
    buttonText: '#000',
  },
  {
    name: ITheme.Ten,
    primary: '#F4CCA4',
    secondary: '#D99879',
    text: '#000',
    buttonText: '#000',
  },
  {
    name: ITheme.Eleven,
    primary: '#F7F7F7',
    secondary: '#5D5D5D',
    text: '#000',
    buttonText: '#fff',
  },
  {
    name: ITheme.Twelve,
    primary: '#F1F6F9',
    secondary: '#14274E',
    text: '#000',
    buttonText: '#fff',
  },
  {
    name: ITheme.Thirteen,
    primary: '#B2EBF2',
    secondary: '#0F4C75',
    text: '#000',
    buttonText: '#fff',
  },
  {
    name: ITheme.Fourteen,
    primary: '#FFFDE8',
    secondary: '#F09C67',
    text: '#000',
    buttonText: '#000',
  },
]
export interface IEmployer {
  id: string // customer key
  about?: string
  legalDisclaimer?: string
  showLegalDisclaimer: boolean
  showAbout: boolean
  logoImageURL?: string
  heroSection?: IHeroSection
  theme?: ITheme
}
