<div class="container-view layout-sidebar">
  <!-- <app-header-secondary></app-header-secondary> -->

  <aside>
    <div class="spacer-5rem"></div>
    <div class="spacer-5rem"></div>

    <nav>
      <ul>
        <li>
          <a [routerLink]="['/landing']" [routerLinkActive]="['active']">{{ 'Starter App' | translate }} Home</a>
        </li>
        <li>
          <a [routerLink]="['/layout-sidebar']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Layout Sidebar Home</a>
        </li>
        <li>
          <a [routerLink]="['/layout-sidebar/view2']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
            >Layout Sidebar View2</a
          >
        </li>
        <li>
          <a [routerLink]="['/landing']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Dashboard</a>
        </li>
        <li>
          <a [routerLink]="['/landing']">Post job</a>
        </li>
        <li>
          <a [routerLink]="['/home']">Manage jobs</a>
        </li>
        <li>
          <a [routerLink]="['/home']">View matches</a>
        </li>
        <li>
          <a [routerLink]="['/home']">Candidate search</a>
        </li>
        <li>
          <a [routerLink]="['/home']">Reports</a>
        </li>
        <li>
          <a [routerLink]="['/home']">Settings</a>
        </li>
        <li>
          <a [routerLink]="['/home']">Help</a>
        </li>
      </ul>
    </nav>
  </aside>

  <main class="block">
    <header>
      <div class="row flex">
        <div class="col-xs-9">
          <p>Breadcrumb / breadcrumb / breadcrumb</p>
        </div>
        <div class="col-xs-3 text-right flex-align-center-justify-end">
          <i class="fas fa-lg fa-user"></i>
          <i class="fas fa-lg fa-home"></i>
          <i class="fas fa-lg fa-cog"></i>
        </div>
      </div>
    </header>

    <!-- <app-header></app-header> -->
    <!-- This is the main content area for the application, including the space for the dynamic left side navugation panel -->
    <!-- <app-nav-side></app-nav-side> -->

    <!-- <div class="container-content" [ngClass]="{'nav-open': sidebarStatus}"> -->
    <div class="e11-container-full container-content block employer-margin-top">
      <router-outlet></router-outlet>
    </div>

    <footer>
      <div class="row flex">
        <div class="col-xs-12">
          <p>footer</p>
        </div>
      </div>
    </footer>
  </main>

  <!-- <app-footer [sideNav]="sidebarStatus"></app-footer> -->
  <!-- <app-footer></app-footer> -->
</div>
