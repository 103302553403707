import { Subscription } from 'rxjs'

export class SubscriptionManagementService {
  subscriptions: Array<any> = new Array<any>()
  constructor() {}

  set next(sub: Subscription) {
    this.subscriptions.push(sub)
  }

  stop(): void {
    this.subscriptions.forEach(s => {
      if (s) {
        s.unsubscribe()
      }
    })
  }
}
