import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { IReference } from '../../model/interfaces'

@Component({
  selector: 'ui-reference-line-item',
  template: `
    <div class="e11-block e11-pb-4">
      <div class="e11-flex">
        <div class="e11-w-3/4">
          <h4 class="e11-line-item-title e11-break-all e11-line-clamp-2">{{ reference.firstName }} {{ reference.lastName }}</h4>
        </div>
        <div class="e11-w-1/4 e11-flex e11-justify-end">
          <span
            *ngIf="userMode === userModeEnum.Candidate"
            class="e11-flex e11-items-center e11-cursor-pointer e11-text-skin-primary-accent hover:e11-brightness-50 e11-text-sm"
            (click)="viewDetail.emit(reference); editView.emit(true)"
          >
            <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary e11-mr-1"> edit </span>
            Edit
          </span>
        </div>
      </div>
      <div class="e11-break-all e11-line-clamp-2 e11-text-sm">{{ 'Type' | translate }}: {{ reference.referenceType }}</div>
      <div class="e11-break-all e11-line-clamp-2 e11-text-sm">{{ 'Company' | translate }}: {{ reference.companyName }}</div>
      <div class="e11-break-all e11-line-clamp-2 e11-text-sm">{{ 'Relationship' | translate }}: {{ reference.referenceRelationship }}</div>
      <div class="e11-text-sm e11-flex e11-items-center">
        <span class="material-icons-outlined md-14 e11-mr-2"> email </span>
        <span *ngIf="reference.email">{{ reference.email }}</span>
        <span *ngIf="!reference.email" class="e11-italic e11-text-skin-light">{{ 'No email supplied' | translate }}</span>
      </div>
      <div class="e11-text-sm e11-flex e11-items-center">
        <span class="material-icons-outlined md-14 e11-mr-2"> phone_iphone </span>
        <span *ngIf="reference.phone">{{ reference.phone }}</span>
        <span *ngIf="!reference.phone" class="e11-italic e11-text-skin-light">{{ 'No phone supplied' | translate }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./ui-reference-line-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiReferenceLineItemComponent {
  userModeEnum = UserMode

  @Input()
  userMode: UserMode = UserMode.Candidate

  @Input()
  reference!: IReference

  @Input()
  removable: boolean = false

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IReference>()
}
