import { Injectable } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { APP_NAVIGATION_LEFT_GROUPS } from '@employer/app/services/nav-left.service'
import { BehaviorSubject } from 'rxjs'

export const ROUTES = Object.freeze({
  HOME: '/home',
  JOBS: '/jobs/list',
  JOBS_TEMPLATE: '/jobs/template/list',
  APPLICATIONS: '/jobs/applications',
  MANAGE_USERS: '/users',
  COMPANY_ACCOUNT: '/customer',
  COMPANY_LOCATIONS: '/customer/locations',
  COMPANY_DEPARTMENTS: '/customer/departments',
  COMPANY_CAREERS: '/customer/careers',
  ACCOUNT: '/account',
  LOGIN: '/auth/login',
})

export enum NavItemNames {
  Jobs = 'Jobs',
  JobTemplates = 'Job Templates',
  Applications = 'Applications',
  ManageUsers = 'Manage Users',
  CompanyAccount = 'Company Settings',
}

export enum CandidateNavigationSource {
  List = 'list',
  Search = 'search',
}

export interface INavItems {
  name: NavItemNames
  altParent?: Array<string>
  navGroup?: APP_NAVIGATION_LEFT_GROUPS
  path: string
  icon: string
  children?: Array<{
    label: null | string
    path: string
    queryParams?: Params
  }>
}

export enum CandidateDetailTabs {
  Profile = 'Profile',
  RequestMoreInfo = 'Request Info',
  Comments = 'Comments',
  DirectMessage = 'Direct Message',
  Dialogues = 'Dialogues',
  ApplicationHistory = 'Application History',
}

@Injectable({ providedIn: 'root' })
export class EmployerNavigationService {
  candidateNavigationSource: BehaviorSubject<CandidateNavigationSource> = new BehaviorSubject<CandidateNavigationSource>(
    CandidateNavigationSource.List
  )
  candidateNavigationSourceObservable$

  navigationItems: INavItems[] = [
    {
      name: NavItemNames.Applications,
      path: ROUTES.APPLICATIONS,
      icon: 'account_circle',
    },
    {
      name: NavItemNames.Jobs,
      path: ROUTES.JOBS,
      icon: 'description',
    },
    // {
    //   name: NavItemNames.Jobs,
    //   navGroup: APP_NAVIGATION_LEFT_GROUPS.Jobs,
    //   path: ROUTES.JOBS,
    //   icon: 'description',
    //   children: [
    //     {
    //       label: 'Active',
    //       path: ROUTES.JOBS,
    //     },
    //     {
    //       label: 'Drafts',
    //       path: ROUTES.JOBS,
    //       queryParams: { jobTab: 1 },
    //     },
    //     {
    //       label: 'Closed',
    //       path: ROUTES.JOBS,
    //       queryParams: { jobTab: 2 },
    //     },
    //     {
    //       label: 'Templates',
    //       path: ROUTES.JOBS_TEMPLATE,
    //     },
    //   ],
    // },
    {
      name: NavItemNames.JobTemplates,
      path: ROUTES.JOBS_TEMPLATE,
      icon: 'summarize',
    },
    {
      name: NavItemNames.ManageUsers,
      path: ROUTES.MANAGE_USERS,
      icon: 'group',
    },
    {
      name: NavItemNames.CompanyAccount,
      path: ROUTES.COMPANY_ACCOUNT,
      icon: 'domain',
      navGroup: APP_NAVIGATION_LEFT_GROUPS.CompanyAccount,
      children: [
        {
          label: 'Account Information',
          path: ROUTES.COMPANY_ACCOUNT,
        },
        {
          label: 'Company Logo',
          path: ROUTES.COMPANY_ACCOUNT,
          queryParams: { id: 'logo' },
        },
        {
          label: 'Legal Disclaimer',
          path: ROUTES.COMPANY_ACCOUNT,
          queryParams: { id: 'disclaimer' },
        },
        {
          label: 'Locations',
          path: ROUTES.COMPANY_LOCATIONS,
        },
        {
          label: 'Departments',
          path: ROUTES.COMPANY_DEPARTMENTS,
        },
        {
          label: 'Careers Page',
          path: ROUTES.COMPANY_CAREERS,
        },
      ],
    },
  ]

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.candidateNavigationSourceObservable$ = this.candidateNavigationSource.asObservable()
  }

  getNavItemByName(navName: NavItemNames) {
    return this.navigationItems.find(item => item.name === navName) ?? null
  }

  toLogin() {
    this.router.navigate([ROUTES.LOGIN])
  }

  toHome() {
    this.router.navigate([ROUTES.HOME])
  }

  // TODO: switch away from this - the form of the applicationId should only live in one place
  toCandidateDetail(jobId: string, candidateId: string) {
    this.router.navigate(['jobs/candidates/detail/', `${jobId}_${candidateId}`, jobId])
  }

  toCandidateDetailTab(jobId: string, applicationId: string, tab: CandidateDetailTabs = CandidateDetailTabs.Profile) {
    this.router.navigate(['jobs/candidates/detail/', applicationId, jobId], { queryParams: { tab } })
  }

  // TODO: Move this to a component store
  setCandidateNavSource(value: CandidateNavigationSource) {
    this.candidateNavigationSource.next(value)
  }

  addQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    })
  }

  updateQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    })
  }
}
