import { BaseListenConfig, Boolean, Default, IFeatures, Json } from '@engineering11/config-web'

export interface CnectFeatures extends IFeatures {
  core: boolean
  portfolio: boolean
  virtualDialogues: boolean
  messaging: boolean
  notifications: boolean
  resumeSharing: boolean
  resumeParsing: boolean
  informationalContent: boolean
  candidateProfilePDF: boolean
  referrals: boolean
  network: boolean
  communities: boolean
  jobApplicationChannels: boolean
  jobSearch: boolean
  promotions: boolean
  promoteResumeParsing: boolean
  jobChannels: boolean
}

export const defaultFeatures: CnectFeatures = {
  core: false,
  portfolio: false,
  virtualDialogues: false,
  messaging: false,
  notifications: false,
  resumeSharing: false,
  resumeParsing: false,
  informationalContent: false,
  candidateProfilePDF: false,
  referrals: false,
  network: false,
  communities: false,
  jobApplicationChannels: false,
  jobSearch: false,
  promotions: false,
  promoteResumeParsing: false,
  jobChannels: false,
}

export class CnectConfig {
  @Boolean()
  @Default(false)
  fromRemoteConfig!: boolean

  @Json()
  @Default(defaultFeatures)
  features!: CnectFeatures
}

export interface CnectListenConfig extends BaseListenConfig {}
