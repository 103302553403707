<div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-3 e11-gap-4">
  <div class="e11-flex e11-col-span-1 e11-row-span-2 e11-items-center e11-justify-center">
    <e11-profile-pic [imageUrl]="candidate.photoURL" [size]="'xxxl'" [type]="'circular'"> </e11-profile-pic>
  </div>

  <div class="e11-col-span-1 md:e11-col-span-2">
    <h3 class="">{{ candidate.firstName }} {{ candidate.lastName }}</h3>
  </div>

  <div class="">
    <div class="rating" *ngIf="!fromCandidate">
      <span class="stars" *ngFor="let star of makeStarRatingArray($any(candidate).starRating); let i = index">
        <i class="fas fa-lg fa-star color-secondary"></i>
      </span>
    </div>

    <!-- Email -->
    <div *ngIf="candidate.email || !hideEmptyCards" class="e11-flex e11-mb-3 e11-items-center">
      <span class="material-icons-outlined e11-mr-2"> email </span>
      <span class="e11-truncate" *ngIf="candidate.email">
        {{ candidate.email }}
      </span>
      <span class="e11-text-skin-light e11-italic e11-relative e11-top-[2px]" *ngIf="!candidate.email && !hideEmptyCards">
        {{ 'No email' | translate }}
      </span>
    </div>
    <!-- Phone -->
    <div *ngIf="candidate.phoneNumber || !hideEmptyCards" class="e11-flex e11-mb-3 e11-items-center e11-truncate">
      <span class="material-icons-outlined e11-mr-2"> smartphone </span>
      <span class="e11-truncate" *ngIf="candidate.phoneNumber">
        {{ candidate.phoneNumber }}
      </span>
      <span class="e11-text-skin-light e11-italic e11-relative e11-top-[2px]" *ngIf="!candidate.phoneNumber && !hideEmptyCards">
        {{ 'No phone number' | translate }}
      </span>
    </div>
    <!-- Address -->
    <div *ngIf="candidate?.address || !hideEmptyCards" class="e11-flex e11-mb-3 e11-items-center e11-truncate">
      <span class="material-icons-outlined e11-mr-2"> cottage </span>
      <span
        [ngClass]="{
          'e11-text-skin-light e11-italic e11-relative e11-top-[2px] e11-truncate':
            !candidate?.address || (!candidate?.address?.city && candidate?.address?.state)
        }"
      >
        <span *ngIf="candidate?.address?.city">{{ candidate?.address?.city }}</span>
        <span *ngIf="candidate?.address?.state">, {{ candidate?.address?.state }}</span>
        <span *ngIf="!candidate?.address && !hideEmptyCards"> {{ 'Address not added.' | translate }} </span>
      </span>
    </div>
  </div>

  <!-- Website, military, confirmations -->
  <div class="">
    <!-- Website -->
    <div class="e11-flex e11-mb-3 e11-items-center">
      <a *ngIf="candidate?.website || !hideEmptyCards" class="e11-flex e11-items-center e11-truncate" href="{{ candidate?.website }}" target="_blank">
        <span class="material-icons-outlined e11-mr-2"> launch </span>
        <span class="e11-cursor-pointer hover:e11-text-skin-primary-accent e11-truncate">
          <span *ngIf="candidate.website">
            {{ candidate.website }}
          </span>
        </span>
      </a>
      <span class="e11-text-skin-light e11-italic e11-relative e11-top-[2px] e11-truncate" *ngIf="!candidate.website && !hideEmptyCards">
        {{ 'No website' | translate }}
      </span>
    </div>

    <!-- Military -->
    <div *ngIf="candidate.militaryAffiliation && candidate.militaryAffiliation.length > 0" class="e11-flex e11-mb-3 e11-items-center">
      <span class="material-icons-outlined e11-mr-2"> military_tech </span>
      <span class="e11-mb-0 e11-truncate">{{ 'Military Affiliations' | translate }}</span>
      <span class="e11-text-skin-light e11-italic e11-relative e11-top-[2px]" *ngIf="!candidate.militaryAffiliation">
        {{ 'None' | translate }}
      </span>
    </div>

    <ul class="e11-ml-8 e11-mb-3" *ngIf="candidate.militaryAffiliation && candidate.militaryAffiliation.length > 0">
      <li class="e11-float-left e11-mr-2 e11-text-sm" *ngFor="let ma of candidate?.militaryAffiliation; let i = index">
        {{ ma }}<span *ngIf="i + 1 < (candidate?.militaryAffiliation)!.length">,</span>
      </li>
      <div class="e11-clear-both"></div>
    </ul>

    <!-- Confirmations -->
    <!-- @Todo: Are these out of place here. Shouldn't these be application specific and not grouped with personal details? -->
    <job-application-confirmations *ngIf="candidate.candidateResponses" [confirmations]="candidate.candidateResponses" [userMode]="userMode">
    </job-application-confirmations>
  </div>
</div>

<div class="e11-w-full e11-mt-4">
  <h4 *ngIf="candidate.summary || !hideEmptyCards" class="e11-mb-4">{{ 'Summary' | translate }}</h4>
  <!-- <div class="e11-w-full summary e11-p-6 e11-border e11-border-skin-app-borders e11-rounded-lg"> -->
  <div *ngIf="candidate.summary" [ngxSummernoteView]="candidate?.summary || ''"></div>
  <p *ngIf="!candidate.summary && !hideEmptyCards" class="empty-state-datapoint">{{ 'Summary not added.' | translate }}</p>
  <!-- </div> -->
</div>
