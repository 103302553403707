<!-- Double up rows on the split containers -->
<div>
  <!-- Main -->
  <div class="content-main">
    <div class="row">
      <div class="col-xs-9 nopadding">
        <h2 class="e11-text-skin-primary-accent">{{ 'Careers Page' | translate }}</h2>
        <p>{{ "Manage Your Company's Careers Page" | translate }}</p>
      </div>
      <div class="col-xs-3 nopadding e11-flex e11-flex-col e11-items-end">
        <e11-button
          [style]="'pill outlined'"
          id="submit_preview"
          value="{{ 'Preview' | translate }}"
          color="primary-accent"
          [type]="'submit'"
          [buttonGroup]="false"
          (click)="preview()"
        >
        </e11-button>
      </div>
    </div>
  </div>
</div>

<div>
  <!-- Main -->
  <div class="content-main">
    <e11-panel [hasBorder]="true" [hasShadow]="false">
      <form class="" [formGroup]="formHeroSection" (ngSubmit)="onSubmitHero()" novalidate>
        <div>
          <div class="e11-mb-5">
            <h3 class="e11-font-primary-demibold">{{ 'Hero Section' | translate }}</h3>
            <p>
              {{
                'Control the hero section settings for your careers page. This appears directly below the header and can help make the pages more engaging with images, color, and text.'
                  | translate
              }}
            </p>
          </div>
          <div>
            <div class="e11-mb-2">
              <h5>{{ 'Headline Text' | translate }}</h5>
              <e11-input
                [type]="'text'"
                name="title"
                id="id_url"
                [style]="'standard'"
                labelColor="primary"
                [size]="'lg'"
                [borderGlow]="'primary'"
                autocomplete="off"
                formControlName="title"
                [parentForm]="formHeroSection!"
              >
                <e11-input-errors [parentForm]="formHeroSection" [formControl]="formHero.title" label="{{ 'Headline Text' | translate }}">
                </e11-input-errors>
              </e11-input>
            </div>
            <div>
              <ui-textarea
                label="{{ 'Supporting Copy (maximum 500 characters)' | translate }}"
                labelColor="primary"
                id="textarea_description"
                name="description"
                borderGlow="primary"
                [rows]="4"
                [parentForm]="formHeroSection!"
                formControlName="description"
              >
                <div class="e11--mt-5">
                  <e11-input-errors [parentForm]="formHeroSection" [formControl]="formHero.description" label="{{ 'Supporting Copy' | translate }}">
                  </e11-input-errors>
                </div>
              </ui-textarea>
            </div>
          </div>
        </div>

        <div>
          <div>
            <h3 class="e11-font-primary-demibold">{{ 'Hero Image' | translate }}</h3>
            <p>{{ 'Choose an image to display' | translate }}</p>
          </div>
          <div class="e11-justify-center md:e11-justify-start e11-flex e11-flex-row e11-flex-wrap e11-gap-2">
            <div
              class="e11-flex e11-mb-4 e11-w-48 e11-bg-white e11-border-2 e11-relative e11-cursor-pointer"
              [ngClass]="{ 'e11-border-skin-primary-accent': formHero.headerImage.value === '' }"
              (click)="setHeaderImage('')"
            >
              <p class="e11-m-auto e11-text-black">{{ 'No image' | translate }}</p>
              <span
                *ngIf="formHero.headerImage.value === ''"
                class="selected-hero-checkmark material-icons-outlined e11-absolute e11-rounded-full e11-bg-skin-primary-accent e11-text-white e11-text-[14px] e11-p-1"
                >check</span
              >
            </div>
            <div
              *ngFor="let image of heroSectionImages"
              class="e11-flex e11-mb-4 e11-w-48 e11-bg-white e11-border-2 e11-relative e11-cursor-pointer"
              [ngClass]="{ 'e11-border-skin-primary-accent': formHero.headerImage.value == image }"
              (click)="setHeaderImage(image)"
            >
              <img [src]="image" [alt]="'hero images'" [title]="'hero images'" class="e11-img-responsive e11-w-48 e11-bg-cover" />
              <span
                *ngIf="formHero.headerImage.value === image"
                class="selected-hero-checkmark material-icons-outlined e11-rounded-full e11-bg-skin-primary-accent e11-text-white e11-text-[14px] e11-p-1"
                >check</span
              >
            </div>
          </div>
        </div>

        <e11-button
          *ngIf="formHeroSection"
          [style]="'pill outlined'"
          id="submit_about"
          value="{{ 'Save' | translate }}"
          color="primary-accent"
          [type]="'submit'"
          [buttonGroup]="false"
        >
        </e11-button>
      </form>
    </e11-panel>

    <e11-panel [hasBorder]="true" [hasShadow]="false">
      <div class="e11-mb-2">
        <h3 class="e11-font-primary-demibold">{{ 'Careers Page Theme' | translate }}</h3>
        <p class="e11-m-0 e11-text-sm">
          {{
            'Select one of the templates below for how you would like your Careers Page to look. This will affect the color of the header, hero section, About Us section, and page buttons.'
              | translate
          }}
        </p>
      </div>

      <div class="e11-mb-2">
        <p class="e11-m-0 e11-mb-1 e11-text-xs">{{ "Primary Color - applies to the header, hero section, and 'About Us' areas." | translate }}</p>
        <p class="e11-m-0 e11-mb-1 e11-text-xs">{{ 'Secondary Color - applies to the button color' | translate }}</p>
        <p class="e11-m-0 e11-mb-1 e11-text-xs">{{ "Text Color - applies to text in the header, hero section, and 'About Us' areas" | translate }}</p>
        <p class="e11-m-0 e11-mb-1 e11-text-xs">{{ 'Button Text Color - applies to text shown in buttons' | translate }}</p>
      </div>
      <div class="e11-flex e11-gap-4 e11-flex-wrap e11-w-full">
        <fieldset
          *ngFor="let item of availableThemes; let i = index"
          class="e11-w-60 e11-border-2 e11-rounded-md e11-border-solid e11-px-4 e11-pb-4 e11-cursor-pointer hover:e11-bg-skin-primary-accent/10"
          [ngClass]="{
            'e11-border-skin-primary-accent e11-bg-skin-primary-accent/10': item.name === activeTheme,
            'e11-border-skin-app-borders': item.name !== activeTheme
          }"
          (click)="setActiveTheme(item.name)"
        >
          <legend
            class="e11-p-2"
            [ngClass]="{
              'e11-text-skin-primary-accent': item.name === activeTheme
            }"
          >
            {{ item.name }}
          </legend>
          <div class="e11-flex e11-justify-between">
            <span> {{ 'Primary color' | translate }}</span>
            <div
              class="e11-h-8 e11-w-20 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.primary
              }"
            ></div>
          </div>
          <div class="e11-mt-3 e11-flex e11-justify-between">
            <span> {{ 'Secondary color' | translate }} </span>
            <div
              class="e11-h-8 e11-w-20 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.secondary
              }"
            ></div>
          </div>
          <div class="e11-mt-3 e11-flex e11-justify-between">
            <span> {{ 'Text color' | translate }} </span>
            <div
              class="e11-h-8 e11-w-20 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.text
              }"
            ></div>
          </div>
          <div class="e11-mt-3 e11-flex e11-justify-between">
            <span> {{ 'Button text color' | translate }} </span>
            <div
              class="e11-h-8 e11-w-20 e11-border e11-border-solid e11-border-black"
              [ngStyle]="{
                'background-color': item.buttonText
              }"
            ></div>
          </div>
        </fieldset>
      </div>
    </e11-panel>

    <div class="row">
      <div class="col-xs-12 nopadding">
        <e11-panel [hasBorder]="true" [hasShadow]="false">
          <div class="row e11-mb-4">
            <div class="col-sm-12 nopadding-left">
              <div class="row flex-align-center">
                <div class="col-md-1 col-xs-4 nopadding">
                  <ui-switch [checked]="showAbout" (valueChange)="changeShowAbout($event)"></ui-switch>
                </div>
                <div class="col-md-11 col-xs-8 nopadding-left">
                  <h3 class="e11-font-primary-demibold">{{ 'About Text' | translate }}</h3>
                  <span>{{
                    'Configure an ‘About Us’ section to show on each job post on your public careers page. Enter your desired text, and use the toggle to turn the section on or off.'
                      | translate
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <form [formGroup]="formAbout" (ngSubmit)="onSubmitAbout()" novalidate>
              <e11-text-editor class="e11-block e11-mb-4" id="text_about" name="about" [formControlName]="'about'" [value]="aboutText || ''">
                <!-- employer?.about || '' -->
              </e11-text-editor>
              <e11-button
                *ngIf="formAbout"
                [style]="'pill outlined'"
                id="submit_about"
                value="{{ 'Save' | translate }}"
                color="primary-accent"
                [type]="'submit'"
                [buttonGroup]="false"
                [disabled]="formAbout.invalid || !formAbout.dirty"
              >
              </e11-button>
            </form>
          </div>
        </e11-panel>
      </div>
    </div>

    <div class="row e11-mb-16">
      <div class="col-xs-12 nopadding">
        <e11-panel [hasBorder]="true" [hasShadow]="false">
          <h3 class="e11-font-primary-demibold e11-w-3/4">{{ 'Careers Page URL' | translate }}</h3>

          <p class="e11-w-3/4">
            {{
              'Use this URL to preview your public Careers Page. Once you’re ready, this URL can be used to control the redirect from your company website over to the cnect Careers Page.'
                | translate
            }}
          </p>

          <div class="e11-w-full e11-pb-4">
            <form class="" [formGroup]="formLink" (ngSubmit)="onSubmitAbout()" novalidate>
              <ui-input
                [type]="'text'"
                name="urli"
                id="id_url"
                [style]="'standard'"
                labelColor="primary"
                [size]="'large'"
                [borderGlow]="'primary'"
                autocomplete="off"
                [disabled]="true"
                formControlName="link"
                [parentForm]="formLink!"
              >
              </ui-input>
            </form>
            <div class="e11-flex e11-items-center e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer" (click)="copyLink()">
              <span class="material-icons-outlined e11-mr-2">content_copy</span>

              <span>{{ 'Click to copy public link' | translate }}</span>
              <div *ngIf="showCopiedMessage">
                <span class="e11-ml-2">{{ 'Copied to clipboard' | translate }}</span>
              </div>
            </div>
          </div>
        </e11-panel>
      </div>
    </div>
  </div>
</div>
