import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import * as fromReduce from '@employer/app/store/reducers'

// Third party
import jwt_decode from 'jwt-decode'
import { Store } from '@ngrx/store'
import { OnLogIn, TokenStorage } from 'shared-lib'
import { IRegistrationResult } from '@engineering11/registration-web'
import { UiNotificationMessage, UiNotificationsService } from '@engineering11/ui/ui-notifications'

@Component({
  selector: 'app-register-invite',
  templateUrl: './register-invite.component.html',
  styleUrls: ['./register-invite.component.scss'],
})
export class RegisterInviteComponent implements OnInit {
  registrationCode?: string
  showError = false
  error: any
  loading = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private tokenStorage: TokenStorage,
    private store: Store<fromReduce.user.State>,
    private notificationsService: UiNotificationsService
  ) {}
  async ngOnInit() {
    this.registrationCode = this.activatedRoute.snapshot.params['code']
  }

  async onSubmit(result: IRegistrationResult) {
    this.loading = true
    this.tokenStorage.setItem('user', JSON.stringify(result.appUser))
    await new Promise(resolve => setTimeout(resolve, 4000))
    this.store.dispatch(new OnLogIn({ token: result.authToken, appUserId: result.appUser.id }))
    this.loading = false
  }

  onError(error: any): void {
    const notification = new UiNotificationMessage('An error occurred, unable to process registration', 'error', true, 'Close')
    this.notificationsService.popNotificationMessage(notification)
  }
}
