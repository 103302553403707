import { Injectable } from '@angular/core'
import { BaseSessionStorageService } from '@engineering11/web-utilities'

export interface ISessionStorage {
  referrerId?: string
  networkInviteToken?: string
}

@Injectable({ providedIn: 'root' })
export class SessionStorageService extends BaseSessionStorageService<ISessionStorage> {}
