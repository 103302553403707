<div class="bg"></div>
<div class="view e11-flex e11-flex-col e11-justify-center e11-py-6 e11-px-6">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <div class="e11-w-full e11-flex e11-justify-center">
      <app-logo-blue [height]="'96px'" [resizable]="false"></app-logo-blue>
    </div>
    <h1 class="e11-text-center e11-font-extrabold">
      {{ "Let's find your account" | translate }}
    </h1>
    <p class="e11-text-center">
      {{ 'Please enter the email address associated with your account' | translate }}
    </p>
  </div>

  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <e11-panel [hasBorder]="true" [hasShadow]="false" [bgOpacity]="90" [contentClassOverrides]="'e11-p-6 e11-pb-12'">
      <auth-forgot-password (continueClicked)="toLogin()"></auth-forgot-password>
      <div class="e11-w-full e11-text-righ e11-mt-2 e11-text-right">
        <a (click)="toLogin()" class="e11-text-sm e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer">
          {{ 'Return to Login' | translate }}
        </a>
      </div>
    </e11-panel>
  </div>
</div>
