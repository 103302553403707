<div *ngIf="loaded">
  <single-first-impression-card
    *ngIf="!showPlayer"
    [firstImpression]="signedFirstImpression"
    [userMode]="userMode"
    (openVideoPlayer)="openVideoPlayer($event)"
    (openRequestUpdate)="openRequestUpdate.emit(true)"
    [readonly]="readOnly"
  ></single-first-impression-card>
  <div class="e11-h-[400px]" *ngIf="showPlayer && userMode === enumUserMode.Public">
    <video-player
      *ngIf="showPlayer && userMode === enumUserMode.Public"
      [playerSize]="playerSizeEnum.Small"
      [videoPlayerResource]="videoPlayerResource"
      (playerClose)="closeVideoModal()"
    >
    </video-player>
  </div>
</div>

<e11-modal
  #modalItem
  width="md"
  [bodyHeight]="'large'"
  bodyClassOverrides="e11-px-0 e11-py-0"
  [showHeader]="false"
  [hideCloseButton]="false"
  [closer]="true"
  [showFooter]="false"
  (modalClosed)="closeVideoModal()"
>
  <div modal-body class="video-container">
    <video-player *ngIf="showPlayer" [playerSize]="playerSizeEnum.Small" [videoPlayerResource]="videoPlayerResource"></video-player>
  </div>
</e11-modal>
