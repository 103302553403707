// Framework
import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

// Platform specific
import { IAuthService } from '@engineering11/auth-web'
import { redirectLoggedInTo, canActivate } from '@angular/fire/compat/auth-guard'

import { TokenStorage } from 'shared-lib'

const redirectLoggedInToItems = () => redirectLoggedInTo(['/'])

@Injectable({
  providedIn: 'root',
})
export class DenyGuard implements CanActivate {
  constructor(public authService: IAuthService, public router: Router, public tokenStorage: TokenStorage) {}

  canActivate(): any {
    return canActivate(redirectLoggedInToItems)
  }
}
