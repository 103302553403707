// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Routing
import { LayoutSidebarRoutingModule } from './layout-sidebar.routing'

// Modules
import { SharedModule } from '../_shared/shared.module'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'

// Components
import { LayoutSidebarComponent } from './layout-sidebar.component'
import { LayoutSidebarStartComponent } from './views/start/start.component'
import { LayoutSidebarView2Component } from './views/view2/view2.component'
import { ConfirmModule } from '@engineering11/ui/ui-confirm'

@NgModule({
  declarations: [LayoutSidebarComponent, LayoutSidebarStartComponent, LayoutSidebarView2Component],
  imports: [
    CommonModule,
    SharedModule,
    LayoutSidebarRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmModule.forRoot(),
  ],
  exports: [],
  providers: [],
})
export class LayoutSidebarModule {}
