<!-- Empty State -->
<!-- Populated state -->
<div class="e11-grid e11-grid-cols-1 lg:e11-grid-cols-2 e11-gap-4" *ngIf="education.length > 0">
  <div class="e11-mb-6" *ngFor="let item of education; let i = index">
    <h4 class="">{{ item.schoolName }}</h4>
    <div class="">{{ stateToStatus.get(item.degreeLevel) || '' | translate }}</div>
    <div class="">
      <span>
        {{ item.degreeTitle }}
        <span class="e11-text-sm" *ngIf="item.notCompleted">({{ 'Not yet Completed' | translate }}) </span>
      </span>
    </div>
    <div *ngIf="item.degreeType" class="detail-line-item title">{{ item.degreeType }}</div>
    <date-display-start-end class="detail-line-item" [item]="item"></date-display-start-end>
  </div>
</div>
