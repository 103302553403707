<div class="e11-w-full e11-flex">
  <div class="e11-grow">
    <h2 class="e11-text-skin-primary-accent">{{ 'Department Detail' | translate }}</h2>
    <p class="e11-flex e11-items-center e11-mb-0 e11-text-skin-primary-accent e11-cursor-pointer" [routerLink]="['/customer/departments']">
      <span class="material-icons-outlined md-18 e11-mr-1"> west </span>{{ 'Back to department list' | translate }}
    </p>
  </div>
  <div class="e11-grow e11-flex e11-items-center e11-justify-end"></div>
</div>

<!-- Margin @0 to get more control over area -->
<e11-divider [margin]="1" [width]="100"></e11-divider>

<div *ngIf="department">
  <!-- Main -->
  <div class="content-main">
    <div class="e11-w-full">
      <e11-panel [hasBorder]="true" [hasShadow]="false">
        <form class="" [formGroup]="formDepartment" (ngSubmit)="onSubmitformDepartment()" novalidate>
          <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-8 e11-mb-8">
            <div>
              <div class="e11-w-full">
                <e11-input
                  [type]="'text'"
                  name="name"
                  id="name_formDepartment"
                  label="{{ 'Department Name' | translate }}"
                  [parentForm]="formDepartment"
                  formControlName="name"
                  [autofocus]="true"
                  autocomplete="off"
                  [required]="true"
                  tabindex="0"
                >
                  <e11-input-errors [parentForm]="formDepartment" [formControl]="f.name" label="{{ 'Name' | translate }}"></e11-input-errors>
                </e11-input>
              </div>

              <div class="e11-w-full e11-mb-7">
                <e11-input
                  [type]="'text'"
                  name="internalCode"
                  id="departmentCode_formDepartment"
                  label="{{ 'Department Code' | translate }}"
                  [parentForm]="formDepartment"
                  formControlName="internalCode"
                  autocomplete="off"
                  tabindex="1"
                >
                </e11-input>
              </div>
            </div>
            <div>
              <div class="e11-w-full">
                <div class="e11-w-full e11-flex e11-items-center e11-pt-4 e11-mb-2">
                  <div class="e11-grow e11-flex e11-items-center">
                    <h3 class="e11-mb-0 e11-float-left e11-mr-4">{{ 'Status' | translate }}</h3>
                    <ui-switch size="medium" [checked]="department?.active || false" (valueChange)="setActive($event)"></ui-switch>
                  </div>
                  <div class="e11-grow e11-flex e11-justify-end"></div>
                </div>

                <div class="e11-w-full e11-flex">
                  <p>
                    {{
                      'Status allows a Department to be available and used for Job and Job Template Listings. (as well as in other places). If you turn the status off the department will not show in screens using departments.'
                        | translate
                    }}
                  </p>
                </div>
                <div class="e11-flex e11-w-full" *ngIf="department && isExisting">
                  <span class="e11-flex e11-items-center e11-text-skin-secondary e11-cursor-pointer" (click)="deleteDepartment()">
                    <span class="material-icons-outlined md-18 e11-mr-1"> delete </span>
                    {{ 'Delete Department' }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="e11-w-full e11-flex e11-items-center e11-justify-center">
            <e11-button
              id="department_cancel"
              value="{{ 'Cancel' | translate }}"
              color="neutral"
              [style]="'pill outlined'"
              [type]="'button'"
              [buttonGroup]="true"
              [routerLink]="['/customer/departments']"
              tabindex="4"
            >
            </e11-button>
            <e11-button
              id="submit_department"
              value="{{ 'Save Department' | translate }}"
              color="primary-accent"
              [type]="'submit'"
              [style]="'pill'"
              tabindex="5"
            ></e11-button>
          </div>
        </form>
      </e11-panel>
    </div>
  </div>
</div>

<!-- Reject -->
<e11-confirmation
  #confirmation
  confirmTitle="{{ 'Delete Department' | translate }}"
  confirmText="{{ 'Delete' | translate }}"
  cancelText="{{ 'Cancel' | translate }}"
  [backdropStyle]="'dark'"
  (answer)="confirmationAnswer($event)"
>
  <div [innerHTML]="confirmationMessage | translate"></div>
</e11-confirmation>
