import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation, ViewChild } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { IWorkHistory } from './../../model/interfaces'

@Component({
  selector: 'ui-work-history-line-item',
  template: `
    <div class="e11-border-b e11-border-skin-app-borders e11-pb-4 e11-mb-4">
      <div class="e11-flex">
        <div class="e11-w-3/4">
          <h4 class="">{{ workHistory.companyName }}</h4>
        </div>
        <div class="e11-w-1/4 e11-flex e11-justify-end">
          <span
            *ngIf="userMode === userModeEnum.Candidate"
            class="e11-flex e11-items-center e11-cursor-pointer e11-text-skin-primary-accent hover:e11-brightness-50 e11-text-sm"
            (click)="viewDetail.emit(workHistory); editView.emit(true)"
          >
            <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary e11-mr-1"> edit </span>
            Edit
          </span>
        </div>
      </div>

      <div class="e11-break-words e11-line-clamp-2">{{ workHistory.jobTitle }}</div>
      <div
        #readMoreSection
        class="e11-readmore-content e11-break-words"
        [ngClass]="{ 'e11-line-clamp-3': hasReadMore && workHistory.id !== workHistoryId }"
        [innerHTML]="workHistory.jobDescription"
      ></div>

      <div class="e11-w-full e11-flex">
        <div class="e11-w-1/2">
          <date-display-start-end class="e11-text-skin-light e11-text-sm" [item]="workHistory"> </date-display-start-end>
        </div>
        <div class="e11-w-1/2 e11-flex e11-items-center e11-justify-end">
          <span
            *ngIf="hasReadMore"
            (click)="readMore(workHistory.id)"
            class="e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer"
          >
            <span class="e11-flex e11-items-center e11-text-sm" *ngIf="workHistory.id !== workHistoryId">
              <span class="material-icons-outlined"> expand_more </span>{{ 'Read more' | translate }}
            </span>
            <span class="e11-flex e11-items-center e11-text-sm" *ngIf="workHistory.id === workHistoryId">
              <span class="material-icons-outlined"> expand_less </span>{{ 'Close' | translate }}
            </span>
          </span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./ui-work-history-line-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiWorkHistoryLineItemComponent implements AfterViewChecked {
  @ViewChild('readMoreSection') private readMoreSection!: ElementRef
  userModeEnum = UserMode
  workHistoryId: string = ''
  @Input()
  userMode: UserMode = UserMode.Candidate

  @Input()
  workHistory!: IWorkHistory

  @Input()
  removable: boolean = false

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IWorkHistory>()

  readmoreHeight?: number | undefined
  hasReadMore: boolean = false

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewChecked() {
    this.readmore(16, 1.5, 3)
    this.changeDetector.detectChanges()
  }

  // @Todo - Refactor into readmore component - character count unreliable
  readmore(pixelHeight: number, lineHeight: number, maxLines: number) {
    let maxHeight = pixelHeight * lineHeight * maxLines // max readMore container height before line clamp is added
    if (this.readMoreSection.nativeElement && this.readmoreHeight === undefined) {
      this.readmoreHeight = this.readMoreSection.nativeElement.getBoundingClientRect().height
      if (this.readMoreSection.nativeElement.getBoundingClientRect().height > maxHeight) {
        this.hasReadMore = true
      } else {
        this.hasReadMore = false
      }
    }
  }

  readMore(workHistoryId: string) {
    workHistoryId === this.workHistoryId ? (this.workHistoryId = '') : (this.workHistoryId = workHistoryId)
  }
}
