// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEmployerEnvironment } from 'shared-lib'

export const environment: IEmployerEnvironment = {
  production: false,
  env: 'dev',
  firebaseConfig: {
    apiKey: 'AIzaSyCnP-U4Q9cCTJ6jCB5aAfdNdPm66Owq6_4',
    authDomain: 'cnect-dev.firebaseapp.com',
    databaseURL: 'https://cnect-dev.firebaseio.com',
    projectId: 'cnect-dev',
    storageBucket: 'cnect-dev.appspot.com',
    messagingSenderId: '114692117909',
    appId: '1:114692117909:web:74e5f41c97794b8174129c',
    measurementId: 'G-2VQ0MJS397',
  },

  services: {
    cloudFunctions: 'https://us-central1-cnect-dev.cloudfunctions.net',
    jobs: 'https://jobs-rest-dot-cnect-dev.uc.r.appspot.com',
    registration: 'https://registration-rest-dot-cnect-dev.uc.r.appspot.com',
    events: 'https://events-rest-dot-cnect-dev.uc.r.appspot.com/',
    applicationHistory: 'https://application-history-rest-dot-cnect-dev.uc.r.appspot.com',
    user: 'https://user-rest-dot-cnect-dev.uc.r.appspot.com',
    content: 'https://content-rest-dot-cnect-dev.uc.r.appspot.com',
    employer: 'https://employer-rest-dot-cnect-dev.uc.r.appspot.com/',
    auth: 'https://auth-rest-dot-cnect-dev.uc.r.appspot.com/',
    files: 'https://files-rest-dot-cnect-dev.uc.r.appspot.com/',
    messaging: 'https://messaging-rest-dot-cnect-dev.uc.r.appspot.com/',
    virtualDialogue: 'https://virtual-dialogue-rest-dot-cnect-dev.uc.r.appspot.com',
    token: 'https://token-rest-dot-cnect-dev.uc.r.appspot.com/',
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LcVas0aAAAAAAcHaLi9835I5X1EPovMGnsTVm_Z',
  tenantId: 'B2B-USER-z43gz',
  algoliaAppId: 'SH1DAV8W6D',
  cdnCname: 'dev-content.cnected.com',
  candidateExternalJobUrl: 'http://localhost:4600/#/landing/job/',
  candidateEmailActionRoute: 'http://localhost:4600/#/auth/actions',
  careersPageBaseLink: 'https://dev-careers.cnected.com/',
  employerEmailSuccessRoute: 'http://localhost:4610/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'sfIqysHX4k910Rq0K4r3',
  emailVerificationEmailTemplateId: '5KYG0zYnsdtR0DLtgjwv',
  betaMessageId: '1HV5MRdznzPO0ivgBbom',
  version: '0e64d29',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
