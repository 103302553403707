import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { pipe } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { searchKey } from '../reducers'

export const getSearchKeyStore = createFeatureSelector<searchKey.State>('searchKey')
export const getUserSearchKeyEntities = createSelector(getSearchKeyStore, searchKey.searchKeyEntitySelectors.selectAll)

export const getUserSearchKey = createSelector(getSearchKeyStore, (store: searchKey.State) => store.userSearchKey)
export const getJobProfileSearchKey = createSelector(getSearchKeyStore, (store: searchKey.State) => store.profileSearchKey)
export const getJobPostSearchKey = createSelector(getSearchKeyStore, (store: searchKey.State) => store.postSearchKey)
export const getSearchKeyLoading = createSelector(getSearchKeyStore, (store: searchKey.State) => store.loading)

const _getCandidateSearchKeyForJobPost = (jobPostId: string) =>
  createSelector(getSearchKeyStore, (searchKeyState: searchKey.State) => {
    const searchKeyMap = searchKeyState.postCandidateSearchKeyMap
    return searchKeyMap ? searchKeyMap[jobPostId] : null
  })
export const getCandidateSearchKeyForJobPost = (jobPostId: string) =>
  pipe(
    select(_getCandidateSearchKeyForJobPost(jobPostId)),
    map(candidateSearchKeyData => candidateSearchKeyData?.jobPostCandidateSearchKey),
    distinctUntilChanged()
  )
