<!-- Confirmation Details -->
<div class="relative margin-top-4" *ngIf="confirmations">
  <span class="link underlined e11-cursor-pointer" (mouseenter)="toggleConfirmationDetails()" (mouseleave)="toggleConfirmationDetails()">
    {{ 'Confirmed Details' | translate }}
  </span>

  <div class="confirmations {{ userMode }}" [ngClass]="{ active: showConfirmationDetails }">
    <!-- Conditional answers-->
    <!-- Hours -->
    <div
      class="answer"
      *ngIf="confirmations.hasOwnProperty('hoursConfirmed')"
      [ngClass]="{ yes: confirmations.hoursConfirmed === true, no: confirmations.hoursConfirmed === false }"
    >
      <span *ngIf="confirmations.hoursConfirmed === true">
        <i class="fas fa-check-circle"></i>
        {{ 'CAN work the hours of availability for the job' | translate }}
      </span>
      <span *ngIf="confirmations.hoursConfirmed === false">
        <i class="fas fa-times-circle"></i>
        {{ 'CAN NOT work the hours of availability for the job' | translate }}
      </span>
    </div>
    <!-- Travel -->
    <div
      class="answer"
      *ngIf="confirmations.hasOwnProperty('travelConfirmed')"
      [ngClass]="{ yes: confirmations.travelConfirmed === true, no: confirmations.travelConfirmed === false }"
    >
      <span *ngIf="confirmations.travelConfirmed === true">
        <i class="fas fa-check-circle"></i>
        {{ 'DOES MEET the travel requirements for the job' | translate }}
      </span>
      <span *ngIf="confirmations.travelConfirmed === false">
        <i class="fas fa-times-circle"></i>
        {{ 'DOES NOT MEET the travel requirements for the job' | translate }}
      </span>
    </div>

    <!-- persistent answers -->
    <div class="answer text-primary">
      {{ confirmations.yearsRelevantExperience || 0 }} years and {{ confirmations.monthsRelevantExperience || 0 }}
      months relating to the job
    </div>

    <div class="answer text-primary">
      <span *ngIf="confirmations.legalWorkAuthorization === true">
        {{ 'CAN legally work in the U.S.' | translate }}
      </span>
      <span *ngIf="confirmations.legalWorkAuthorization === false">
        {{ 'CAN NOT legally work in the U.S.' | translate }}
      </span>
    </div>

    <div class="answer text-primary">
      <span *ngIf="confirmations.visaSponsorshipRequired === false">
        {{ 'WILL NOT require a sponsorship' | translate }}
      </span>
      <span *ngIf="confirmations.visaSponsorshipRequired === true">
        {{ 'WILL require a sponsorship' | translate }}
      </span>
    </div>
  </div>
</div>
<!-- /Confirmation Details -->
