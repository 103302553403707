import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { EventsService } from '@employer/app/services/events.service'
import { EmployerNavigationService } from '@employer/app/services/navigation.service'

@Component({
  selector: 'action-success',
  templateUrl: './action-success.component.html',
  styleUrls: ['./action-success.component.scss'],
})
export class ActionSuccessComponent implements OnInit {
  mode?: string
  constructor(private route: ActivatedRoute, private router: Router, private navigationService: EmployerNavigationService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.mode = params['mode']
    })
  }

  login() {
    this.navigationService.toHome()
    // this.router.navigate(['/home'])
  }
}
