import { createFeatureSelector, createSelector } from '@ngrx/store'

import * as fromReducers from '@employer/app/store/reducers'

export const getCustomerStore = createFeatureSelector<fromReducers.customer.State>('customer')

export const getCustomerEntities = createSelector(getCustomerStore, fromReducers.customer.customerEntitySelectors.selectAll)

export const getCustomerDepartmentEntities = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.departments)
export const getCustomerLocationEntities = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.locations)
export const getCustomerUserEntities = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.users)
export const getCustomerLoadingStatus = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.loading)
