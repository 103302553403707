import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { IUserRolesProvider } from '@engineering11/permissions-web'
import { getCurrentUser } from 'shared-lib'
import { Observable } from 'rxjs'
import { isNotNil } from '@engineering11/utility'
import { filter, map } from 'rxjs/operators'
import { EMPLOYER_USER_ROLES } from '@cnect/user-shared'

export enum Permission {
  JOB_TEMPLATE_LIST = 'JOB_TEMPLATE_LIST',
  TEAM_MANAGE = 'TEAM_MANAGE',
  COMPANY_MANAGE = 'COMPANY_MANAGE',
}

export const PERMISSION_TO_ROLE = Object.freeze({
  [Permission.JOB_TEMPLATE_LIST]: [EMPLOYER_USER_ROLES.ADMINISTRATOR, EMPLOYER_USER_ROLES.INTERNAL_RECRUITER],
  [Permission.TEAM_MANAGE]: [EMPLOYER_USER_ROLES.ADMINISTRATOR],
  [Permission.COMPANY_MANAGE]: [EMPLOYER_USER_ROLES.ADMINISTRATOR],
})

@Injectable({ providedIn: 'root' })
export class UserRolesProvider implements IUserRolesProvider {
  constructor(private store: Store) {}
  getRoles(): Observable<string[]> {
    return this.store.select(getCurrentUser).pipe(
      filter(isNotNil),
      map(user => user.roles)
    )
  }
}
