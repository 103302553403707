// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Platform specific
import { LayoutSidebarComponent } from './layout-sidebar.component'
import { LayoutSidebarStartComponent } from './views/start/start.component'
import { LayoutSidebarView2Component } from './views/view2/view2.component'
import { FileNotFoundComponent } from '../_404/404.component'

const routes: Routes = [
  {
    path: '',
    component: LayoutSidebarComponent,
    // canActivate: [ AuthGuard ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LayoutSidebarStartComponent,
      },
      {
        path: 'start',
        component: LayoutSidebarStartComponent,
      },
      {
        path: 'view2',
        component: LayoutSidebarView2Component,
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutSidebarRoutingModule {}
