// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Modules
import { UiComponentLibraryModule } from '../_component-library/component-library.module'

// Components
import { FileNotFoundComponent } from './404.component'
import { SharedModule } from '../_shared/shared.module'

@NgModule({
  declarations: [FileNotFoundComponent],
  imports: [CommonModule, SharedModule, UiComponentLibraryModule],
  exports: [],
  providers: [],
})
export class FileNotFoundModule {}
