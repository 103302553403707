import { Injectable } from '@angular/core'
import { BaseLocalStorageService } from '@engineering11/web-utilities'
import { ICnectUser } from '../model/interfaces'

export interface ILocalStorage {
  user: ICnectUser
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends BaseLocalStorageService<ILocalStorage> {}
