// Framework
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import { IRequestedUpdate, RequestedUpdateFields } from '../../../model/candidate-application.model'
import { UserMode } from '../../../model/component-modes.enum'
import { RequestedFieldChanged } from '../../../model/enum'
import { IWorkHistory } from '../../../model/interfaces'
import { Subscription } from 'rxjs'
import { ViewportService } from '../../../service/viewport.service'

// Platform specific

@Component({
  selector: 'candidate-work-history-profile-share',
  templateUrl: './candidate-work-history.component.html',
  styleUrls: ['./candidate-work-history.component.scss'],
})
export class CandidateWorkHistoryProfileShareComponent implements OnInit, OnDestroy {
  workHistoryItem: IWorkHistory | undefined
  @Input() workHistory: IWorkHistory[] = []
  @Input() requests: IRequestedUpdate[] = []
  @Input() requestsDisabled = false
  @Input() viewAll: boolean = false
  @Input() editItem: boolean = false
  @Input() deleteItem: boolean = false
  @Input() userMode = UserMode.Candidate
  @Output() requestFromComponent = new EventEmitter()
  @Output() viewAllStatus = new EventEmitter()
  @Output() editingItem = new EventEmitter()
  @Output() deletingItem = new EventEmitter()

  @Input() hideViewOptionButton = false

  userModeEnum = UserMode
  openRequest?: RequestedUpdateFields

  isMobile: boolean = false
  viewportSubscription: Subscription

  itemSelect: number[] = []
  open?: any = 0 || null // View more.

  constructor(private viewportService: ViewportService) {
    this.viewportSubscription = this.viewportService.viewportSizeChanged$.subscribe(data => {
      this.isMobile = data == 'sm' || data == 'xs'
    })
  }

  ngOnInit(): void {
    this.open = null
    this.processRequests()
    if (this.workHistory.length > 0) {
      this.workHistoryItem = this.workHistory[0]
    }
  }

  ngOnDestroy() {
    this.viewportSubscription.unsubscribe()
  }

  processRequests() {
    this.openRequest = this.requests.find(
      item => item.requestedFieldChanged === RequestedFieldChanged.WorkHistory && !item.fulfilled
    )?.requestedFieldChanged
    return this.openRequest
  }

  viewMore(i: any) {
    this.viewAll = false
    this.viewAllStatus.emit(false)
    i === this.open ? (this.open = null) : (this.open = i)
  }

  requestModal() {
    this.requestFromComponent.emit('Work History')
  }

  editLineItem(item: IWorkHistory) {
    this.editingItem.emit(item)
  }
  deleteLineItem(item: IWorkHistory) {
    this.deletingItem.emit(item)
  }
}
