import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { applicationFieldMappings, ICandidateJobApplication } from '../model/candidate-application.model'
import {
  applicationStateMappings,
  APPLICATION_STATE,
  DegreeLevel,
  degreeLevelMappings,
  EmploymentType,
  employmentTypeMappings,
  ExperienceLevel,
  experienceLevelMapping,
} from '../model/enum'
import { dialogueQuestionTypeMappings, QuestionType } from '../model/virtual-dialogue.model'

const getTranslation = <T>(key: T, map: Map<T, string>) => map.get(key) || ''

@Pipe({ name: 'employmentType' })
export class EmploymentTypePipe implements PipeTransform {
  transform(value: EmploymentType | string): string {
    return getTranslation(value, employmentTypeMappings)
  }
}

@Pipe({ name: 'experienceLevel' })
export class ExperienceLevelPipe implements PipeTransform {
  transform(value: ExperienceLevel | string) {
    return getTranslation(value, experienceLevelMapping)
  }
}

@Pipe({ name: 'degreeLevel' })
export class DegreeLevelPipe implements PipeTransform {
  transform(value: DegreeLevel | string) {
    return getTranslation(value, degreeLevelMappings)
  }
}

@Pipe({ name: 'applicationState' })
export class ApplicationStatePipe implements PipeTransform {
  transform(value: APPLICATION_STATE | string) {
    return getTranslation(value, applicationStateMappings)
  }
}

@Pipe({ name: 'dialogueQuestionType' })
export class DialogueQuestionTypePipe implements PipeTransform {
  transform(value: QuestionType | string) {
    return getTranslation(value, dialogueQuestionTypeMappings)
  }
}

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'applicationField' })
export class ApplicationFieldPipe implements PipeTransform {
  transform(value: keyof ICandidateJobApplication | string) {
    return getTranslation(value, applicationFieldMappings)
  }
}
