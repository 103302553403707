// Framework
import { Component, Input, OnDestroy, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'

// Platform specific
import { LoadingService } from '@employer/app/services/loading.service'

@Component({
  selector: 'loader-view',
  templateUrl: './loader-view.component.html',
  styleUrls: ['./loader-view.component.scss'],
})
export class LoaderViewComponent implements OnDestroy {
  public isSpinnerVisible = true

  // @Input() public backgroundColor = 'rgba(255, 115, 170, 0.75)'

  constructor(private loadingService: LoadingService, @Inject(DOCUMENT) private document: Document) {
    this.loadingService.generalLoading.subscribe(isLoading => {
      this.isSpinnerVisible = isLoading
    })
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false
  }
}
