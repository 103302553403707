<e11-card [actionable]="true" [status]="(lastApplicationViewed$ | async) === item.id" id="candidateId_{{ item.id }}" (click)="candidateSelection()">
  <div class="e11-w-full e11-flex">
    <div class="e11-mr-4 e11-chart-percentage-container">
      <e11-chart-percentage [data]="item?.jobMatchScore || 0" [size]="'80px'"></e11-chart-percentage>
    </div>
    <div class="e11-grow">
      <div class="e11-w-full e11-flex">
        <div class="e11-grow">
          <h4 class="e11-line-clamp-1 e11-mb-1">{{ item.firstName }} {{ item.lastName }}</h4>

          <!-- Rating -->
          <div class="e11-flex e11-items-center e11-h-4">
            <span class="e11-flex e11-cursor-pointer e11-mb-1">
              <span class="material-icons-outlined md-18 e11-text-skin-complimentary" *ngFor="let star of fullStars(item?.starRating || 0)">
                star
              </span>
              <span class="material-icons-outlined md-18 e11-text-skin-light" *ngFor="let star of emptyStars(item?.starRating || 0)"> star </span>
            </span>
            <span class="material-icons-outlined e11-ml-2 e11-mb-1" *ngIf="item.firstImpression"> smart_display </span>
          </div>

          <p class="e11-text-xs e11-mb-0" *ngIf="item.address">
            {{ formatAddress(item.address) }}
          </p>

          <div class="e11-flex e11-items-center">
            <span class="e11-text-xs e11-mr-2 e11-text-skin-light">{{ 'Applied:' | translate }} {{ item.appliedDate | date : 'shortDate' }}</span>
            <ng-container *ngrxLet="applicationViewed$ as applicationViewed">
              <!-- <e11-tooltip [tooltipText]="'You have seen this application' | translate" [position]="'bottom'">
              </e11-tooltip> -->
              <span
                *ngIf="applicationViewed"
                class="material-icons md-14 e11-text-skin-primary-accent"
                [attr.title]="'You have seen this application' | translate"
              >
                visibility
              </span>
            </ng-container>
          </div>
        </div>
        <div class="e11-flex e11-items-start e11-justify-end">
          <e11-overflow
            [options]="overflowOptions"
            [closeOnMouseOut]="true"
            [menuPosition]="'bottom left'"
            [menuMaxWidth]="160"
            (clickOptionEvent)="overflowChoice($any($event))"
          ></e11-overflow>
        </div>
      </div>
    </div>
  </div>
</e11-card>
