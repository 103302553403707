// Framework
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { IRequestedUpdate, RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { RequestedFieldChanged, DegreeLevel } from '../../model/enum'
import { IEducation } from '../../model/interfaces'

// Platform specific

@Component({
  selector: 'candidate-education',
  templateUrl: './candidate-education.component.html',
  styleUrls: ['./candidate-education.component.scss'],
})
export class CandidateEducationComponent implements OnInit {
  @Input() education: IEducation[] = []
  @Input() requests: IRequestedUpdate[] = []
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode
  openRequest?: RequestedUpdateFields
  degreeLevel = DegreeLevel

  constructor() {}
  ngOnInit(): void {
    this.processRequests()
  }

  processRequests() {
    this.openRequest = this.requests.find(
      item => item.requestedFieldChanged === RequestedFieldChanged.Education && !item.fulfilled
    )?.requestedFieldChanged
    return this.openRequest
  }

  requestModal() {
    this.requestFromComponent.emit('Education')
  }

  stateToStatus = new Map<DegreeLevel, string>()
    .set(this.degreeLevel.NotSpecified, myDegreeLevel('Not Specified'))
    .set(this.degreeLevel.HighSchool, myDegreeLevel('High School'))
    .set(this.degreeLevel.VocationalHsDiploma, myDegreeLevel('Vocational High School Diploma'))
    .set(this.degreeLevel.VocationalDegree, myDegreeLevel('Vocational Degree'))
    .set(this.degreeLevel.Associates, myDegreeLevel("Associate's Degree"))
    .set(this.degreeLevel.Bachelors, myDegreeLevel("Bachelor's Degree"))
    .set(this.degreeLevel.Masters, myDegreeLevel("Master's Degree"))
    .set(this.degreeLevel.PhD, myDegreeLevel('Doctorate/PhD'))
}

const myDegreeLevel = (str: string) => `${str}`
