<div class="first-impression">
  <e11-empty-state
    *ngIf="!firstImpression && userMode === userModeEnum.Candidate"
    [icon]="'videocam'"
    [iconSize]="'md-36'"
    title="{{ 'No First Impression Video' | translate }}"
    subtitle="{{ 'You have not uploaded a first impression yet.' | translate }}"
    [routerLink]="['/home/videos']"
  >
  </e11-empty-state>

  <e11-empty-state
    *ngIf="!firstImpression && userMode !== userModeEnum.Candidate"
    [icon]="'videocam'"
    [iconSize]="'md-36'"
    title="{{ 'No First Impression Video' | translate }}"
    subtitle="{{ 'No First Impression video uploaded' | translate }}"
    [clickable]="false"
  >
  </e11-empty-state>

  <div class="spacer-1rem"></div>
  <div class="e11-w-full e11-text-center e11-my-4" *ngIf="displayRequestUpdate && !firstImpression">
    <e11-button
      *ngIf="displayRequestUpdate"
      [disabled]="readonly"
      color="primary-accent"
      [style]="'pill outlined'"
      value="{{ 'Request First Impression' | translate }}"
      (click)="openRequestUpdate.emit(true)"
    ></e11-button>
  </div>
</div>
<div *ngIf="firstImpression">
  <div
    class="image-card"
    (click)="onClickFirstImpression()"
    [ngStyle]="{ background: 'center / cover no-repeat url(' + firstImpression.thumb?.signedURL + ')' }"
  >
    <div class="flex flex-row flex-justify-content-space option">
      <div></div>
      <div class="content-length">{{ firstImpression.videoLength || '0:60' }}</div>
    </div>
    <div class="flex flex-row flex-justify-content-space option" style="height: 50%">
      <i class="fa fa-play control"></i>
    </div>
  </div>
</div>
