// Framework
import { Component, OnInit, Input } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { RequestedFieldChanged } from '../../model/enum'

// Platform specific

@Component({
  selector: 'request-open',
  templateUrl: './request-open.component.html',
  styleUrls: ['./request-open.component.scss'],
})
export class CandidateRequestOpenComponent {
  @Input() request?: RequestedUpdateFields

  requestTypes = RequestedFieldChanged

  constructor() {}
}
