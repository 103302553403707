<div class="view e11--mt-6">
  <header class="e11-container-full e11-mx-auto">
    <app-logo-white [height]="'64px'"></app-logo-white>
  </header>

  <h1 class="e11-text-skin-white e11-text-center e11-text-5xl">{{ 'Hi There!' | translate }}</h1>
  <h2 class="e11-font-skin-avenir e11-text-skin-white e11-text-center e11-mb-8 e11-px-6">
    {{ 'We’re so excited you’re here to set up your cnect account!' | translate }}
  </h2>

  <div class="e11-w-full e11-px-6 e11-flex md:e11-hidden e11-text-center">
    <h4 class="e11-font-skin-avenir e11-text-skin-white e11-mb-4">
      {{ 'For a limited time, you will get free access to the full range of cnect’s employer tools.' | translate }}
    </h4>
  </div>

  <div class="e11-w-2/3 e11-flex e11-mx-auto">
    <div class="e11-hidden e11-w-1/2 md:e11-flex e11-items-center e11-pr-8">
      <h4 class="e11-font-skin-avenir e11-text-skin-white e11--mt-4">
        {{ 'For a limited time, you will get free access to the full range of cnect’s employer tools.' | translate }}
      </h4>
    </div>
    <div class="md:e11-w-full md:e11-w-1/2">
      <p class="e11-font-skin-avenir e11-text-skin-white e11-flex e11-items-center">
        <span class="material-icons-outlined e11-mr-2"> check_circle </span>
        {{ 'Post unlimited job openings' | translate }}
      </p>
      <p class="e11-font-skin-avenir e11-text-skin-white e11-flex e11-items-center">
        <span class="material-icons-outlined e11-mr-2"> check_circle </span>
        {{ 'Quickly sort top-tier talent using the cnect Job Match' | translate }}
      </p>
      <p class="e11-font-skin-avenir e11-text-skin-white e11-flex e11-items-center">
        <span class="material-icons-outlined e11-mr-2"> check_circle </span>
        {{ 'View candidate First Impression Videos' | translate }}
      </p>
      <p class="e11-font-skin-avenir e11-text-skin-white e11-flex e11-items-center">
        <span class="material-icons-outlined e11-mr-2"> check_circle </span>
        {{ 'Engage in Digital Dialogue with candidates all in one powerful platform.' | translate }}
      </p>
    </div>
  </div>

  <div class="e11-w-1/2 e11-mx-auto e11-text-center">
    <e11-divider [margin]="1" [color]="'e11-border-skin-grey/50'"></e11-divider>
    <div class="e11-w-full">
      <e11-button
        id="submit_formCode"
        value="{{ 'Get Started' | translate }}"
        [color]="'secondary'"
        [type]="'button'"
        [style]="'pill'"
        (click)="registerTrial()"
      >
      </e11-button>
    </div>
    <e11-divider [margin]="1" [color]="'e11-border-skin-grey/50'"></e11-divider>
  </div>

  <div class="e11-w-full e11-flex e11-justify-center e11-text-center">
    <div class="e11-w-3/4 md:e11-w-1/2">
      <p class="e11-font-skin-avenir e11-text-skin-white e11-mb-16">
        {{
          'You will also have access to our promotional tools to post your jobs to Google Jobs, and partner with the cnect team to create promotional content to help get more eyes on and interest in your openings. Once we get better acquainted, we would love your feedback and the pleasure of getting your company set up with one of our 3 plans!'
            | translate
        }}
      </p>
    </div>
  </div>
</div>
