<div class="row flex">
  <div class="col-xs-12">
    <div class="wrapper-panel">
      <ui-panel-classic size="sm" borderRadius="lg">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h3 class="bold color-head">
              {{ 'Employer Sign in' | translate }}
            </h3>
            <div class="spacer-2rem"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <auth-login (onSubmit)="onSubmit($event)" (onError)="onError($event)" (onForgottenPassword)="onForgottenPassword()"></auth-login>

            <div class="spacer-2rem"></div>

            <p class="block text-center">
              {{ 'Not signed up?' | translate }}
              <a class="color-secondary" [routerLink]="['/auth/code']"> {{ 'Create an account' | translate }}</a>
            </p>
          </div>
        </div>
      </ui-panel-classic>
    </div>
  </div>
</div>
