import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, AfterContentInit } from '@angular/core'
import { E11SideOverService } from '@engineering11/ui-lib/e11-side-over'
import { UiScrollLockingService } from '@engineering11/web-utilities'
import { UiTemplate } from '../modal-container/ui-template'

@Component({
  selector: 'form-sideover-container',
  templateUrl: './form-sideover-container.component.html',
  styleUrls: ['./form-sideover-container.component.scss'],
})
export class FormSideoverContainerComponent implements OnInit, AfterContentInit {
  @Input() headerTitle = ''

  @Input() subTitle = ''

  @Input() showHeader: boolean = true
  @Input() showFooter: boolean = true
  @Input() showDeleteButton: boolean = false
  @Input() showSubmitButton: boolean = true
  @Input() showCloseButton: boolean = true

  @Input() loading: boolean = false
  @Input() submitDisabled: boolean = false // Consider reaching into the form in a standardised way

  @Output() removeItem = new EventEmitter()
  @Output() submitItem = new EventEmitter()
  @Output() sideoverClosed = new EventEmitter()

  @ViewChild('sideOverContent') sideOverContent!: TemplateRef<any>

  @ContentChildren(UiTemplate) templates!: QueryList<any>

  uiTemplates: any = {}
  isOpen = false

  constructor(private sideOverService: E11SideOverService, private scrollLockService: UiScrollLockingService) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.templates.forEach(item => {
      this.uiTemplates[item.getType()] = item.template
    })
  }

  open() {
    this.isOpen = true
    this.sideOverService.overlayToggle(true, this.sideOverContent, { size: 'lg' })
    this.scrollLockService.scrollLock(true)
  }

  close() {
    this.sideOverService.overlayToggle(false)
    this.scrollLockService.scrollLock(false)
    // TODO: How to maintain the animation - setTimeout doesn't work because it allows the user to break their state by closing and quickly reopening
    this.isOpen = false // rip contents out of the DOM on close
    this.sideoverClosed.emit()
  }
}
