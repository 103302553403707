<div class="row">
  <div class="col-xs-12">
    <ui-panel>
      <h1>Layout Persistent Sidebar</h1>
      <p>This view has no header and a persistent sidebar.</p>
      <p>Should work well for CNect Employer desktop.</p>
    </ui-panel>
  </div>
</div>

<div class="row">
  <div class="col-md-12 col-lg-6">
    <ui-panel-classic title="Layout Persistent Sidebar" color="primary">
      <p>This view has no header and a persistent sidebar</p>
    </ui-panel-classic>
  </div>
  <div class="col-md-12 col-lg-6">
    <ui-panel-classic title="Layout Persistent Sidebar" color="secondary">
      <p>This view has no header and a persistent sidebar</p>
    </ui-panel-classic>
  </div>
</div>
