import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { INotificationHandler } from '@engineering11/notifications-web/lib/models/notification-handler.model'
import { RequestedUpdateFulfilledNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class RequestedUpdateFulfilledNotificationHandler implements INotificationHandler<RequestedUpdateFulfilledNotification, INotificationView> {
  notificationType = 'requested-update-fulfilled'
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  toViewModel(notification: RequestedUpdateFulfilledNotification): INotificationView {
    return {
      description: `${notification.candidateName} has updated their application for the ${notification.jobPostName} job.`,
      title: 'Candidate Application updated',
      originalNotification: notification,
      viewed: notification.viewed,
      logoUrl: '',
    }
  }

  onClick(notification: RequestedUpdateFulfilledNotification): boolean | Promise<boolean> {
    this.markAsRead(notification)
    return this.router.navigate(['/jobs/candidates/', notification.jobPostId], { queryParams: { candidateId: notification.candidateApplicationId } })
  }

  markAsRead(notification: RequestedUpdateFulfilledNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
