import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { JobApplicationViewsStore } from '@employer/app/modules/jobs/stores/job-application-views.store'
import { CandidateDetailTabs, CandidateNavigationSource, EmployerNavigationService } from '@employer/app/services/navigation.service'
import { APPLICATION_STATE, IAddress, IJobPostApplication } from 'shared-lib'
import { Observable, tap } from 'rxjs'

export enum CandidateActions {
  Preview = 'Preview',
  ViewDetails = 'View details',
  SendMessage = 'Send message',
  RequestInfo = 'Request info',
  Dialogue = 'Dialogue',
}

const actionToTab: { [key in string]: CandidateDetailTabs } = {
  [CandidateActions.ViewDetails]: CandidateDetailTabs.Profile,
  [CandidateActions.RequestInfo]: CandidateDetailTabs.RequestMoreInfo,
  [CandidateActions.SendMessage]: CandidateDetailTabs.DirectMessage,
  [CandidateActions.Dialogue]: CandidateDetailTabs.Dialogues,
}

@Component({
  selector: 'ui-alg-card',
  templateUrl: './ui-alg-card.component.html',
  styleUrls: ['./ui-alg-card.component.scss'],
})
export class UiAlgCardComponent implements OnInit {
  @Input() item!: IJobPostApplication // Item comes from Algolia, so might not PERFECTLY reflect IJobPostApplication
  @Input() coreFeature = false
  @Input() selected: boolean = false
  @Output() candidateSelected = new EventEmitter<string>()

  candidateActions = CandidateActions
  overflowOptions = [
    CandidateActions.Preview,
    CandidateActions.ViewDetails,
    CandidateActions.SendMessage,
    CandidateActions.RequestInfo,
    // CandidateActions.Dialogue,
  ]

  stateToStatus = new Map<APPLICATION_STATE, string>()
    .set(APPLICATION_STATE.COMMUNICATING, applicationStatus('Communicating'))
    .set(APPLICATION_STATE.HIRED, applicationStatus('Hired'))
    .set(APPLICATION_STATE.INTERESTED, applicationStatus('Interested'))
    .set(APPLICATION_STATE.IN_PROGRESS, applicationStatus('Submitted'))
    .set(APPLICATION_STATE.NOT_INTERESTED, applicationStatus('Not Interested'))
    .set(APPLICATION_STATE.OFFER_SENT, applicationStatus('Offer Sent'))
    .set(APPLICATION_STATE.WITHDRAWN, applicationStatus('Withdrawn'))

  applicationViewed$?: Observable<boolean>
  lastApplicationViewed$: Observable<string | null> = this.jobApplicationViewsStore.lastApplicationViewed$

  constructor(private navigationService: EmployerNavigationService, private jobApplicationViewsStore: JobApplicationViewsStore) {}
  ngOnInit(): void {
    this.applicationViewed$ = this.jobApplicationViewsStore.applicationViewedForJob$(this.item.jobPostId, this.item.id)
  }

  // ngAfterViewChecked() {}

  fullStars(n: number) {
    return Array(n)
  }

  emptyStars(fullStars: number) {
    const STAR_TOTAL = 5
    return Array(STAR_TOTAL - fullStars)
  }

  formatAddress(address: IAddress) {
    const city = address.city && address.state ? `${address.city}, ` : address.city ?? ''

    return `${city}${address.state ?? ''} ${address.zip ?? ''}`
  }

  candidateSelection() {
    this.jobApplicationViewsStore.onMarkApplicationViewed({ applicationId: this.item.id, jobPostId: this.item.jobPostId })
    this.candidateSelected.emit(this.item.id)
  }

  overflowChoice(event: CandidateActions): CandidateActions {
    switch (event) {
      case CandidateActions.Preview: {
        this.candidateSelected.emit(this.item.id)
        return event // Type checking to ensure switch is exhaustive
      }
      case CandidateActions.ViewDetails:
      case CandidateActions.SendMessage:
      case CandidateActions.RequestInfo:
      case CandidateActions.Dialogue: {
        this.navigationService.setCandidateNavSource(CandidateNavigationSource.List)
        this.navigationService.toCandidateDetailTab(this.item.jobPostId, this.item.id, actionToTab[event])
        return event
      }
    }
  }
}

const applicationStatus = (str: string) => `${str}`
