import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FormCustomValidationService, TokenStorage } from 'shared-lib'
import { SubscriptionManagementService } from '@employer/app/services/subscription.management.service'
import { IEmployerUser } from '@cnect/user-shared'
import { CustomerService, ICustomerDepartment } from '@engineering11/customer-web'
import { TranslateService } from '@ngx-translate/core'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { UiScrollLockingService } from '@engineering11/web-utilities'
import { Validation } from '@engineering11/ui/ui-input-errors'
@Component({
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class CustomerDepartmentDetailComponent implements OnInit, OnDestroy {
  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent
  confirmationMessage = 'Are you absolutely sure you want to delete this department? This cannot be undone.'

  // Forms:
  formDepartment = new UntypedFormGroup({})
  formDepartmentSubmitted = false

  successMessage: string = ''
  departmentId: string = ''
  user?: IEmployerUser
  department?: ICustomerDepartment
  subs: SubscriptionManagementService = new SubscriptionManagementService()

  isExisting: boolean = true

  constructor(
    private formBuilder: UntypedFormBuilder,
    // private notificationsService: UiNotificationsService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private tokenStorage: TokenStorage,
    private router: Router,
    private customValidator: FormCustomValidationService,
    private scrollLockService: UiScrollLockingService
  ) {
    translate.get('Success! You have added a company department.').subscribe((res: string) => {
      this.successMessage = res
    })
  }

  ngOnInit(): void {
    this.createFormDepartment()
    this.activatedRoute.queryParams.subscribe(params => {
      this.departmentId = params['id']
      this.user = this.tokenStorage.getItem('user')
      if (this.departmentId) {
        this.subs.next = this.customerService.departments.get(this.user?.customerKey!, this.departmentId).subscribe(d => {
          this.department = d
          this.formDepartment.patchValue(this.department!)
        })
      } else {
        this.isExisting = false
        this.department = { customerKey: this.user?.customerKey, active: true } as ICustomerDepartment
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  createFormDepartment() {
    this.formDepartment = this.formBuilder.group({
      name: new UntypedFormControl(this.department?.name!, [Validators.required, this.customValidator.whitespaceValidator()]),
      internalCode: new UntypedFormControl(this.department?.internalCode!),
    })
    this.formDepartment.valueChanges.subscribe(changes => {
      if (this.department) {
        this.department.name = changes.name
        this.department.internalCode = changes.internalCode
      }
    })
  }

  setActive(active: boolean) {
    if (this.department) {
      this.department.active = active
    }
  }

  get f() {
    return this.formDepartment.controls as {
      [key: string]: UntypedFormControl
    }
  }

  async onSubmitformDepartment() {
    if (this.formDepartment.valid && this.department) {
      if (this.department.id) {
        await this.customerService.departments.set(this.department)
      } else {
        await this.customerService.departments.add(this.department)
      }
      this.activateNotification('success', this.successMessage)
    } else {
      this.formDepartment.updateValueAndValidity()
      Validation.validateAll(this.formDepartment)
    }
  }

  deleteDepartment() {
    this.confirmation.open()
    this.scrollLockService.scrollLock(true)
  }

  confirmationAnswer(confirmed: boolean) {
    if (confirmed && this.department) {
      this.customerService.departments.delete(this.department)
      this.router.navigate(['../customer/departments'])
    }
    this.scrollLockService.scrollLock(false)
  }

  activateNotification(type: string, message: string): void {
    this.router.navigate(['../customer/departments'])
  }
}
