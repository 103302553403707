import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IHiringTeamMemberNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class HiringTeamMemberAddNotificationHandler implements INotificationHandler<IHiringTeamMemberNotification, INotificationView> {
  notificationType = 'hiring-team-member-added'
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  toViewModel(notification: IHiringTeamMemberNotification): INotificationView {
    return {
      description: `You have been added as a hiring team member on the ${notification.jobTitle} job post`,
      logoUrl: '',
      originalNotification: notification,
      title: 'Added to hiring team',
      viewed: notification.viewed,
    }
  }

  async onClick(notification: IHiringTeamMemberNotification): Promise<boolean> {
    if (!notification.viewed) {
      await this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['/jobs/post', notification.jobPostId])
  }
}
