import { Injectable } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { TokenStorage } from 'shared-lib'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class JobsApiService {
  private restClient: RestApiClient
  constructor(private tokenStorage: TokenStorage) {
    this.restClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  getJobProfileSearchKey(): Observable<string> {
    return this.restClient.get<string>(`search-keys/job-profile`).pipe(map(item => item.data))
  }
  getJobPostSearchKey(): Observable<string> {
    return this.restClient.get<string>(`search-keys/job-post`).pipe(map(item => item.data))
  }
  getJobPostCandidateKey(jobPostId: string): Observable<string> {
    return this.restClient.get<string>(`search-keys/candidate/${jobPostId}`).pipe(map(item => item.data))
  }
  getAllCandidatesSearchKey() {
    const userId = this.tokenStorage.getItem('user')?.id
    return this.restClient.get(`search-keys/candidate/${userId}/all`).pipe(map(item => item.data))
  }
}
