import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { from, of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { CustomerService, ICustomerDepartment, ICustomerLocation } from '@engineering11/customer-web'
import {
  GetCustomerDepartmentSuccess,
  CustomerActionTypes,
  ErrorAction,
  GetCustomerLocationSuccess,
  GetCustomerUsersSuccess,
} from './customer.actions'
import { Store } from '@ngrx/store'
import { getCustomerKey } from 'shared-lib'
import { UserService } from '@engineering11/user-web'
import { IEmployerUser } from '@cnect/user-shared'

@Injectable()
export class CustomerEffects {
  constructor(
    public router: Router,
    private actions$: Actions,
    private customerService: CustomerService,
    private userService: UserService,
    private store: Store
  ) {}

  onGetCustomerDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActionTypes.getDepartment),
      switchMap(action => this.store.select(getCustomerKey)),
      filter(customerKey => !!customerKey),
      switchMap(customerKey => from(this.customerService.departments.getAllActive(customerKey as string))),
      map((response: ICustomerDepartment[]) => new GetCustomerDepartmentSuccess(response)),
      catchError(error => of(new ErrorAction(error)))
    )
  )

  onGetCustomerLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActionTypes.getLocation),
      switchMap(action => this.store.select(getCustomerKey)),
      filter(customerKey => !!customerKey),
      switchMap(customerKey => from(this.customerService.locations.getAllActive(customerKey as string))),
      map((response: ICustomerLocation[]) => new GetCustomerLocationSuccess(response)),
      catchError(error => of(new ErrorAction(error)))
    )
  )

  onGetActiveCustomerUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActionTypes.getActiveCustomerUsers),
      switchMap(action => this.store.select(getCustomerKey)),
      filter(customerKey => !!customerKey),
      switchMap(customerKey => this.userService.getAllActiveByCustomer(customerKey as string)),
      map((response: IEmployerUser[]) => new GetCustomerUsersSuccess(response)),
      catchError(error => of(new ErrorAction(error)))
    )
  )
}
