// Framework
import { Injectable } from '@angular/core'

// Other - third party
import { BehaviorSubject, Subject } from 'rxjs'
import { scan } from 'rxjs/operators'

export enum APP_NAVIGATION_LEFT_GROUPS {
  Jobs = 'jobs',
  Messages = 'messages',
  JobChannels = 'job_channels',
  CompanyAccount = 'company_account',
}

export type AppNavGroup = APP_NAVIGATION_LEFT_GROUPS | null

@Injectable({
  providedIn: 'root',
})
export class NavLeftService {
  activeNavGroupSet = new Set<APP_NAVIGATION_LEFT_GROUPS>()

  private activeNavGroupSource = new BehaviorSubject<AppNavGroup>(null)
  activeNavGroupObservable$

  mobileSideDrawerToggle$ = new Subject<boolean>()

  mobileSideDrawerToggledState$ = this.mobileSideDrawerToggle$.pipe(scan((acc, _) => !acc, false))

  constructor() {
    this.activeNavGroupObservable$ = this.activeNavGroupSource.asObservable()
  }

  isCurrentlyActiveGroup(group: APP_NAVIGATION_LEFT_GROUPS) {
    return this.activeNavGroupSet.has(group)
  }

  navToggleGroup(group: APP_NAVIGATION_LEFT_GROUPS, shouldToggleActive: boolean = false) {
    if (this.activeNavGroupSet.has(group)) {
      return this.activeNavGroupSet.delete(group)
    }

    if (shouldToggleActive) {
      this.activeNavGroupSet.clear()

      return this.activeNavGroupSet.add(group)
    }

    return this.activeNavGroupSet.add(group)
  }
}
