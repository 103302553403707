import { CommonModule } from '@angular/common'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms'
import { NgAisModule } from 'angular-instantsearch'
import { AppFooterComponent } from '../../components/footer/footer.component'
import { AppHeaderComponent } from '../../components/header/header.component'
import { AppNavLeftComponent } from '../../components/nav-left/nav-left.component'
import { AppNavContainerComponent } from '@employer/app/components/nav-container/nav-container.component'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { TranslateModule } from '@ngx-translate/core'
import { AppHeaderSecondaryComponent } from '../../components/header-secondary/header-secondary.component'
import { AppHeaderLandingComponent } from '../../components/header-landing/header-landing.component'
import { AppHeaderLoginComponent } from '../../components/header-login/header.component'
import { AppLogoComponent } from '../../components/logo/logo.component'
import { LoaderViewComponent } from '../../components/loader-view/loader-view.component'
import { AppHeaderRegisterComponent } from '../../components/header-register/header-register.component'
import { ModalPrivacyPolicyComponent } from '../../components/modals/privacy-policy/privacy-policy.component'
import { ModalTermsOfUseComponent } from '../../components/modals/terms-of-use/terms-of-use.component'
import { UiRefinementListComponent } from '../../components/algolia/ui-refinement-list/ui-refinement-list.component'
import { UiRangeSliderListComponent } from '../../components/algolia/ui-range-slider/ui-range-slider.component'
import { UiAlgCardComponent } from '../../components/algolia/ui-alg-card/ui-alg-card.component'
import { UiAlgLineItemComponent } from '../../components/algolia/ui-alg-line-item/ui-alg-line-item.component'
import {
  ApplicationCommentNotificationHandler,
  ApplicationReviewCreatedNotificationHandler,
  CandidateAppliedNotificationHandler,
  ConfigFromApp,
  ConfigurationStore,
  EmployerJobPostClosedNotificationHandler,
  EmployerJobPostReactivatedNotificationHandler,
  HiringTeamMemberAddNotificationHandler,
  initAppWithNotification,
  RequestedUpdateFulfilledNotificationHandler,
  SharedLibModule,
  SocialDmConfigProvider,
  VirtualDialogueSubmittedEmployerHandler,
} from 'shared-lib'
import { environment } from '@employer/environments/environment'
import { ResponsiveWarningComponent } from '@candidate/app/modules/_shared/components/responsive-warning/responsive-warning.component'
import { ERROR_HANDLING } from '@employer/app/app.constants'
import { MessagingConfigurationProvider, MessagingModule } from '@engineering11/messaging-web'
import { ConversationCustomDataProvider, MessagingConfigFromApp } from '@employer/app/config/messaging.config'
import { NgxSummernoteModule } from 'ngx-summernote'
import {
  NotificationConfigurationProvider,
  NotificationHandlerModule,
  UiNotificationComponent,
  UiNotificationLongComponent,
} from '@engineering11/notifications-web'
// import { VirtualDialogueSubmittedEmployerHandler } from '@employer/app/services/notifications/virtual-dialogue-submitted-employer.handler'
// import { RequestedUpdateFulfilledNotificationHandler } from '@employer/app/services/notifications/requested-update-fulfilled-notification-handler'
// import { HiringTeamMemberAddNotificationHandler } from '@employer/app/services/notifications/hiring-team-member-add-notification.handler'
// import { EmployerJobPostReactivatedNotificationHandler } from '@employer/app/services/notifications/employer-job-post-reactivated-notification.handler'
// import { EmployerJobPostClosedNotificationHandler } from '@employer/app/services/notifications/employer-job-post-closed-notification.handler'
// import { CandidateAppliedNotificationHandler } from '@employer/app/services/notifications/candidate-applied-notification.handler'
// import { ApplicationCommentNotificationHandler } from '@employer/app/services/notifications/application-comment-notificaiton.handler'
// import { ApplicationReviewCreatedNotificationHandler } from '@employer/app/services/notifications/application-review-created-notification.handler'
import { FormSideoverContainerComponent } from '@employer/app/components/form-sideover-container/form-sideover-container.component'
import { FormModalContainerComponent } from '@employer/app/components/modal-container/form-modal-container.component'
import { UiTemplate } from '@employer/app/components/modal-container/ui-template'
import { Store } from '@ngrx/store'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { ReactiveComponentModule } from '@ngrx/component'
import { MobileSideDrawerMenuComponent } from '@employer/app/components/mobile-side-drawer-menu/mobile-side-drawer-menu.component'
import { E11AvatarModule } from '@engineering11/ui-lib/e11-avatar'
import { MessagingUserMentionedEmployerHandler } from '@employer/app/services/notifications/messaging-user-mentioned-employer.handler'
import { EmployerApplicationConversationDMProvider } from '@employer/app/config/conversation-config-provider/application-conversation-dm-config.provider'
import { EmployerApplicationConversationProvider } from '@employer/app/config/conversation-config-provider/application-conversation-config.provider'
import { IdenticonPipe } from '@candidate/app/pipes/identicon.pipe'
import { CheckFieldIsUnfulfilledPipe } from '@employer/app/pipes/check-field-is-unfulfilled.pipe'

const declareExportComponents = [
  AppFooterComponent,
  AppLogoComponent,
  AppHeaderComponent,
  AppHeaderSecondaryComponent,
  AppHeaderRegisterComponent,
  AppHeaderLandingComponent,
  AppHeaderLoginComponent,
  AppNavLeftComponent,
  MobileSideDrawerMenuComponent,
  AppNavContainerComponent,
  FormSideoverContainerComponent,
  FormModalContainerComponent,
  UiTemplate,
  LoaderViewComponent,
  ModalPrivacyPolicyComponent,
  ModalTermsOfUseComponent,
  ResponsiveWarningComponent,
  UiRefinementListComponent,
  UiRangeSliderListComponent,
  UiAlgCardComponent,
  UiAlgLineItemComponent,

  CheckFieldIsUnfulfilledPipe,
]

@NgModule({
  declarations: [...declareExportComponents],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule, // For header login modal
    UiComponentLibraryModule,
    TranslateModule,
    NgxSummernoteModule,
    NgAisModule,
    SharedLibModule.forRoot(environment, ERROR_HANDLING),
    E11InputErrorsModule.forRoot({
      additional: {
        phoneNumber: 'Please enter a valid phone number',
        invalidDate: 'Please enter a valid date',
        invalidStartDate: 'Must be before end date',
        invalidEndDate: 'Must be after start date',
        requiredAuthCode: 'Please enter your supplied authorization code.',
        invalidAuthCode: "That doesn't appear to be a valid authorization code.",
        DateFuture: 'Cannot be a future date', // TODO consider how to get static type checking on these
        DateEmptyYear: 'Please pick a year', // TODO consider how to get static type checking on these
        passwordMatchError: 'The passwords do not match',
        hasWhitespaceError: 'This cannot start with a space',
      },
    }),
    MessagingModule.forRoot({
      config: {
        provide: MessagingConfigurationProvider,
        useClass: MessagingConfigFromApp,
      },
      conversationCustomDataProvider: ConversationCustomDataProvider,
      conversationConfigProviders: [EmployerApplicationConversationDMProvider, EmployerApplicationConversationProvider, SocialDmConfigProvider],
    }),
    NotificationHandlerModule.forRoot({
      notificationHandlers: [
        VirtualDialogueSubmittedEmployerHandler,
        RequestedUpdateFulfilledNotificationHandler,
        HiringTeamMemberAddNotificationHandler,
        EmployerJobPostReactivatedNotificationHandler,
        EmployerJobPostClosedNotificationHandler,
        CandidateAppliedNotificationHandler,
        ApplicationCommentNotificationHandler,
        ApplicationReviewCreatedNotificationHandler,
        MessagingUserMentionedEmployerHandler,
      ],
      config: {
        provide: NotificationConfigurationProvider,
        useClass: ConfigFromApp,
      },
    }),
    ReactiveComponentModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppWithNotification,
      multi: true,
      deps: [ConfigurationStore, Store], // <-- Add this
    },
  ],
  exports: [
    ...declareExportComponents,
    UiComponentLibraryModule,
    TranslateModule,
    SharedLibModule,
    MessagingModule,
    NgxSummernoteModule,
    UiNotificationComponent,
    UiNotificationLongComponent,
  ],
})
export class SharedModule {}
