// Framework
import { Component, Input } from '@angular/core'

@Component({
  selector: 'ui-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
})
export class UiSkillComponent {
  @Input() skill: any // Intentional use of any, Using for skills and certs and level isn't optional in skill
  constructor() {}
}
