// Framework
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'

// Platform specific
import { ConfigService } from '@employer/app/services/config.service'
import { environment } from '../../../../../environments/environment'
import { UiErrorsService, UiErrorsMessage } from '@engineering11/ui/ui-errors'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'

@Component({
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class LayoutSidebarStartComponent implements OnInit {
  environment = environment
  themeCurrent: ''

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private errorsService: UiErrorsService,
    private backdropService: E11BackdropService
  ) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {}

  throwError() {
    throw new Error('Holy crap!!!!    Shit just went majorly sideways....')
  }

  throwHttpError() {
    this.http.get('urlhere').subscribe()
  }
}
