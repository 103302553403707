import { CollectionRepository } from '@engineering11/web-api-firebase'
import { Injectable } from '@angular/core'
import { IBetaMessage } from './beta-message.model'
import { AngularFirestore } from '@angular/fire/compat/firestore'

@Injectable({ providedIn: 'root' })
export class BetaMessageRepository extends CollectionRepository<IBetaMessage> {
  constructor(afs: AngularFirestore) {
    super(afs)
  }
  public COLLECTION = `beta_message/`
}
