import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SearchKeyService } from '@employer/app/services/search-key.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { TokenStorage, UserActionTypes } from 'shared-lib'
import {
  ErrorAction,
  GetJobPostCandidateSearchKey,
  GetJobPostCandidateSearchKeySuccess,
  GetJobPostSearchKey,
  GetJobPostSearchKeySuccess,
  GetProfileSearchKey,
  GetProfileSearchKeySuccess,
  GetUserSearchKey,
  GetUserSearchKeySuccess,
  OnInit,
  OnInitSuccess,
  Reset,
  SearchKeyActionTypes,
} from './search-key.actions'

const USER_SEARCH_KEY = 'userSearchKey'
const JOB_PROFILE_SEARCH_KEY = 'jobProfileSearchKey'
const JOB_POST_SEARCH_KEY = 'jobPostSearchKey'
const CANDIDATE_SEARCH_KEY_MAP = 'candidateSearchKeyMap_2'
@Injectable()
export class SearchKeyEffects {
  constructor(public router: Router, private actions$: Actions, private searchKeyService: SearchKeyService, private tokenStorage: TokenStorage) {}
  ngrxOnInitEffects(): Action {
    return { type: SearchKeyActionTypes.onInit }
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchKeyActionTypes.onInit),
      mergeMap((action: OnInit) =>
        of(
          new OnInitSuccess({
            userSearchKey: this.tokenStorage.getItem(USER_SEARCH_KEY) || null,
            jobProfileSearchKey: this.tokenStorage.getItem(JOB_PROFILE_SEARCH_KEY) || null,
            jobPostSearchKey: this.tokenStorage.getItem(JOB_POST_SEARCH_KEY) || null,
            candidateSearchKeyMap: this.tokenStorage.getItem(CANDIDATE_SEARCH_KEY_MAP) || null,
          })
        )
      )
    )
  })

  onGetUserSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getUserSearchKey),
      mergeMap((action: GetUserSearchKey) => {
        return from(this.searchKeyService.getCustomerUsersSearchKey(action.payload.key)).pipe(
          map((response: string) => {
            this.tokenStorage.setItem(USER_SEARCH_KEY, JSON.stringify(response))
            return new GetUserSearchKeySuccess(response)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onGetProfileSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getProfileSearchKey),
      mergeMap((action: GetProfileSearchKey) => {
        return from(this.searchKeyService.getJobProfileSearchKey()).pipe(
          map((response: string) => {
            this.tokenStorage.setItem(JOB_PROFILE_SEARCH_KEY, JSON.stringify(response))
            return new GetProfileSearchKeySuccess(response)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onGetPostSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getPostSearchKey),
      mergeMap((action: GetJobPostSearchKey) => {
        return from(this.searchKeyService.getJobPostSearchKey()).pipe(
          map((response: string) => {
            this.tokenStorage.setItem(JOB_POST_SEARCH_KEY, JSON.stringify(response))
            return new GetJobPostSearchKeySuccess(response)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onGetPostCandidateSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getPostCandidateSearchKey),
      mergeMap((action: GetJobPostCandidateSearchKey) => {
        return from(this.searchKeyService.getJobPostCandidateKey(action.payload)).pipe(
          map((response: string) => {
            const candidateSearchKeyData = { jobPostId: action.payload, jobPostCandidateSearchKey: response }
            const candidateSearchKeyMap = this.tokenStorage.getItem(CANDIDATE_SEARCH_KEY_MAP) ?? {}
            candidateSearchKeyMap[action.payload] = candidateSearchKeyData
            this.tokenStorage.setItem(CANDIDATE_SEARCH_KEY_MAP, JSON.stringify(candidateSearchKeyMap))
            return new GetJobPostCandidateSearchKeySuccess(candidateSearchKeyData)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onUserLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.logOutSuccess),
      map(success => new Reset())
    )
  )
}
