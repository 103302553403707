import { Injectable } from '@angular/core'
import { ConversationSideOverService, MessagingOperationService } from '@engineering11/messaging-web'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { UserMentionedNotification } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class MessagingUserMentionedEmployerHandler implements INotificationHandler<UserMentionedNotification, INotificationView> {
  constructor(private userAppNotificationService: UserAppNotificationService, private messagingOperationService: MessagingOperationService) {}
  toViewModel(notification: UserMentionedNotification): INotificationView {
    return {
      description: `${notification.displayName} has mentioned you.`,
      logoUrl: '',
      originalNotification: notification,
      viewed: notification.viewed,
      title: 'Mentioned',
    }
  }
  onClick(notification: UserMentionedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    this.messagingOperationService.navigateToConversationFromId(notification.conversationId)
    return true
  }

  notificationType: string = 'messaging/user-mentioned'
}
