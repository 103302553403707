import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { IReference } from '../../model/interfaces'

@Component({
  selector: 'ui-reference-line-item-card',
  template: `
    <div
      class="e11-flex e11-flex-col e11-relative e11-h-full e11-border e11-border-skin-app-borders e11-rounded-lg e11-p-4 e11-cursor-pointer hover:e11-bg-gray-50 hover:e11-shadow-md"
    >
      <span
        *ngIf="removable"
        class="e11-line-item-delete e11-flex e11-items-center e11-justify-center e11-bg-white e11-rounded-full e11-border e11-border-skin-app-borders"
      >
        <span class="material-icons md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary" (click)="deleteItem.emit(reference)">
          close
        </span>
      </span>
      <span class="e11-icon-with-attitude" (click)="viewDetail.emit(reference)">
        <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary"> edit </span>
      </span>
      <span (click)="viewDetail.emit(reference)">
        <h4 class="e11-line-item-title e11-break-words e11-line-clamp-2">{{ reference.firstName }} {{ reference.lastName }}</h4>
        <div class="e11-break-words e11-line-clamp-2">{{ reference.referenceType }}</div>
        <div class="e11-break-words e11-line-clamp-2">{{ reference.companyName }}</div>
        <div class="e11-break-words e11-line-clamp-2">{{ reference.referenceRelationship }}</div>
      </span>
    </div>
  `,
  styleUrls: ['./ui-reference-line-item-card.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiReferenceLineItemCardComponent {
  @Input()
  reference!: IReference

  @Input()
  removable: boolean = false

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IReference>()
}
