import { hookToGlobalState, WebpackTranslateLoader } from 'shared-lib'
// Framework
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'

// Platform specific
import { UiNotificationsService, UiNotificationMessage } from '@engineering11/ui/ui-notifications'
import { UiToastService, UiToastMessage } from '@engineering11/ui/ui-toast'
import { ConfigService } from './services/config.service'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { UiScrollLockingService } from '@engineering11/web-utilities'

// Third party
import { TranslateService } from '@ngx-translate/core'
import { ConversationMessageStore, ConversationStore } from '@engineering11/messaging-web'
import { NotificationStore } from '@engineering11/notifications-web'
import { Store } from '@ngrx/store'
import { environment } from '@employer/environments/environment'
import { isElectron } from 'recordrtc'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  preserveWhitespaces: true,
})
export class AppComponent implements OnInit {
  theme: string = 'default'
  features: any[] = []
  settings = false

  _environment = environment
  constructor(
    private notificationsService: UiNotificationsService,
    private toastService: UiToastService,
    private titleService: Title,
    private translateService: TranslateService,
    private configService: ConfigService,
    private backdropService: E11BackdropService,
    private conversationStore: ConversationStore,
    private messageStore: ConversationMessageStore,
    private notificationStore: NotificationStore,
    private store: Store,
    private scrollLockService: UiScrollLockingService
  ) {
    translateService.setDefaultLang('en')
    translateService.currentLoader = new WebpackTranslateLoader()
    this.theme = configService.config.theme
    this.features = configService.config.featureFlags
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle)
  }

  ngOnInit(): void {
    hookToGlobalState(this.conversationStore.getState, 'messaging-conversation', this.store)
    hookToGlobalState(this.messageStore.getState, 'messaging-message', this.store)

    hookToGlobalState(this.notificationStore.getState, 'notification', this.store)

    this.setTitle('cnect - Employer')
    // Note: use firebase feature flags
    this.settings = this.isFeatureFlagEnabled('settings')

    if (isElectron()) {
      console.log(isElectron(), 'isElectron')
      this.cleanupZendDeskDOM()
    }
  }

  cleanupZendDeskDOM() {
    const zendDeskElement = document.getElementById('launcher')
    if (zendDeskElement && zendDeskElement.parentElement) {
      zendDeskElement.parentElement.removeChild(zendDeskElement)
    }
  }
  isFeatureFlagEnabled(flag: any) {
    return this.features && this.features[flag]
  }

  close(value: boolean) {
    this.backdropService.backdropDisplay(false, '')
  }

  sideOverClose() {
    this.scrollLockService.scrollLock(false)
  }
}
