export interface IApplicationConversationMetadata {
  jobPostName: string
  companyName: string
  jobPostId: string
  jobPostApplicationId: string
}

export type IApplicationConversationCreateMetadata = Pick<IApplicationConversationMetadata, 'jobPostId' | 'jobPostApplicationId'>

export const APPLICATION_DM_CONVERSATION_TYPE = 'application-conversation-dm'
