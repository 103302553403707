import { Injectable } from '@angular/core'
import { IConversationTypeConfig, BaseConversationConfigProvider } from '@engineering11/messaging-web'
import { ConversationTypes } from '../../constants'

@Injectable({ providedIn: 'root' })
export class ApplicationConversationConfigProvider extends BaseConversationConfigProvider {
  conversationType = ConversationTypes.JobApplication

  getConfig(): IConversationTypeConfig {
    return {
      canEditOwnMessages: false,
      canDeleteOwnMessages: false,
      canReactOnMessages: true,
      canDeleteConversation: false,
    }
  }
}
