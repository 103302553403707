<div class="e11-mb-5 e11-hidden lg:e11-block app-nav-left e11-w-full">
  <div class="e11-bg-skin-app-bg-dark e11-text-white/70 e11-overflow-hidden e11-rounded nav-prototype" #navContainer style="top: {{ navTop + 'px' }}">
    <div
      class="middle e11-relative e11-overscroll-contain e11-overflow-y-scroll e11-scrollbar-thumb-blue e11-scrollbar-thumb-rounded e11-scrollbar-track-blue-lighter e11-scrollbar-w-2 e11-scrolling-touch"
    >
      <div class="bg e11-absolute"></div>

      <div class="e11-border-t e11-border-t-skin-grey/30">
        <ng-container
          [ngTemplateOutlet]="directRouteNavItemTemplate"
          [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.Jobs) }"
        ></ng-container>
      </div>

      <ng-container *ngIf="currentUser$ | async as currentUser">
        <ng-container *ngIf="!currentUser.roles.includes(EMPLOYER_USER_ROLES.CONTRIBUTOR)">
          <div class="e11-border-t e11-border-t-skin-grey/30">
            <ng-container
              [ngTemplateOutlet]="directRouteNavItemTemplate"
              [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.JobTemplates) }"
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>

      <div class="e11-border-t e11-border-t-skin-grey/30">
        <ng-container
          [ngTemplateOutlet]="directRouteNavItemTemplate"
          [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.Applications) }"
        ></ng-container>
      </div>

      <ul>
        <li
          class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-border-b e11-border-t e11-border-t-skin-grey/30 hover:e11-bg-skin-primary e11-cursor-pointer"
          (click)="toggleNavGroup(navGroups.Messages)"
          [ngClass]="{
            'e11-bg-skin-primary/70 e11-border-b-transparent': isActiveNavGroup(navGroups.Messages),
            'e11-border-b-skin-grey/30': !isActiveNavGroup(navGroups.Messages)
          }"
        >
          <span class="e11-font-medium e11-text-md e11-relative"
            >{{ 'Messages' | translate }}
            <e11-badge
              *ngIf="unreadConversationCount$ | async"
              [value]="(unreadConversationCount$ | async) ?? 0"
              [color]="'error'"
              [size]="'xs'"
              [posAdjustX]="'-4px'"
              [posAdjustY]="'-11px'"
              [limit]="UNREAD_LIMIT"
              [containerClassOverrides]="''"
            ></e11-badge>
          </span>
          <span
            class="material-icons-outlined e11-text-skin-extra-light nav-icon"
            [ngClass]="{ 'nav-icon-active': isActiveNavGroup(navGroups.Messages) }"
          >
            expand_more
          </span>
        </li>

        <div
          class="nav-messages-container e11-px-2"
          [ngClass]="{ 'nav-messages-container-active e11-border-b e11-border-b-skin-grey/30': isActiveNavGroup(navGroups.Messages) }"
        >
          <div class="e11-w-full e11-mt-2 e11-mb-4 e11-rounded-lg e11-bg-skin-primary-accent/20 e11-pt-4">
            <sdk-conversation-nav
              [type]="conversationTypesEnum.JobApplicationDM"
              [emptyStateSubtitle]="'This is where you message candidates'"
              [darkMode]="true"
            ></sdk-conversation-nav>
          </div>
        </div>
      </ul>

      <ng-container *ngIf="currentUser$ | async as currentUser">
        <ng-container *ngIf="currentUser.roles.includes(EMPLOYER_USER_ROLES.ADMINISTRATOR)">
          <ng-container *ngrxLet="canManageCompany$ as canManageCompany">
            <ul>
              <li
                class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-border-b e11-border-t e11-border-t-skin-grey/30 hover:e11-bg-skin-primary e11-cursor-pointer"
                [ngClass]="{
                  'e11-bg-skin-primary/70 e11-border-b-transparent': isActiveNavGroup(navGroups.CompanyAccount),
                  'e11-border-b-skin-grey/30': !isActiveNavGroup(navGroups.CompanyAccount)
                }"
                (click)="toggleNavGroup(navGroups.CompanyAccount)"
              >
                <span class="e11-font-medium e11-text-md e11-relative">{{ navItemNames.CompanyAccount | translate }}</span>
                <span
                  class="material-icons-outlined e11-text-skin-extra-light nav-icon"
                  [ngClass]="{ 'nav-icon-active': isActiveNavGroup(navGroups.CompanyAccount) }"
                >
                  expand_more
                </span>
              </li>
              <div
                class="nav-messages-container e11-px-2"
                [ngClass]="{ 'nav-messages-container-active': isActiveNavGroup(navGroups.CompanyAccount) }"
              >
                <ul class="e11-my-2">
                  <ng-container *ngIf="canManageCompany">
                    <ng-container *ngFor="let child of getNavItem(navItemNames.CompanyAccount)?.children">
                      <li
                        *ngIf="child.label"
                        class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-grey/20 e11-rounded-md hover:e11-text-white e11-rounded-md"
                        [routerLink]="[child.path]"
                        [queryParams]="child.queryParams"
                        [routerLinkActiveOptions]="{ exact: true }"
                        [routerLinkActive]="'e11-bg-skin-grey/10 e11-text-white e11-font-primary-demibold'"
                      >
                        {{ child.label | translate }}
                      </li>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngrxLet="canManageTeam$ as canManageTeam">
                    <ng-container
                      *ngIf="canManageTeam"
                      [ngTemplateOutlet]="directChildRouteNavItemTemplate"
                      [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.ManageUsers) }"
                    ></ng-container>
                  </ng-container>
                </ul>
              </div>
            </ul>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <!-- / middle -->

    <!-- bottom -->
    <div
      class="bottom e11-flex e11-items-center e11-h-12 e11-px-4 e11-bg-skin-app-bg-dark e11-font-bold hover:e11-bg-skin-black e11-cursor-pointer e11-border-t e11-border-t-skin-grey/30"
      [routerLink]="[routes.ACCOUNT]"
    >
      <span class="e11-ml-2 e11-text-sm">
        {{ 'My Account' | translate }}
      </span>
    </div>
  </div>
</div>

<ng-template #directRouteNavItemTemplate let-navItem="navItem">
  <div
    *ngIf="!navItem.navGroup && !navItem.children?.length"
    class="top e11-flex e11-items-center e11-h-12 e11-px-4 e11-font-primary-medium hover:e11-bg-skin-primary e11-cursor-pointer e11-border-b e11-border-b-skin-grey/30"
    [routerLink]="[navItem.path]"
  >
    {{ navItem.name | translate }}
  </div>
</ng-template>

<ng-template #directChildRouteNavItemTemplate let-navItem="navItem">
  <li
    class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-grey/20 e11-rounded-md hover:e11-text-white e11-rounded-md"
    [routerLink]="[navItem.path]"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLinkActive]="'e11-bg-skin-grey/10 e11-text-white e11-font-primary-demibold'"
  >
    {{ navItem.name | translate }}
  </li>
</ng-template>
