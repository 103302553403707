// Framework
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import {
  APPLICATION_STATE,
  degreeLevelMappings,
  experienceLevelMapping,
  employmentTypeMappings,
  applicationStateMappings,
  PayRateTypeEnum,
  SkillLevel,
  EmployeeLocation,
  CandidateReferrals,
} from '../model/enum'
import { BusinessClassification, BusinessSizeRange, IndustrySector, GICSIndustryGroup } from '@engineering11/customer-web'
// Platform specific
import { FormSelectModel } from '../model/form-select.model'
import { IMultiSelectOption } from '@engineering11/ui-lib/e11-multi-select'
import { EMPLOYER_USER_ROLES } from '@cnect/user-shared'

@Injectable({
  providedIn: 'root',
})
export class FormSelectService {
  pleaseSelect = ''
  selectLevel = ''
  pleaseSelectState: string = ' ' // to be translated if needed

  constructor(private translate: TranslateService) {
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelectState = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelect = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.selectLevel = res
    })
  }

  getSelectMonth() {
    return [
      new FormSelectModel('01', 'Jan'),
      new FormSelectModel('02', 'Feb'),
      new FormSelectModel('03', 'Mar'),
      new FormSelectModel('04', 'Apr'),
      new FormSelectModel('05', 'May'),
      new FormSelectModel('06', 'Jun'),
      new FormSelectModel('07', 'Jul'),
      new FormSelectModel('08', 'Aug'),
      new FormSelectModel('09', 'Sep'),
      new FormSelectModel('10', 'Oct'),
      new FormSelectModel('11', 'Nov'),
      new FormSelectModel('12', 'Dec'),
    ]
  }

  getAddressStates() {
    return [
      new FormSelectModel('', this.pleaseSelectState),
      new FormSelectModel('AL', 'AL'),
      new FormSelectModel('AK', 'AK'),
      new FormSelectModel('AZ', 'AZ'),
      new FormSelectModel('AR', 'AR'),
      new FormSelectModel('CA', 'CA'),
      new FormSelectModel('CO', 'CO'),
      new FormSelectModel('CT', 'CT'),
      new FormSelectModel('DE', 'DE'),
      new FormSelectModel('DC', 'DC'),
      new FormSelectModel('FL', 'FL'),
      new FormSelectModel('GA', 'GA'),
      new FormSelectModel('HI', 'HI'),
      new FormSelectModel('ID', 'ID'),
      new FormSelectModel('IL', 'IL'),
      new FormSelectModel('IN', 'IN'),
      new FormSelectModel('IA', 'IA'),
      new FormSelectModel('KS', 'KS'),
      new FormSelectModel('KY', 'KY'),
      new FormSelectModel('LA', 'LA'),
      new FormSelectModel('ME', 'ME'),
      new FormSelectModel('MD', 'MD'),
      new FormSelectModel('MA', 'MA'),
      new FormSelectModel('MI', 'MI'),
      new FormSelectModel('MN', 'MN'),
      new FormSelectModel('MS', 'MS'),
      new FormSelectModel('MO', 'MO'),
      new FormSelectModel('MT', 'MT'),
      new FormSelectModel('NE', 'NE'),
      new FormSelectModel('NV', 'NV'),
      new FormSelectModel('NH', 'NH'),
      new FormSelectModel('NJ', 'NJ'),
      new FormSelectModel('NM', 'NM'),
      new FormSelectModel('NY', 'NY'),
      new FormSelectModel('NC', 'NC'),
      new FormSelectModel('ND', 'ND'),
      new FormSelectModel('OH', 'OH'),
      new FormSelectModel('OK', 'OK'),
      new FormSelectModel('OR', 'OR'),
      new FormSelectModel('PA', 'PA'),
      new FormSelectModel('RI', 'RI'),
      new FormSelectModel('SC', 'SC'),
      new FormSelectModel('SD', 'SD'),
      new FormSelectModel('TN', 'TN'),
      new FormSelectModel('TX', 'TX'),
      new FormSelectModel('UT', 'UT'),
      new FormSelectModel('VT', 'VT'),
      new FormSelectModel('VA', 'VA'),
      new FormSelectModel('WA', 'WA'),
      new FormSelectModel('WV', 'WV'),
      new FormSelectModel('WI', 'WI'),
      new FormSelectModel('WY', 'WY'),
    ]
  }

  getMarketSectors() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(enumToFormSelectModelArray(IndustrySector))
  }

  getIndustryGroups() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(GICSIndustryGroup.map(value => new FormSelectModel(value, value)))
  }

  getBusinessSizesList() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(BusinessSizeRange.map(value => new FormSelectModel(value, value)))
  }
  getBusinessTypes() {
    return [new FormSelectModel('', this.pleaseSelectState), new FormSelectModel('Public', 'Public'), new FormSelectModel('Private', 'Private')]
  }

  getEmploymentTypes() {
    const models: FormSelectModel[] = []
    employmentTypeMappings.forEach((value, key) => {
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getEmployeeLocationList() {
    return [
      new FormSelectModel(EmployeeLocation.Remote, 'Remote'),
      new FormSelectModel(EmployeeLocation.InPerson, 'In-Person'),
      new FormSelectModel(EmployeeLocation.Both, 'Both'),
    ]
  }

  getExperienceLevelList() {
    const models: FormSelectModel[] = []
    experienceLevelMapping.forEach((value, key) => {
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getEducationList() {
    const models: FormSelectModel[] = []
    degreeLevelMappings.forEach((value, key) => {
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getSkillLevelList() {
    return [
      new FormSelectModel(SkillLevel.Beginner, 'Beginner'),
      new FormSelectModel(SkillLevel.Intermediate, 'Intermediate'),
      new FormSelectModel(SkillLevel.Expert, 'Expert'),
    ]
  }

  getPayrateList() {
    return [
      new FormSelectModel('', this.pleaseSelect),
      new FormSelectModel(PayRateTypeEnum.Hourly, 'Hourly'),
      new FormSelectModel(PayRateTypeEnum.Salary, 'Salary'),
    ]
  }

  getTimeList() {
    return [
      new FormSelectModel('12:00 AM', '12:00 AM'),
      new FormSelectModel('1:00 AM', '1:00 AM'),
      new FormSelectModel('2:00 AM', '2:00 AM'),
      new FormSelectModel('3:00 AM', '3:00 AM'),
      new FormSelectModel('4:00 AM', '4:00 AM'),
      new FormSelectModel('5:00 AM', '5:00 AM'),
      new FormSelectModel('6:00 AM', '6:00 AM'),
      new FormSelectModel('7:00 AM', '7:00 AM'),
      new FormSelectModel('8:00 AM', '8:00 AM'),
      new FormSelectModel('9:00 AM', '9:00 AM'),
      new FormSelectModel('10:00 AM', '10:00 AM'),
      new FormSelectModel('11:00 AM', '11:00 AM'),
      new FormSelectModel('12:00 PM', '12:00 PM'),
      new FormSelectModel('1:00 PM', '1:00 PM'),
      new FormSelectModel('2:00 PM', '2:00 PM'),
      new FormSelectModel('3:00 PM', '3:00 PM'),
      new FormSelectModel('4:00 PM', '4:00 PM'),
      new FormSelectModel('5:00 PM', '5:00 PM'),
      new FormSelectModel('6:00 PM', '6:00 PM'),
      new FormSelectModel('7:00 PM', '7:00 PM'),
      new FormSelectModel('8:00 PM', '8:00 PM'),
      new FormSelectModel('9:00 PM', '9:00 PM'),
      new FormSelectModel('10:00 PM', '10:00 PM'),
      new FormSelectModel('11:00 PM', '11:00 PM'),
      new FormSelectModel('12:00 AM', '12:00 AM'),
    ]
  }

  getCandidateSwimLaneList() {
    const models: FormSelectModel[] = []
    applicationStateMappings.forEach((value, key) => {
      if (key === APPLICATION_STATE.IN_PROGRESS) {
        return
      }
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getCandidateReferralList() {
    return [
      new FormSelectModel(CandidateReferrals.Cnect, 'cnect'),
      new FormSelectModel(CandidateReferrals.Google, 'Google'),
      new FormSelectModel(CandidateReferrals.Referral, 'Referral'),
      new FormSelectModel(CandidateReferrals.SocialMedia, 'Social Media'),
      new FormSelectModel(CandidateReferrals.Other, 'Other'),
    ]
  }

  static getAllRolesMultiList(): IMultiSelectOption[] {
    return [
      {
        label: EMPLOYER_USER_ROLES.ADMINISTRATOR,
        value: EMPLOYER_USER_ROLES.ADMINISTRATOR,
        description: 'The Administrator role has access to all features including user management and billing.',
      },
      {
        label: EMPLOYER_USER_ROLES.CONTRIBUTOR,
        value: EMPLOYER_USER_ROLES.CONTRIBUTOR,
        description: 'The contributor role has access to jobs where they are on the hiring team.',
      },
      {
        label: EMPLOYER_USER_ROLES.INTERNAL_RECRUITER,
        value: EMPLOYER_USER_ROLES.INTERNAL_RECRUITER,
        description: 'The internal recruiter role has access to all features related to jobs and candidates.',
      },
    ]
  }
}

function enumToFormSelectModelArray(enumerator: any) {
  return Object.keys(enumerator).map(key => new FormSelectModel(key, enumerator[key]))
}
