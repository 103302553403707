<div class="e11-w-full e11-flex">
  <div class="e11-grow">
    <h2 class="e11-text-skin-primary-accent">{{ 'Location Detail' | translate }}</h2>
    <p class="e11-flex e11-items-center e11-mb-0 e11-text-skin-primary-accent e11-cursor-pointer" [routerLink]="['/customer/locations']">
      <span class="material-icons-outlined md-18 e11-mr-1"> west </span>{{ 'Back to location list' | translate }}
    </p>
  </div>
  <div class="e11-grow e11-flex e11-items-center e11-justify-end"></div>
</div>

<!-- Margin @0 to get more control over area -->
<e11-divider [margin]="1" [width]="100"></e11-divider>

<div>
  <!-- Main -->
  <div class="content-main">
    <div class="row">
      <div class="col-xl-9 col-lg-12 nopadding">
        <e11-panel [hasBorder]="true" [hasShadow]="false">
          <form class="" [formGroup]="formLocation" (ngSubmit)="onSubmitformLocation()" novalidate>
            <div class="e11-w-full">
              <e11-input
                [type]="'text'"
                name="name"
                id="locationName_formLocation"
                label="{{ 'Location' | translate }}"
                [borderGlow]="'primary'"
                [parentForm]="formLocation"
                formControlName="name"
                [autofocus]="true"
                autocomplete="off"
                [required]="true"
                tabindex="0"
              >
                <e11-input-errors [parentForm]="formLocation" [formControl]="f.name" label="{{ 'Name' | translate }}"></e11-input-errors>
              </e11-input>
            </div>

            <div class="e11-w-full e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4">
              <div class="e11-mb-7">
                <e11-input
                  [type]="'text'"
                  name="locationNameSecondary"
                  id="locationNameSecondary_formLocation"
                  label="{{ 'Location Name (Secondary)' | translate }}"
                  [parentForm]="formLocation"
                  formControlName="secondaryName"
                  autocomplete="off"
                  tabindex="1"
                >
                </e11-input>
              </div>

              <div class="e11-mb-7">
                <e11-input
                  [type]="'text'"
                  name="locationCode"
                  id="locationCode_formLocation"
                  label="{{ 'Location Code' | translate }}"
                  [parentForm]="formLocation"
                  formControlName="internalCode"
                  autocomplete="off"
                >
                </e11-input>
              </div>
            </div>

            <div class="e11-w-full">
              <e11-input
                [type]="'text'"
                name="address"
                id="address_formLocation"
                label="{{ 'Address' | translate }}"
                [parentForm]="formLocation"
                formControlName="address1"
                autocomplete="off"
                [required]="true"
              >
                <e11-input-errors [parentForm]="formLocation" [formControl]="f.address1" label="{{ 'Address' | translate }}"></e11-input-errors>
              </e11-input>
            </div>

            <div class="e11-w-full e11-mb-7">
              <e11-input
                [type]="'text'"
                name="address2"
                id="address2_formLocation"
                label="{{ 'Address additional...' | translate }}"
                [parentForm]="formLocation"
                formControlName="address2"
                autocomplete="off"
                placeholder="{{ 'Suite, Floor, Bldg, Apt. Attn...' | translate }}"
              >
              </e11-input>
            </div>

            <div class="e11-flex e11-flex-wrap sm:e11-flex-nowrap sm:e11-gap-4">
              <div class="e11-w-full sm:e11-w-5/12 nopadding-left">
                <e11-input
                  [type]="'text'"
                  name="city"
                  id="city_formLocation"
                  label="{{ 'City' | translate }}"
                  [parentForm]="formLocation"
                  formControlName="city"
                  autocomplete="off"
                  [required]="true"
                >
                  <e11-input-errors [parentForm]="formLocation" [formControl]="f.city" label="{{ 'City' | translate }}"></e11-input-errors>
                </e11-input>
              </div>
              <div class="e11-w-full sm:e11-w-5/12 input-group">
                <e11-select
                  name="select"
                  id="id_select"
                  label="{{ 'State' | translate }}"
                  labelPosition="top"
                  [required]="true"
                  [options]="stateList"
                  formControlName="state"
                ></e11-select>
                <e11-input-errors [parentForm]="formLocation" [formControl]="f.state" label="{{ 'State' | translate }}"></e11-input-errors>
              </div>
              <div class="e11-w-full sm:e11-w-2/12">
                <e11-input
                  [type]="'text'"
                  name="zip"
                  id="zip_formLocation"
                  label="{{ 'Zip' | translate }}"
                  [parentForm]="formLocation"
                  formControlName="zip"
                  autocomplete="off"
                  [required]="true"
                >
                  <e11-input-errors [parentForm]="formLocation" [formControl]="f.zip" label="{{ 'Zip' | translate }}"></e11-input-errors>
                </e11-input>
              </div>
              <div class="e11-w-full sm:e11-w-2/12 nopadding-right">
                <e11-input
                  [type]="'text'"
                  name="country"
                  id="country_formLocation"
                  label="{{ 'Country' | translate }}"
                  [parentForm]="formLocation"
                  formControlName="countryCode"
                  autocomplete="off"
                >
                  <e11-input-errors [parentForm]="formLocation" [formControl]="f.countryCode" label="{{ 'Country' | translate }}"></e11-input-errors>
                </e11-input>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 text-center">
                <e11-button
                  id="location_cancel"
                  value="{{ 'Cancel' | translate }}"
                  color="neutral"
                  [type]="'button'"
                  [buttonGroup]="true"
                  [style]="'pill outlined'"
                  [routerLink]="['/customer/locations']"
                >
                </e11-button>
                <e11-button id="submit_location" value="{{ 'Save' | translate }}" color="primary-accent" [style]="'pill outlined'" [type]="'submit'">
                </e11-button>
              </div>
            </div>

            <!-- Find a way to globalize this for forms, padding-bottom, or something safe and consistent -->
            <div class="spacer-2rem"></div>
          </form>
        </e11-panel>
      </div>
      <div class="col-xl-3 col-lg-12">
        <e11-panel [hasBorder]="true" [hasShadow]="false">
          <div class="row">
            <div class="col-xs-6">
              <h3>Status</h3>
            </div>
            <div class="col-xs-6 text-right">
              <ui-switch size="medium" [checked]="location?.active || false" (valueChange)="setActive($event)"> </ui-switch>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <p>
                {{
                  "The status here allows this 'Location' to be available for Job and Job Template Listings. (as well as in other places). If you turn it off it will not show as a selection in screens that let you choose a location."
                    | translate
                }}
              </p>
              <div class="spacer-1rem"></div>
            </div>
          </div>
        </e11-panel>
      </div>
    </div>
  </div>
</div>
