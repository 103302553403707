// Framework
import { Component, OnInit, ViewChild } from '@angular/core'

// Platform specific
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { UiModalComponent } from '@engineering11/ui/ui-modal'
import { takeUntil } from 'rxjs/operators'
import { Timestamp } from '@engineering11/web-api-firebase'
import { getCurrentUser } from '../../store/user/user.selectors'
import { ICnectUser } from '../../model/interfaces'
import { UpdateUserPhoto } from '../../store/user/user.actions'

@Component({
  selector: 'account-manage-profile-photo',
  templateUrl: './manage-profile-photo.component.html',
  styleUrls: ['./manage-profile-photo.component.scss'],
})
export class AccountManageProfilePhotoComponent implements OnInit {
  @ViewChild('modalProfilePic')
  modalProfilePic!: UiModalComponent

  user?: Timestamp<ICnectUser> | null
  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(private store: Store) {}
  ngOnInit(): void {
    this.store
      .select(getCurrentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => (this.user = user))
  }

  saveNewPhoto(newPhotoUrl: string) {
    this.store.dispatch(new UpdateUserPhoto({ id: this.user!.id, photoURL: newPhotoUrl }))
    this.modalProfilePic.close()
  }

  removeProfilePhoto() {
    this.store.dispatch(new UpdateUserPhoto({ id: this.user!.id, photoURL: null }))
    this.modalProfilePic.close()
  }
}
