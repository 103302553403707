<div class="row">
  <div class="col-xs-12 nopadding">
    <ui-panel-classic title="{{ 'About' | translate }}" color="primary">
      <div class="row">
        <div class="col-xs-10">
          <div class="spacer-1rem"></div>
          <div class="job-details e11-mt-4">
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'App Version' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ appVersion }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'User Agent' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.userAgent }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Browser' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.browser }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Browser Version' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.userAgent }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Mobile' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ isMobile }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Device' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.device }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Device Type' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.deviceType }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Orientation' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.orientation }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'OS' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.os }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'OS Version' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">{{ deviceInfo?.os_version }}</div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Auth User' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">
                <a class="e11-cursor-pointer e11-underline e11-text-skin-primary-accent" (click)="toggleAuthUser()">
                  {{ showAuthUser ? 'Close' : 'View' }}
                </a>
                <pre class="e11-overflow-x-auto" *ngIf="showAuthUser">{{ userFromAuth | json }}</pre>
              </div>
            </div>
            <div class="row line-item e11-mb-3">
              <div class="col-xs-12 col-md-5 nopadding-left">{{ 'Storage User' | translate }}:</div>
              <div class="col-xs-12 col-md-7 nopadding-left">
                <a class="e11-cursor-pointer e11-underline e11-text-skin-primary-accent" (click)="toggleStorageUser()">
                  {{ showStorageUser ? 'Close' : 'View' }}
                </a>
                <pre class="e11-overflow-x-auto" *ngIf="showStorageUser">{{ userFromStorage | json }}</pre>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-4"></div>
      </div>
    </ui-panel-classic>
  </div>
</div>
