<div class="e11-max-h-screen e11-min-h-screen e11-bg-white e11-flex e11-flex-col">
  <!-- Top -->
  <div class="e11-bg-skin-primary e11-p-4 e11-h-16">
    <div class="e11-flex e11-items-center e11-justify-between">
      <h3 class="e11-text-white e11-mb-0" id="slide-over-title">
        {{ 'First Impression Video' | translate }}
      </h3>
      <div class="e11-ml-3 e11-h-7 e11-flex e11-items-center">
        <button
          (click)="closeFired.emit(true)"
          type="button"
          class="e11-bg-skin-primary e11-rounded-md e11-text-indigo-200 hover:e11-text-white focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-white"
        >
          <span class="e11-sr-only">Close panel</span>
          <svg class="e11-h-6 e11-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <!-- Middle -->
  <div class="side-over-middle middle e11-p-4 overflow-y-auto e11-flex-1">
    <div class="e11-pb-16 e11-space-y-6">
      <div>
        <div class="e11-block e11-w-full e11-aspect-w-10 e11-aspect-h-7 e11-rounded-lg e11-overflow-hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="e11-hidden hover:e11-block e11-absolute e11-z-20 e11-top-[42%] e11-mx-auto e11-h-12 e11-w-12 e11-text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div class="e11-hidden hover:e11-block e11-w-full e11-h-full e11-bg-black e11-bg-opacity-50 e11-absolute e11-z-10"></div>
          <video-player *ngIf="showContent" [playerSize]="playerSizeEnum.Small" [videoPlayerResource]="videoPlayerResource"></video-player>
        </div>
        <div class="e11-mt-4 e11-flex e11-items-start e11-justify-between">
          <div>
            <h3 class="e11-text-lg e11-font-medium e11-text-gray-900">
              <span class="e11-sr-only">{{ 'Details for' | translate }} </span>{{ _video.name }}
            </h3>
          </div>
        </div>
      </div>

      <h5 class="">{{ 'Additional Information' | translate }}</h5>
      <dl class="e11-mt-2 e11-border-t e11-border-b e11-border-gray-200 e11-divide-y e11-divide-gray-200">
        <div class="e11-py-3 e11-flex e11-justify-between e11-text-sm e11-font-medium">
          <dt class="e11-text-gray-500">{{ 'Size' | translate }}</dt>
          <dd class="e11-text-gray-900">{{ videoSize }}</dd>
        </div>
        <div class="e11-py-3 e11-flex e11-justify-between e11-text-sm e11-font-medium">
          <dt class="e11-text-gray-500">{{ 'Title' | translate }}</dt>
          <dd class="e11-text-gray-900">{{ _video.name }}</dd>
        </div>
        <div class="e11-py-3 e11-flex e11-justify-between e11-text-sm e11-font-medium">
          <dt class="e11-text-gray-500">{{ 'Video Length' | translate }}</dt>
          <dd class="e11-text-gray-900">{{ _video.videoLength }}</dd>
        </div>
      </dl>
    </div>
  </div>

  <!-- Bottom -->
  <div class="e11-p-4 e11-h-16 e11-border-t e11-border-skin-app-borders e11-bg-gray-50 e11-text-right">
    <e11-button [color]="'secondary'" (click)="delete()" value="{{ 'Delete' | translate }}"></e11-button>
  </div>
</div>

<!-- Modal -->
<div *ngIf="showDeleteModal" class="e11-absolute">
  <div class="e11-fixed e11-z-50 e11-inset-0 e11-overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="e11-flex e11-items-end e11-justify-center e11-min-h-screen e11-pt-4 e11-px-4 e11-pb-20 e11-text-center sm:e11-block sm:e11-p-0">
      <div class="e11-fixed e11-inset-0 e11-bg-gray-500 e11-bg-opacity-75 e11-transition-opacity" aria-hidden="true"></div>
      <span class="e11-hidden sm:e11-inline-block sm:e11-align-middle sm:e11-h-screen" aria-hidden="true">&#8203;</span>
      <div
        class="e11-relative e11-inline-block e11-align-bottom e11-bg-white e11-rounded-lg e11-px-4 e11-pt-5 e11-pb-4 e11-text-left e11-overflow-hidden e11-shadow-xl e11-transform e11-transition-all sm:e11-my-8 sm:e11-align-middle sm:e11-max-w-lg sm:e11-w-full sm:e11-p-6"
      >
        <div class="e11-hidden sm:e11-block e11-absolute e11-top-0 e11-right-0 e11-pt-4 e11-pr-4">
          <button
            (click)="showDeleteModal = false"
            type="button"
            class="e11-bg-white e11-rounded-md e11-text-gray-400 hover:e11-text-gray-500 focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2 focus:e11-ring-indigo-500"
          >
            <span class="e11-sr-only">Close</span>
            <svg class="e11-h-6 e11-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:e11-flex sm:e11-items-start">
          <div
            class="e11-mx-auto e11-flex-shrink-0 e11-flex e11-items-center e11-justify-center e11-h-12 e11-w-12 e11-rounded-full e11-bg-red-100 sm:e11-mx-0 sm:e11-h-10 sm:e11-w-10"
          >
            <svg
              class="e11-h-6 e11-w-6 e11-text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="e11-mt-3 e11-text-center sm:e11-mt-0 sm:e11-ml-4 sm:e11-text-left">
            <h3 class="e11-text-lg e11-leading-6 e11-font-medium e11-text-gray-900" id="modal-title">{{ 'Delete Video' }}</h3>
            <div class="e11-mt-2">
              <p class="e11-text-sm">{{ 'Are you sure you want to delete this?' | translate }}</p>
              <p class="e11-text-sm">
                {{ 'This video will be permanently removed from our servers.' | translate }} {{ 'This action cannot be undone.' | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="e11-mt-5 sm:e11-mt-4 sm:e11-flex sm:e11-flex-row-reverse">
          <e11-button
            [color]="'secondary'"
            [style]="'pill'"
            (click)="showDeleteModal = false; deleteFired.emit(true)"
            value="{{ 'Delete' | translate }}"
          ></e11-button>
          <e11-button
            [color]="'neutral'"
            [buttonGroup]="true"
            [style]="'pill outlined'"
            (click)="showDeleteModal = false"
            value="{{ 'Cancel' | translate }}"
          ></e11-button>
        </div>
      </div>
    </div>
  </div>
</div>
