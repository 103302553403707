import { Injectable } from '@angular/core'
import { FirebaseFunctionsService } from '@engineering11/web-api-firebase'
import { IBetaService } from './beta.service.interface'

@Injectable({
  providedIn: 'root',
})
export class BetaService implements IBetaService {
  constructor() {
    /**/
  }
  public async validateBetaCode(betaCode: string): Promise<{ res: any; serverError: string }> {
    return this.loginHandler(FirebaseFunctionsService.call('beta-validateCode', { code: betaCode }))
  }

  async loginHandler(promise: Promise<any>): Promise<{ res: any; serverError: string }> {
    let res
    let serverError

    try {
      res = await promise
    } catch (err: any) {
      serverError = err.message
      console.error(err)
    }
    return { res, serverError }
  }
}
