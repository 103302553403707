// Framework
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core'

// Platform specific
import { selectors } from '@employer/app/store/selectors'
import * as fromReduce from '@employer/app/store/reducers'
import { IEmployerUser } from '@cnect/user-shared'
import { BetaMessageService, CnectFeatures, OnLogOut } from 'shared-lib'

// Third party
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { SubscriptionManagementService } from '@employer/app/services/subscription.management.service'
import { environment } from '@employer/environments/environment'
import { NavLeftService } from '@employer/app/services/nav-left.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  currentUser$: Observable<IEmployerUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  message?: string
  showBeta: boolean = false
  subs = new SubscriptionManagementService()
  features?: CnectFeatures

  unreadCount = 0
  isMobileMenuOpened = false

  dropDownOpen = false
  mobileSideDrawerOpened$ = this.navLeftService.mobileSideDrawerToggledState$

  constructor(
    private store: Store<fromReduce.user.State>,
    private betaMessageService: BetaMessageService,
    private elementRef: ElementRef,
    private navLeftService: NavLeftService
  ) {}

  ngOnInit() {
    this.subs.next = this.betaMessageService.get(environment.betaMessageId).subscribe(message => {
      this.message = message?.emailHtml
    })

    this.subs.next = this.store.select(selectors.getFeatures).subscribe(features => (this.features = features))
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  signOut(): void {
    this.store.dispatch(new OnLogOut())
  }

  getInitial(value: string) {
    return value.match(/\b(\w)/g)?.join('') || ''
  }

  toggleMobileSideDrawer() {
    this.navLeftService.mobileSideDrawerToggle$.next(true)
  }

  // Closers - Coffee is for closers only
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any[]) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.dropDownOpen = false
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dropDownOpen = false
    }
  }
}
