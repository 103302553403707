import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IApplicationReviewCreatedNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class ApplicationReviewCreatedNotificationHandler implements INotificationHandler<IApplicationReviewCreatedNotification, INotificationView> {
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}
  notificationType = 'application-review-created'
  toViewModel(notification: IApplicationReviewCreatedNotification): INotificationView {
    return {
      viewed: notification.viewed,
      description: `A new review has been added on a candidate for the ${notification.jobTitle} job.`,
      logoUrl: '',
      originalNotification: notification,
      title: 'New Review',
    }
  }

  onClick(notification: IApplicationReviewCreatedNotification) {
    this.markAsRead(notification)
    return this.router.navigate(['/jobs/candidates/', notification.jobPostId], { queryParams: { candidateId: notification.candidateApplicationId } })
  }

  private markAsRead(notification: IApplicationReviewCreatedNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
