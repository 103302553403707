<div class="container-view" [class.shade]="header === 'code'">
  <div class="loader" [ngClass]="{ inactive: !isLoading }">
    <e11-loader-dots [size]="'lg'" [color]="'e11-bg-skin-primary-accent'" *ngIf="header !== 'code'"></e11-loader-dots>
  </div>

  <app-header-register *ngIf="header === 'register' || header === 'recover'"> </app-header-register>
  <app-header-login *ngIf="header === 'login'"></app-header-login>
  <app-header-landing *ngIf="header !== 'login' && header !== 'code' && header !== 'register' && header !== 'recover'"> </app-header-landing>

  <main class="authentication" [ngClass]="{ registration: header === 'register', code: header === 'code' }">
    <div class="e11-container-full container-content block employer-margin-top">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
