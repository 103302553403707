// Framework
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import { IRequestedUpdate, RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { RequestedFieldChanged } from '../../model/enum'
import { IWorkHistory } from '../../model/interfaces'
import { Subscription } from 'rxjs'
import { ViewportService } from '../../service/viewport.service'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'

// Platform specific

@Component({
  selector: 'candidate-work-history',
  templateUrl: './candidate-work-history.component.html',
  styleUrls: ['./candidate-work-history.component.scss'],
})
export class CandidateWorkHistoryComponent implements OnInit, OnDestroy {
  workHistoryItem: IWorkHistory | undefined
  @Input() workHistory: IWorkHistory[] = []
  @Input() requests: IRequestedUpdate[] = []
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode
  openRequest?: RequestedUpdateFields

  isMobile: boolean = false
  viewportSubscription: Subscription

  constructor(private viewportService: ViewportService, private backdropService: E11BackdropService) {
    this.viewportSubscription = this.viewportService.viewportSizeChanged$.subscribe(data => {
      this.isMobile = data == 'sm' || data == 'xs'
    })
  }

  ngOnInit(): void {
    this.processRequests()
    if (this.workHistory.length > 0) {
      this.workHistoryItem = this.workHistory[0]
    }
  }

  ngOnDestroy() {
    this.viewportSubscription.unsubscribe()
  }

  processRequests() {
    this.openRequest = this.requests.find(
      item => item.requestedFieldChanged === RequestedFieldChanged.WorkHistory && !item.fulfilled
    )?.requestedFieldChanged
    return this.openRequest
  }

  workHistoryViewDetail(item: IWorkHistory) {
    this.workHistoryItem = item
  }

  requestModal() {
    //
    this.requestFromComponent.emit('Work History')
    // this.backdropService.backdropDisplay(true, 'dark')
  }
}
