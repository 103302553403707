<!-- Empty State may be needed -->
<!-- Populated state -->
<div class="e11-w-full" *ngIf="workHistory.length > 0">
  <div *ngFor="let item of workHistory; let i = index">
    <div class="e11-pb-4">
      <div class="e11-w-full e11-flex">
        <div class="e11-w-3/4">
          <h4 class="e11-truncate">{{ item.companyName }}</h4>
          <span
            class="material-icons e11--mt-[2px] e11-cursor-pointer"
            (click)="editLineItem(item)"
            *ngIf="editItem"
            title="{{ 'Edit this entry' | translate }}"
          >
            edit
          </span>
          <span
            class="material-icons e11--mt-[2px] e11-cursor-pointer"
            (click)="deleteLineItem(item)"
            *ngIf="deleteItem"
            title="{{ 'Remove from this application' | translate }}"
          >
            remove_circle_outline
          </span>
        </div>
        <div
          *ngIf="!hideViewOptionButton"
          class="e11-w-1/4 e11-flex e11-items-center e11-justify-end e11-cursor-pointer e11-text-skin-primary-accent hover:e11-text-skin-primary"
          (click)="viewMore(i)"
        >
          <span class="e11-flex e11-items-center" *ngIf="open !== i && !viewAll">
            {{ 'View more' | translate }}
            <span class="material-icons e11--mt-[2px]"> expand_more </span>
          </span>
          <span class="e11-flex e11-items-center" *ngIf="open === i || viewAll">
            {{ 'View less' | translate }}
            <span class="material-icons e11--mt-[2px]"> expand_less </span>
          </span>
        </div>
      </div>
      <div>{{ item.jobTitle }}</div>
      <date-display-start-end [item]="item"></date-display-start-end>
      <div class="" *ngIf="open === i || viewAll">
        <div
          *ngIf="item.jobDescription"
          [ngxSummernoteView]="item?.jobDescription || ''"
          class="e11-mt-2 e11-border-b e11-border-skin-app-borders"
        ></div>
      </div>
    </div>
  </div>
</div>
