<div class="e11-w-full e11-flex">
  <div class="e11-grow">
    <h2 class="e11-text-skin-primary-accent">{{ 'Company Locations' | translate }}</h2>
    <p class="e11-mb-0">{{ 'Manage the locations within your company' | translate }}</p>
  </div>
  <div class="e11-grow e11-flex e11-items-center e11-justify-end">
    <e11-button
      [style]="'pill outlined'"
      color="secondary"
      value="{{ 'Add Location' | translate }}"
      [icon]="'add'"
      [iconPosition]="'left'"
      [iconSize]="'md-18'"
      [routerLink]="['/customer/locations/detail']"
    >
    </e11-button>
  </div>
</div>

<!-- Margin @0 to get more control over area -->
<e11-divider [margin]="1" [width]="100"></e11-divider>

<div>
  <!-- Left -->

  <!-- Main -->
  <div class="content-main">
    <div class="e11-flex">
      <div class="e11-w-full">
        <e11-panel [hasBorder]="true" [hasShadow]="false">
          <div class="e11-flex">
            <div class="e11-w-full">
              <div class="e11-hidden md:e11-flex e11-font-medium e11-border-b e11-border-skin-app-borders">
                <div class="e11-w-1/5 e11-font-primary-demibold e11-mb-2">Code</div>
                <div class="e11-w-5/12 e11-font-primary-demibold e11-mb-2">Name</div>
                <div class="e11-w-2/12 e11-font-primary-demibold e11-mb-2 ellipsis">City</div>
                <div class="e11-w-2/12 e11-font-primary-demibold e11-mb-2 text-center ellipsis">State</div>
                <div class="e11-w-2/12 e11-font-primary-demibold e11-mb-2 text-center ellipsis">Country</div>
                <div class="e11-w-1/12 e11-font-primary-demibold e11-mb-2 text-center ellipsis">Active</div>
                <div class="e11-w-1/12 e11-font-primary-demibold e11-mb-2 text-center">Edit</div>
              </div>

              <div class="loading" *ngIf="loading">
                <div class="e11-w-full e11-flex e11-text-center e11-items-center e11-justify-center">
                  <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary-accent'"></e11-loader-dots>
                </div>
              </div>

              <div *ngIf="(locations$ | async) && (locations$ | async)?.length !== 0; else emptyState">
                <div
                  class="e11-flex e11-rounded-lg e11-mb-4 e11-overflow-hidden e11-border e11-flex-col md:e11-border-0 md:e11-rounded-none md:e11-mb-0 md:e11-flex-row e11-items-center md:e11-h-[4rem] e11-cursor-pointer hover:e11-bg-skin-secondary-100 md:e11-border-b md:e11-border-skin-app-borders"
                  *ngFor="let location of locations$ | async"
                  [routerLink]="['/customer/locations/detail']"
                  [queryParams]="{ id: location.id }"
                >
                  <div class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-1/5 ellipsis">
                    <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Code</span>
                    {{ location.internalCode }}
                  </div>
                  <div class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-5/12 ellipsis">
                    <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Name</span>
                    {{ location.name }}
                  </div>
                  <div class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-2/12 ellipsis">
                    <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">City</span>
                    {{ location.city }}
                  </div>
                  <div
                    class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-2/12 md:e11-text-center ellipsis"
                  >
                    <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">State</span>
                    {{ location.state }}
                  </div>
                  <div
                    class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-2/12 md:e11-text-center ellipsis"
                  >
                    <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Country</span>
                    {{ location.countryCode }}
                  </div>
                  <div
                    class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-1/12 md:e11-text-center ellipsis"
                  >
                    <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Active</span>
                    <span class="material-icons-outlined e11-text-skin-primary" *ngIf="location.active"> thumb_up </span>
                    <span class="material-icons-outlined e11-text-skin-light" *ngIf="!location.active"> thumb_down </span>
                  </div>
                  <div
                    class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-1/12 md:e11-text-center color-secondary"
                  >
                    <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Edit</span>
                    <span class="material-icons-outlined"> edit </span>
                  </div>
                </div>
              </div>

              <ng-template #emptyState>
                <e11-empty-state
                  *ngIf="!loading"
                  [icon]="'location_on'"
                  [iconSize]="'md-36'"
                  [iconType]="''"
                  title="{{ 'No Company Locations' | translate }}"
                  subtitle="{{ 'It appears that we do not have any company locations entered yet.' | translate }}"
                  (click)="addLocations()"
                >
                </e11-empty-state>
              </ng-template>
            </div>
          </div>
        </e11-panel>
      </div>
    </div>
  </div>

  <!-- Right -->
  <!-- <div class="content-right"></div> -->
</div>
