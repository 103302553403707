<h3 class="e11-font-primary-demibold">{{ 'Legal Disclaimer' | translate }}</h3>
<p>{{ 'Create and manage your company’s legal disclaimer settings.' | translate }}</p>

<e11-panel [hasBorder]="true" [hasShadow]="false">
  <div class="e11-w-full">
    <div class="e11-flex e11-items-start e11-mb-4">
      <div class="e11-mr-4">
        <ui-switch [checked]="showDisclaimer" (valueChange)="changeShowDisclaimer($event)"></ui-switch>
      </div>
      <div class="">
        <h4 class="e11-mb-0 nomargin-bottom">{{ 'Enable Legal Disclaimer' | translate }}</h4>
        <span>{{
          'When toggled on, this legal disclaimer text will appear on the job posts seen by candidates both within the platform and on your public careers page.'
            | translate
        }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="formDisclaimer" (ngSubmit)="onSubmitDisclaimer()" novalidate>
      <e11-text-editor name="disclaimer" id="text_legal" formControlName="disclaimer" [value]="employer?.legalDisclaimer || ''" [required]="false">
      </e11-text-editor>
      <div class="spacer-1rem"></div>
      <e11-button
        *ngIf="formDisclaimer"
        [style]="'pill outlined'"
        id="submit_disclaimer"
        value="{{ 'Save' | translate }}"
        color="primary-accent"
        [type]="'submit'"
        [buttonGroup]="false"
        [disabled]="formDisclaimer.invalid || !formDisclaimer.dirty"
      >
      </e11-button>
    </form>
  </div>
</e11-panel>

<span id="about"></span>
