import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { NGXLogger } from 'ngx-logger'
import { Observable } from 'rxjs'
import { Permission } from '../config/permission.config'
import { PermissionService } from '@engineering11/permissions-web'

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanActivate {
  constructor(private permissionService: PermissionService, private logger: NGXLogger) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const permission: Permission = route.data.permission
    this.logger.log({ permission })
    return this.permissionService.userHasPermission(permission)
  }
}
