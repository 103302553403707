import { Pipe, PipeTransform } from '@angular/core'
import { RequestedUpdateFields } from 'shared-lib'

@Pipe({
  name: 'checkFieldIsUnfulfilled',
})
export class CheckFieldIsUnfulfilledPipe implements PipeTransform {
  transform(field: RequestedUpdateFields, unfulfilledFields: RequestedUpdateFields[]) {
    return unfulfilledFields.includes(field)
  }
}
