<div class="header-container e11-bg-skin-primary e11-w-full sm:e11-flex-wrap">
  <header class="e11-container-full e11-bg-skin-primary selection:e11-mx-auto e11-px-4">
    <div class="e11-flex e11-justify-between e11-items-center e11-w-full">
      <div class="e11-hidden lg:e11-flex e11-items-center e11-header-logo">
        <span class="e11-cursor-pointer" [routerLink]="['/jobs']">
          <app-logo-white [height]="'64px'"></app-logo-white>
        </span>
      </div>
      <!-- <div> -->
      <div class="e11-grow e11--mr-2 e11-flex e11-items-center lg:e11-hidden">
        <!-- Mobile menu button -->
        <button
          *ngIf="currentUser$ | async as currentUser"
          (click)="toggleMobileSideDrawer()"
          type="button"
          class="e11-inline-flex e11-items-center e11-justify-center e11-p-2 e11-rounded-md e11-text-gray-100 hover:e11-text-gray-100 focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="e11-sr-only">Open main menu</span>
          <span class="material-icons-outlined" *ngIf="(mobileSideDrawerOpened$ | async) != true">menu</span>
          <span class="material-icons-outlined" *ngIf="mobileSideDrawerOpened$ | async">close</span>
        </button>

        <div class="e11-flex e11-items-center e11-ml-5">
          <e11-avatar
            *ngIf="currentUser$ | async as currentUser"
            [imageUrl]="currentUser.photoURL"
            [size]="'md'"
            [initials]="currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)"
            (click)="dropDownOpen = !dropDownOpen"
          ></e11-avatar>

          <span
            (click)="dropDownOpen = !dropDownOpen"
            *ngIf="currentUser$ | async as currentUser"
            class="e11-flex e11-items-center e11-px-3 e11-py-2 e11-rounded-md e11-text-md e11-text-white/80 e11-cursor-pointer"
          >
            {{ currentUser.firstName }} {{ currentUser.lastName }}
            <span class="material-icons">expand_more</span>
          </span>
        </div>
      </div>

      <div class="e11-grow e11-hidden lg:e11-flex e11-text-white e11-gap-4 e11-pl-6">
        <a class="e11-flex e11-items-center e11-gap-2 e11-text-white/80 hover:e11-text-white" [routerLink]="'/jobs'">
          <span class="material-icons"> description </span> {{ 'Jobs' | translate }}
        </a>
        <a class="e11-flex e11-items-center e11-gap-2 e11-text-white/80 hover:e11-text-white" [routerLink]="'/jobs/applications'">
          <span class="material-icons"> account_circle </span> {{ 'Applications' | translate }}
        </a>
      </div>
      <!-- </div> -->

      <div class="e11-flex e11-items-center e11-relative">
        <ui-notification *ngIf="features?.notifications"></ui-notification>
        <!--        <messages-envelope *ngIf="features?.messaging"></messages-envelope>-->

        <div class="user e11-hidden lg:e11-inline-flex" *ngIf="currentUser$ | async as currentUser">
          <span class="e11-flex e11-items-center" (click)="dropDownOpen = !dropDownOpen">
            <!-- <profile-pic [imageUrl]="currentUser.photoURL" [size]="'sm'"></profile-pic> -->
            <e11-profile-pic
              *ngIf="currentUser$ | async as currentUser"
              [imageUrl]="currentUser.photoURL"
              [size]="'md'"
              [textValue]="getInitial(currentUser.displayName || '')"
            >
            </e11-profile-pic>

            <div class="name e11-ml-2 e11-text-white">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
            <span class="e11-ml-1 e11-mt-1 e11-text-white">
              <span class="material-icons-outlined" *ngIf="!dropDownOpen"> expand_more </span>
              <span class="material-icons-outlined" *ngIf="dropDownOpen"> expand_less </span>
            </span>
          </span>
          <div
            [ngClass]="{
              'e11-transition e11-ease-out e11-duration-200 e11-transform e11-opacity-0 e11-scale-95 e11-invisible': !dropDownOpen,
              'e11-transition e11-ease-in duration-75 e11-e11-transform e11-opacity-100 e11-scale-100': dropDownOpen
            }"
            class="e11-origin-top-right e11-absolute e11-right-0 e11-top-10 e11-mt-2 e11-w-48 e11-rounded-md e11-shadow-lg e11-py-1 e11-bg-white e11-border e11-border-skin-app-borders focus:e11-outline-none focus:e11-ring-opacity-100"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <a
              [routerLink]="['/account']"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
            >
              {{ 'My Account' | translate }}
            </a>

            <a
              href="#"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              (click)="signOut()"
            >
              {{ 'Sign out' | translate }}
            </a>
          </div>
        </div>
        <!--        <div class="e11-ml-2 lg:e11-hidden">-->
        <!--          <button (click)="toggleMenu()">-->
        <!--            <span class="material-icons e11-text-white">menu</span>-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
  </header>
  <!-- Mobile menu, show/hide based on menu state. -->
  <div *ngIf="dropDownOpen" class="lg:e11-hidden e11-mt-[86px] e11-w-full e11-bg-skin-primary">
    <div class="e11-pt-4 e11-pb-3 e11-border-t e11-border-gray-200 e11-bg-skin-primary">
      <div class="e11-flex e11-items-center e11-px-4">
        <div class="e11-flex-shrink-0">
          <e11-profile-pic
            *ngIf="currentUser$ | async as currentUser"
            [imageUrl]="currentUser.photoURL"
            [size]="'md'"
            [textValue]="getInitial(currentUser.displayName || '')"
          >
          </e11-profile-pic>
        </div>
        <div class="e11-ml-2">
          <div class="e11-text-base e11-font-medium e11-text-gray-200" *ngIf="currentUser$ | async as currentUser">
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </div>
        </div>
      </div>

      <div class="e11-mt-3 e11-space-y-1 e11-px-4 e11-text-base e11-font-medium e11-text-white">
        <a
          [routerLink]="['/account']"
          class="e11-items-center e11-h-[40px] e11-rounded-md e11-h-10 e11-transition e11-duration-200 e11-flex e11-px-3"
          routerLinkActive="e11-bg-skin-primary-accent/20"
        >
          {{ 'My Account' | translate }}
        </a>

        <a
          href="#"
          class="e11-items-center e11-h-[40px] e11-rounded-md e11-h-10 e11-transition e11-duration-200 e11-flex e11-px-3"
          routerLinkActive="e11-bg-skin-primary-accent/20"
          (click)="signOut()"
        >
          {{ 'Sign out' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
