// Framework
import { Component } from '@angular/core'

// Platform specific
import { OnLogIn, TokenStorage } from 'shared-lib'
import * as fromReduce from '@employer/app/store/reducers'

// Third party
import jwt_decode from 'jwt-decode'
import { Store } from '@ngrx/store'
import { UiNotificationMessage, UiNotificationsService } from '@engineering11/ui/ui-notifications'
import { IRegistrationResult } from '@engineering11/registration-web'

@Component({
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss'],
})
export class AuthenticationRegisterCompanyComponent {
  loading = false

  constructor(
    private tokenStorage: TokenStorage,
    private store: Store<fromReduce.user.State>,
    private notificationsService: UiNotificationsService
  ) {}

  async onSubmit(result: IRegistrationResult) {
    this.loading = true
    this.tokenStorage.setItem('user', JSON.stringify(result.appUser))
    this.store.dispatch(new OnLogIn({ token: result.authToken, appUserId: result.appUser.id }))
    this.loading = false
  }

  onError(error: any): void {
    const notification = new UiNotificationMessage('An error occurred, unable to process registration', 'error', true, 'Close')
    this.notificationsService.popNotificationMessage(notification)
  }
}
