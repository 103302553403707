import { Injectable } from '@angular/core'
import { IEmployer } from '../model/employer.model'
import { EmployerRepository } from '../repository/employer.repository'

@Injectable({ providedIn: 'root' })
export class EmployerService {
  constructor(private repository: EmployerRepository) {}

  get(customerKey: string) {
    return this.repository.get(customerKey)
  }

  add(model: IEmployer) {
    model.id = model.id
    return this.repository.add(model)
  }

  update(model: IEmployer) {
    return this.repository.update(model)
  }

  set(model: IEmployer) {
    return this.repository.set(model)
  }

  delete(customerKey: string) {
    return this.repository.delete(customerKey)
  }
}
