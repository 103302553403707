<e11-panel [hasBorder]="true" [hasShadow]="false">
  <div class="e11-w-full">
    <e11-loader-dots *ngIf="loading" [size]="'md'" [color]="'e11-bg-skin-primary-accent'"></e11-loader-dots>

    <form class="e11-mb-16" [formGroup]="formAccount" (ngSubmit)="onSubmitFormAccount()" novalidate *ngIf="!loading">
      <e11-input
        [type]="'text'"
        name="name"
        id="firstname_formAccount"
        label="{{ 'Company Name' | translate }}"
        [parentForm]="formAccount"
        formControlName="name"
        autocomplete="off"
        [required]="true"
        [disabled]="formReadOnly"
      >
        <e11-input-errors [parentForm]="formAccount" [formControl]="f.name" label="{{ 'Name' | translate }}"></e11-input-errors>
      </e11-input>

      <e11-input
        [type]="'text'"
        name="address"
        id="address_formAccount"
        label="{{ 'Address' | translate }}"
        [parentForm]="formAccount"
        formControlName="address"
        autocomplete="off"
        [required]="true"
        [disabled]="formReadOnly"
      >
        <e11-input-errors [parentForm]="formAccount" [formControl]="f.address" label="{{ 'Address' | translate }}"></e11-input-errors>
      </e11-input>

      <e11-input
        [type]="'text'"
        name="address2"
        id="address2_formAccount"
        label="{{ 'Address additional' | translate }}"
        [parentForm]="formAccount"
        formControlName="address2"
        autocomplete="off"
        [disabled]="formReadOnly"
        placeholder="{{ 'Suite, Floor, Bldg, Apt. Attn...' | translate }}"
      >
        <e11-input-errors [parentForm]="formAccount" [formControl]="f.address2" label="{{ 'Address additional' | translate }}"></e11-input-errors>
      </e11-input>

      <div class="row">
        <div class="col-md-4 nopadding-left">
          <e11-input
            [type]="'text'"
            name="city"
            id="city_formAccount"
            label="{{ 'City' | translate }}"
            [parentForm]="formAccount"
            formControlName="city"
            autocomplete="off"
            [required]="true"
            [disabled]="formReadOnly"
          >
            <e11-input-errors [parentForm]="formAccount" [formControl]="f.city" label="{{ 'City' | translate }}"></e11-input-errors>
          </e11-input>
        </div>

        <div class="col-md-4 input-group nopadding-middle-md">
          <e11-select
            name="select"
            id="id_select"
            label="{{ 'State' | translate }}"
            labelPosition="top"
            [required]="true"
            [options]="stateList"
            formControlName="state"
            [disabled]="formReadOnly"
          >
          </e11-select>
          <e11-input-errors [parentForm]="formAccount" [formControl]="f.state" label="{{ 'State' | translate }}"></e11-input-errors>
        </div>
        <div class="col-md-4 nopadding-right">
          <e11-input
            [type]="'text'"
            name="zip"
            id="zip_formAccount"
            label="{{ 'Zip' | translate }}"
            [parentForm]="formAccount"
            formControlName="zip"
            autocomplete="off"
            [required]="true"
            [disabled]="formReadOnly"
          >
            <e11-input-errors [parentForm]="formAccount" [formControl]="f.zip" label="{{ 'Zip' | translate }}"></e11-input-errors>
          </e11-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 nopadding-left">
          <e11-select
            name="select"
            id="id_select"
            label="{{ 'Business Size' | translate }}"
            labelPosition="top"
            [options]="businessSizeRangeList"
            formControlName="businessSizeRange"
            [disabled]="formReadOnly"
          >
          </e11-select>
        </div>
        <div class="col-md-4 input-group nopadding-middle-md">
          <e11-select
            name="select"
            id="id_select"
            label="{{ 'Industry' | translate }}"
            labelPosition="top"
            [options]="industryGroupList"
            formControlName="industryGroup"
            [disabled]="formReadOnly"
          >
          </e11-select>
          <e11-input-errors [parentForm]="formAccount" [formControl]="f.industryGroup" label="{{ 'Industry Group' | translate }}"></e11-input-errors>
        </div>
        <div class="col-md-4 input-group nopadding-left">
          <e11-select
            name="select"
            id="id_select"
            label="{{ 'Business Type' | translate }}"
            labelPosition="top"
            [options]="businessTypesList"
            formControlName="businessClassification"
            [disabled]="formReadOnly"
          >
          </e11-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 nopadding-left">
          <e11-input
            [type]="'text'"
            name="website"
            id="id_website"
            label="{{ 'Company Website' | translate }}"
            labelColor="primary"
            [parentForm]="formAccount"
            formControlName="websiteURL"
            autocomplete=" off"
            placeholder="https://"
            [disabled]="formReadOnly"
          >
            <e11-input-errors [parentForm]="formAccount" [formControl]="f.website" label="{{ 'Company website' | translate }}"></e11-input-errors>
          </e11-input>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 text-center">
          <e11-button
            *ngIf="formReadOnly"
            id="button_edit"
            [style]="'pill outlined'"
            value="{{ 'Edit' | translate }}"
            color="primary-accent"
            [type]="'button'"
            (click)="toggleFormState(false)"
          >
          </e11-button>
          <e11-button
            *ngIf="!formReadOnly"
            id="button_cancel"
            value="{{ 'Cancel' | translate }}"
            color="neutral"
            [type]="'button'"
            [style]="'pill outlined'"
            (click)="cancel()"
            [buttonGroup]="true"
          >
          </e11-button>
          <e11-button
            *ngIf="!formReadOnly"
            id="submit_account"
            value="{{ 'Save' | translate }}"
            [style]="'pill'"
            color="primary-accent"
            [type]="'submit'"
          >
          </e11-button>
        </div>
      </div>
    </form>
  </div>
</e11-panel>
