// Framework
import { Component, Input } from '@angular/core'

// Platform specific
import { UserMode } from '../../model/component-modes.enum'

// Third party
import { TranslateService } from '@ngx-translate/core'
import { ICandidateResponses } from '../../model/candidate-application.model'

@Component({
  selector: 'job-application-confirmations',
  templateUrl: './application-confirmations.component.html',
  styleUrls: ['./application-confirmations.component.scss'],
})
export class MyJobsApplicationConfirmationsComponent {
  showConfirmationDetails: boolean = false
  @Input() confirmations?: ICandidateResponses
  @Input() userMode: UserMode = UserMode.Candidate

  constructor(private translateService: TranslateService) {}

  toggleConfirmationDetails() {
    // This could be handled in the template but putting here incase we need to expand on the functionality later
    this.showConfirmationDetails = !this.showConfirmationDetails
  }
}
