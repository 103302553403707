import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { deepCopy } from '@engineering11/utility'
import { NGXLogger } from 'ngx-logger'

import { FilesService, IFileReceipt } from '@engineering11/files-web'
import { UserMode } from '../../model/component-modes.enum'
import { IFirstImpression } from '../../model/interfaces'
import { PlayerSize } from '@engineering11/multimedia-web'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'single-first-impression',
  templateUrl: './single-first-impression.component.html',
  styleUrls: ['./single-first-impression.component.scss'],
})
export class SingleFirstImpressionComponent implements OnInit, OnChanges {
  @Input() firstImpression?: IFirstImpression | null
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() readOnly = false
  @Output()
  openRequestUpdate: EventEmitter<boolean> = new EventEmitter<boolean>()
  @ViewChild('modalItem')
  modal!: E11ModalComponent
  signedFirstImpression?: IFirstImpression
  playerSizeEnum = PlayerSize
  loaded = false
  showPlayer = false
  videoPlayerResource!: { thumb?: string; video?: string }

  enumUserMode = UserMode

  constructor(private filesService: FilesService, private logger: NGXLogger) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAndSignFirstImpression()
  }

  async ngOnInit(): Promise<void> {
    this.loadAndSignFirstImpression()
    this.loaded = true
  }

  async loadAndSignFirstImpression() {
    if (this.firstImpression) {
      this.firstImpression = deepCopy(this.firstImpression) // Need this because signThumbnail mutates the original state
      this.signedFirstImpression = await this.signThumbnail(this.firstImpression!) // The input data could be overwritten
    } else {
      this.signedFirstImpression = undefined
    }
  }

  async signThumbnail(firstImpression: IFirstImpression): Promise<IFirstImpression> {
    let fileReceipts = new Array<IFileReceipt>()
    if (firstImpression.thumb) {
      fileReceipts = await this.filesService.signedURLs([firstImpression.thumb])
      firstImpression.thumb.signedURL = fileReceipts[0].signedURL
    }
    return firstImpression
  }

  openVideoPlayer(firstImpression: IFirstImpression) {
    if (firstImpression.thumb?.signedURL) {
      this.videoPlayerResource = {
        thumb: firstImpression.thumb.signedURL,
      }

      if (firstImpression.video) {
        this.filesService.signedURLs([firstImpression.video]).then(val => {
          if (val.length > 0 && val[0].signedURL) {
            this.videoPlayerResource.video = val[0].signedURL
            this.videoPlayerResource = Object.assign({}, this.videoPlayerResource)
          }
        })
      }

      if (this.userMode !== this.enumUserMode.Public) {
        this.modal.open()
      }
      this.showPlayer = true
    }
  }

  closeVideoModal() {
    this.showPlayer = false

    this.videoPlayerResource = { thumb: undefined, video: undefined }
  }
}
