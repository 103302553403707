<!-- Layout or SDK wrapper -->
<div class="e11-pb-16 e11-w-full e11-flex e11-justify-center">
  <div class="e11-w-3/4 md:e11-w-1/2 lg:e11-w-1/3">
    <!-- Dynamic/Conditional Panel title -->
    <h1 class="e11-text-center e11-text-skin-primary e11-font-skin-century-gothic e11-mb-4">{{ 'Create Your Employer Account' | translate }}</h1>
    <p class="e11-text-center">{{ "First, we'll need your email address." | translate }}</p>

    <e11-panel [hasBorder]="true" [hasShadow]="false" [contentClassOverrides]="'e11-px-8 e11-py-6'">
      <!-- Email check -->
      <registration-sdk (onSubmit)="onSubmit()"></registration-sdk>

      <div class="spacer-2rem"></div>
    </e11-panel>
  </div>
</div>
