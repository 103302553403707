import { Injectable } from '@angular/core'
import { E11NotificationsService, E11NotificationMessage, NotificationType, INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { deepCopy } from '@engineering11/utility'
import { TranslateService } from '@ngx-translate/core'

@Injectable({ providedIn: 'root' })
export class NotificationTranslateService {
  constructor(private notificationsService: E11NotificationsService, private translate: TranslateService) {}

  /**
   * @deprecated - use @popNotification - it's more extensible
   * @param title
   * @param message
   * @param type
   * @param autoClose
   * @param ctaText
   */
  popNotificationMessage(title: string, message: string, type: NotificationType, autoClose: boolean, ctaText = '') {
    const notification = { title, message, type, autoClose, ctaText }
    const translatedNotification = this.translateNotification(notification)
    this.notificationsService.popNotificationMessage(translatedNotification)
  }

  popNotification(notification: INotificationMessage) {
    const translatedNotification = this.translateNotification(notification)
    this.notificationsService.popNotificationMessage(translatedNotification)
  }

  private translateNotification(notification: INotificationMessage): INotificationMessage {
    const translatedNotification = deepCopy(notification)

    this.translate.get(translatedNotification.title).subscribe((res: string) => {
      translatedNotification.title = res
    })
    this.translate.get(translatedNotification.message).subscribe((res: string) => {
      translatedNotification.message = res
    })
    return translatedNotification
  }
}
