<div class="e11-w-full e11-flex">
  <div class="e11-grow">
    <h2 class="e11-text-skin-primary-accent">{{ 'Departments' | translate }}</h2>
    <p class="e11-mb-0">{{ 'Manage departments within your company' | translate }}</p>
  </div>
  <div class="e11-grow e11-flex e11-items-center e11-justify-end">
    <e11-button
      [style]="'pill outlined'"
      color="secondary"
      value="{{ 'Add Department' | translate }}"
      [icon]="'add'"
      [iconPosition]="'left'"
      [iconSize]="'md-18'"
      [routerLink]="['/customer/departments/detail']"
    >
    </e11-button>
  </div>
</div>

<!-- Margin @0 to get more control over area -->
<e11-divider [margin]="1" [width]="100"></e11-divider>

<div>
  <!-- Main -->
  <div class="content-main">
    <e11-panel [hasBorder]="true" [hasShadow]="false">
      <div class="e11-flex">
        <div class="e11-w-full">
          <div class="e11-hidden md:e11-flex e11-font-medium e11-border-b e11-border-skin-app-borders">
            <div class="e11-w-5/12 e11-font-primary-demibold e11-mb-2">Code</div>
            <div class="e11-w-6/12 e11-font-primary-demibold e11-mb-2">Name</div>
            <div class="e11-w-2/12 e11-font-primary-demibold e11-mb-2 text-center ellipsis">Active</div>
            <div class="e11-w-2/12 e11-font-primary-demibold e11-mb-2 text-center">Edit</div>
          </div>

          <div class="loading" *ngIf="loading">
            <div class="e11-w-full e11-flex e11-text-center e11-items-center e11-justify-center">
              <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary-accent'"></e11-loader-dots>
            </div>
          </div>

          <div *ngIf="(departments$ | async) && (departments$ | async)?.length !== 0; else emptyState">
            <div
              class="e11-flex e11-rounded-lg e11-mb-4 e11-overflow-hidden e11-border e11-flex-col md:e11-border-0 md:e11-rounded-none md:e11-mb-0 md:e11-flex-row e11-items-center md:e11-h-[4rem] e11-cursor-pointer hover:e11-bg-skin-secondary-100 md:e11-border-b md:e11-border-skin-app-borders"
              *ngFor="let department of departments$ | async"
              [routerLink]="['/customer/departments/detail']"
              [queryParams]="{ id: department.id }"
            >
              <div class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-5/12 ellipsis">
                <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Code</span>
                {{ department.internalCode }}
              </div>
              <div class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-6/12 ellipsis">
                <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Name</span>
                {{ department.name }}
              </div>
              <div class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-2/12 md:e11-text-center ellipsis">
                <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Active</span>
                <span class="material-icons-outlined e11-text-skin-primary" *ngIf="department.active"> thumb_up </span>
                <span class="material-icons-outlined e11-text-skin-light" *ngIf="!department.active"> thumb_down </span>
              </div>
              <div
                class="e11-w-full e11-border-b md:e11-border-b-0 e11-flex e11-items-center md:e11-block md:e11-w-2/12 md:e11-text-center color-secondary"
              >
                <span class="md:e11-hidden e11-w-1/4 e11-mr-2 e11-pl-3 e11-py-3 e11-font-medium e11-bg-skin-primary-100">Edit</span>
                <span class="material-icons-outlined"> edit </span>
              </div>

              <ng-template #emptyState>
                <e11-empty-state
                  *ngIf="!loading"
                  [icon]="'safety_divider'"
                  [iconSize]="'md-36'"
                  [iconType]="''"
                  title="{{ 'No Company Departments' | translate }}"
                  subtitle="{{ 'It appears that we do not have any company departments entered yet.' | translate }}"
                  (click)="addDepartment()"
                >
                </e11-empty-state>
              </ng-template>
            </div>
          </div>

          <ng-template #emptyState>
            <div class="text-center" *ngIf="!loading">
              <div class="spacer-2rem"></div>
              <h2 class="text-secondary">{{ 'No Company Departments' | translate }}</h2>
              <p>{{ 'It appears that we do not have any company departments entered yet.' | translate }}</p>
              <h3 class="pointer" [routerLink]="['/customer/departments/detail']">{{ "Let's add some!" | translate }}</h3>
            </div>
          </ng-template>
        </div>
      </div>
    </e11-panel>
  </div>
</div>
