<ng-container *ngrxLet="features$ as features">
  <div class="mobile-drawer-container e11-w-full e11-fixed e11-top-[87px] e11-bottom-0 e11-z-20" [ngClass]="{ 'side-drawer-active': isOpen }">
    <div
      class="mobile-drawer e11-top-0 e11-w-[90vw] e11-fixed e11-top-[87px] e11-bottom-0 e11-z-20 e11-divide-y e11-divide-gray-200 e11-flex e11-flex-col e11-shadow-xl"
    >
      <div class="e11-bg-skin-primary e11-text-white e11-flex-1 e11-h-0 e11-overflow-y-auto e11-overscroll-none e11-pt-5">
        <div *ngIf="selectedTab === 'home'" class="e11-px-4">
          <h5 class="e11-mb-5 e11-font-primary-demibold e11-text-md">{{ 'Home' | translate }}</h5>

          <ul class="e11-flex e11-flex-col e11-pl-3">
            <ng-container
              [ngTemplateOutlet]="directRouteNavItemTemplate"
              [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.Jobs) }"
            ></ng-container>

            <ng-container
              [ngTemplateOutlet]="directRouteNavItemTemplate"
              [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.JobTemplates) }"
            ></ng-container>

            <ng-container
              [ngTemplateOutlet]="directRouteNavItemTemplate"
              [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.Applications) }"
            ></ng-container>

            <ng-container
              *ngIf="canManageCompany$ | async"
              [ngTemplateOutlet]="dropdownRouteNavItemTemplate"
              [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.CompanyAccount) }"
            ></ng-container>

            <ng-container
              *ngIf="canManageTeam$ | async"
              [ngTemplateOutlet]="directRouteNavItemTemplate"
              [ngTemplateOutletContext]="{ navItem: getNavItem(navItemNames.ManageUsers) }"
            ></ng-container>
          </ul>
        </div>

        <div *ngIf="selectedTab === 'messages'" class="e11-px-4">
          <!-- Messages -->
          <div *ngIf="features?.messaging">
            <h5 class="e11-mb-5 e11-font-primary-demibold e11-text-md">{{ 'Messages' | translate }}</h5>

            <div class="e11-px-2">
              <div class="e11-w-full e11-mt-2 e11-mb-4 e11-rounded-lg e11-bg-skin-primary-accent/20 e11-pt-4">
                <sdk-conversation-nav
                  [type]="conversationTypesEnum.JobApplicationDM"
                  [emptyStateSubtitle]="'This is where you discuss your job applications with employers'"
                  [darkMode]="true"
                ></sdk-conversation-nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e11-text-skin-light e11-flex e11-justify-around e11-p-4 e11-flex-shrink-0 e11-bg-skin-primary">
        <div (click)="selectedTab = 'home'" class="e11-flex e11-flex-col e11-items-center" [ngClass]="{ 'e11-text-white': selectedTab === 'home' }">
          <span class="icon material-icons">home</span>
          <span>{{ 'Home' | translate }}</span>
        </div>
        <div
          *ngIf="features?.messaging"
          (click)="selectedTab = 'messages'"
          class="e11-relative e11-flex e11-flex-col e11-items-center"
          [ngClass]="{ 'e11-text-white': selectedTab === 'messages' }"
        >
          <span class="icon material-icons material-icons-outlined">chat</span>
          <span>{{ 'Messages' | translate }}</span>
          <e11-badge
            *ngIf="unreadConversationCount$ | async"
            [value]="(unreadConversationCount$ | async) ?? 0"
            [color]="'error'"
            [size]="'sm'"
            [posAdjustX]="'-32px'"
            [posAdjustY]="'-12px'"
            [limit]="UNREAD_LIMIT"
            [containerClassOverrides]="''"
          ></e11-badge>
        </div>
        <div (click)="close(true)" class="e11-flex e11-flex-col e11-items-center">
          <span class="icon material-icons material-icons-outlined">arrow_back_ios</span>
          <span>{{ 'Close' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #directRouteNavItemTemplate let-navItem="navItem">
  <li class="e11-flex e11-h-12 e11-items-center e11-gap-2 e11-cursor-pointer" [routerLink]="[navItem.path]">
    <span class="material-icons">{{ navItem.icon }}</span> {{ navItem.name | translate }}
  </li>
</ng-template>

<ng-template #dropdownRouteNavItemTemplate let-navItem="navItem">
  <ul>
    <li class="e11-flex e11-h-12 e11-justify-between e11-items-center e11-cursor-pointer" (click)="toggleNavGroup(navItem.navGroup)">
      <div class="e11-flex e11-items-center e11-gap-2">
        <span class="material-icons material-icons-outlined">{{ navItem.icon }}</span>
        <span class="e11-font-medium e11-text-md">{{ navItem.name | translate }}</span>
      </div>
      <span class="material-icons-outlined e11-text-skin-extra-light nav-icon" [ngClass]="{ 'nav-icon-active': isActiveNavGroup(navItem.navGroup) }"
        >expand_more</span
      >
    </li>
    <ul class="e11-px-2" *ngIf="isActiveNavGroup(navItem.navGroup)">
      <li
        *ngFor="let child of navItem.children"
        class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/30 e11-rounded-md"
        [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
        [routerLinkActiveOptions]="{ exact: true }"
        [queryParams]="child.queryParams"
        [routerLink]="[child.path]"
      >
        {{ child.label | translate }}
      </li>
    </ul>
  </ul>
</ng-template>
