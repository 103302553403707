import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { UserMode } from '../../model/component-modes.enum'
import { IFirstImpression } from '../../model/interfaces'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'single-first-impression-card',
  templateUrl: './single-first-impression-card.component.html',
  styleUrls: ['./single-first-impression-card.component.scss'],
})
export class SingleFirstImpressionCardComponent implements OnInit {
  userModeEnum = UserMode
  @Input() firstImpression?: IFirstImpression

  @Input() userMode: UserMode = UserMode.Candidate

  @Input() readonly = false

  displayRequestUpdate = false

  @Output()
  openVideoPlayer: EventEmitter<IFirstImpression> = new EventEmitter<IFirstImpression>()

  @Output()
  openRequestUpdate: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(private logger: NGXLogger) {}

  ngOnInit(): void {
    this.displayRequestUpdate = this.userMode === UserMode.Employer
  }

  onClickFirstImpression() {
    this.logger.log('opening first impression', this.firstImpression)
    this.openVideoPlayer.emit(this.firstImpression)
  }
}
