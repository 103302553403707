import { Action } from '@ngrx/store'

export enum SearchKeyActionTypes {
  onInit = '[Search Key Initial App Load] on init',
  onInitSuccess = '[Search Key Initial App Load] on init success',
  getUserSearchKey = '[User Search Key] get user search key',
  getUserSearchKeySuccess = '[User Search Key] get user search key success',
  getProfileSearchKey = '[Profile Key] get profile search key',
  getProfileSearchKeySuccess = '[Profile Key] get profile search key success',
  getPostSearchKey = '[Post Key] get post search key',
  getPostSearchKeySuccess = '[Post Key] get post search key success',
  getPostCandidateSearchKey = '[Post Candidate Key] get post candidate search key',
  getPostCandidateSearchKeySuccess = '[Post Candidate Key] get post candidate search key success',
  reset = '[Search Key] search Key reset',
  error = '[Search Key] error',
}

export type CandidateSearchKeyData = { jobPostId: string; jobPostCandidateSearchKey: string }
export type CandidateSearchKeyMap = { [key: string]: CandidateSearchKeyData }
export class OnInit implements Action {
  readonly type = SearchKeyActionTypes.onInit
  constructor() {}
}

export class OnInitSuccess implements Action {
  readonly type = SearchKeyActionTypes.onInitSuccess
  constructor(
    public payload: {
      userSearchKey: string | null
      jobProfileSearchKey: string | null
      jobPostSearchKey: string | null
      candidateSearchKeyMap: CandidateSearchKeyMap | null
    }
  ) {}
}

export class GetUserSearchKey implements Action {
  readonly type = SearchKeyActionTypes.getUserSearchKey
  constructor(public payload: { key: string }) {}
}

export class GetUserSearchKeySuccess implements Action {
  readonly type = SearchKeyActionTypes.getUserSearchKeySuccess
  constructor(public payload: string) {}
}

export class GetProfileSearchKey implements Action {
  readonly type = SearchKeyActionTypes.getProfileSearchKey
  constructor() {}
}

export class GetProfileSearchKeySuccess implements Action {
  readonly type = SearchKeyActionTypes.getProfileSearchKeySuccess
  constructor(public payload: string) {}
}

export class GetJobPostSearchKey implements Action {
  readonly type = SearchKeyActionTypes.getPostSearchKey
  constructor() {}
}

export class GetJobPostSearchKeySuccess implements Action {
  readonly type = SearchKeyActionTypes.getPostSearchKeySuccess
  constructor(public payload: string) {}
}

export class GetJobPostCandidateSearchKey implements Action {
  readonly type = SearchKeyActionTypes.getPostCandidateSearchKey
  constructor(public payload: string) {}
}

export class GetJobPostCandidateSearchKeySuccess implements Action {
  readonly type = SearchKeyActionTypes.getPostCandidateSearchKeySuccess
  constructor(public payload: CandidateSearchKeyData) {}
}

export class Reset implements Action {
  readonly type = SearchKeyActionTypes.reset
  constructor() {}
}

export class ErrorAction implements Action {
  readonly type = SearchKeyActionTypes.error
  constructor(public payload?: []) {}
}

export type SearchKeyActions =
  | OnInit
  | OnInitSuccess
  | GetUserSearchKey
  | GetUserSearchKeySuccess
  | GetProfileSearchKey
  | GetProfileSearchKeySuccess
  | GetJobPostSearchKey
  | GetJobPostSearchKeySuccess
  | GetJobPostCandidateSearchKey
  | GetJobPostCandidateSearchKeySuccess
  | Reset
  | ErrorAction
