// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Routing
import { AuthenticationRoutingModule } from './authentication.routing'

// Modules
import { SharedModule } from '../_shared/shared.module'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Components
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthenticationRecoverComponent } from './views/recover/recover.component'
import { AuthenticationRegisterComponent } from './views/register/register.component'
import { AuthenticationRegisterVerifyComponent } from './views/register-verify/register-verify.component'
import { AuthenticationRegisterCompanyComponent } from './views/register-company/register-company.component'
import { AuthenticationTermsComponent } from './views/terms/terms.component'
import { AuthModule, IAuthConfig } from '@engineering11/auth-web'
import { RegistrationModule, IRegistrationConfig } from '@engineering11/registration-web'
import { UserModule } from '@engineering11/user-web'
import { AuthenticationCodeWallComponent } from './views/code-wall/code-wall.component'
import { RecaptchaModule } from 'ng-recaptcha'
import { BetaService } from '@employer/app/services/beta/beta.service'
import { IBetaService } from '@employer/app/services/beta/beta.service.interface'

import { environment } from '../../../environments/environment'
import { RegisterInviteComponent } from './views/register-invite/register-invite.component'
import { EmailActionsComponent } from './views/email-actions/email-actions.component'
import { ActionSuccessComponent } from './views/action-success/action-success.component'

const registrationConfig: IRegistrationConfig = {
  registrationBaseURL: environment.services.registration,
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: environment.emailVerificationEmailTemplateId,
  tenantId: environment.tenantId,
}

const authConfig: IAuthConfig = {
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: environment.emailVerificationEmailTemplateId,
  passwordRecoveryEmailTemplateId: environment.passwordRecoveryEmailTemplateId,
  tenantId: environment.tenantId,
}
@NgModule({
  declarations: [
    AuthenticationComponent,

    AuthenticationLoginComponent,
    AuthenticationRecoverComponent,
    AuthenticationRegisterComponent,
    AuthenticationRegisterCompanyComponent,
    AuthenticationRegisterVerifyComponent,
    AuthenticationTermsComponent,
    AuthenticationCodeWallComponent,
    RegisterInviteComponent,
    EmailActionsComponent,
    ActionSuccessComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    AuthModule.forRoot(authConfig),
    RegistrationModule.forRoot(registrationConfig),
    UserModule,
    AuthenticationRoutingModule,
    UiComponentLibraryModule,
  ],
  exports: [],
  providers: [{ provide: IBetaService, useClass: BetaService }],
})
export class AuthenticationModule {}
