// Framework
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'

@Component({
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  backgroundCycling = false // Enables background image cycling.
  backgroundMax = 5
  backgroundRnd = 1 // Default login background with no image cycling
  header = 'landing' // Headers need to change based on route - make 1 header more dynamic (multiple dumb headers or 1 smart)
  isLoading: boolean = true

  constructor(private router: Router) {
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // let page = this.router.url.split('/').pop()
        // const pageSections = page?.split('?')
        // page = pageSections ? pageSections[0] : page
        const urlTree: UrlTree = this.router.parseUrl(this.router.url)
        const primary = urlTree.root.children[PRIMARY_OUTLET]
        let page = primary.segments[1]?.path || 'home'

        if (this.router.url.indexOf('register-invite') > 0 || this.router.url.indexOf('register-verify') > 0) {
          page = 'register'
        }
        if (this.router.url.indexOf('login') > 0) {
          page = 'login'
        }
        if (this.router.url.indexOf('recover') > 0) {
          page = 'recover'
        }
        if (page === 'login' || page === 'code' || page === 'register' || page === 'actions' || page === 'action-success' || page === 'recover') {
          this.header = page
          if (this.backgroundCycling) {
            this.backgroundRnd = this.getRandomNumberBetween(1, this.backgroundMax)
          }
        } else {
          this.header = 'landing'
          this.backgroundRnd = 1
        }
      }
    })
  }

  ngOnInit(): void {
    this.loadImages()
    // Ditch any locally stored searchKeys to prevent no searches being displayed due to non-matching search keys
    const jobProfileSearchKey = JSON.parse(localStorage.getItem('jobProfileSearchKey') || '{}')
    const jobPostSearchKey = JSON.parse(localStorage.getItem('jobPostSearchKey') || '{}')
    const userSearchKey = JSON.parse(localStorage.getItem('userSearchKey') || '{}')
    if (jobProfileSearchKey) localStorage.removeItem('jobProfileSearchKey')
    if (jobPostSearchKey) localStorage.removeItem('jobPostSearchKey')
    if (userSearchKey) localStorage.removeItem('userSearchKey')
  }

  getRandomNumberBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  // With backgroundCycling false we will only load 1 image. It can still be slow enough to need a "loader"
  backgroundImages = [
    'https://content.cnected.com/assets/login/2.jpg',
    // '../../../../assets/images/login/2.jpg',
    // '../../../../assets/images/login/3.jpg',
    // '../../../../assets/images/login/4.jpg',
    // '../../../../assets/images/login/5.jpg',
  ]
  loadedImages = 0

  loadImages() {
    for (let i = 0; i < this.backgroundImages.length; i++) {
      let img = new Image()
      img.onload = () => {
        this.loaded()
      }
      img.src = this.backgroundImages[i]
    }
  }

  loaded() {
    this.loadedImages++
    //
    if (this.backgroundImages.length == this.loadedImages) {
      //all images loaded
      this.isLoading = false
      //
    }
  }
}
