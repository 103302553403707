// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Routing
import { CustomerRoutingModule } from './customer.routing'

// Modules
import { UiComponentLibraryModule } from '../_component-library/component-library.module'

// Components
import { CustomerComponent } from './customer.component'
import { CustomerHomeComponent } from './views/home/customer-home.component'
import { SharedModule } from '../_shared/shared.module'
import { CustomerManageProfilePicComponent } from './components/manage-profile-pic/manage-profile-pic.component'
import { CustomerManageCompanyInfoComponent } from './components/manage-company-info/manage-company-info.component'
import { CustomerManageSettingsComponent } from './components/manage-settings/manage-settings.component'
import { ManageEmployerSettingsComponent } from './components/manage-employer-settings/manage-employer.component'
import { CustomerManageIndustryComponent } from './components/manage-industry/manage-industry.component'
import { CustomerDepartmentsComponent } from './views/departments/departments.component'
import { CustomerLocationsComponent } from './views/locations/locations.component'
import { CustomerMenuLeftComponent } from './components/menu-left/menu-left.component'
import { CustomerLocationDetailComponent } from './views/locations/location-detail/location-detail.component'
import { CustomerLocationListComponent } from './views/locations/location-list/location-list.component'
import { CustomerDepartmentsListComponent } from './views/departments/list/list.component'
import { CustomerDepartmentDetailComponent } from './views/departments/detail/detail.component'
import { ManageCompanyLogoComponent } from './components/manage-company-logo/manage-company-logo.component'
import { CustomerModalProfilePicComponent } from './components/modal-profile-pic/modal-profile-pic.component'
import { CareersPageComponent } from './views/careers-page/careers-page.component'
import { CompanyLogoUploadModalComponent } from './components/company-logo-upload-modal/company-logo-upload-modal.component'

@NgModule({
  declarations: [
    CustomerComponent,
    CustomerHomeComponent,
    CustomerMenuLeftComponent,
    CustomerManageProfilePicComponent,
    CustomerManageCompanyInfoComponent,
    CustomerManageSettingsComponent,
    ManageEmployerSettingsComponent,
    CustomerManageIndustryComponent,
    CustomerDepartmentsComponent,
    CustomerLocationsComponent,
    CustomerLocationDetailComponent,
    CustomerLocationListComponent,
    CustomerDepartmentsListComponent,
    CustomerDepartmentDetailComponent,
    ManageCompanyLogoComponent,
    CustomerModalProfilePicComponent,
    CareersPageComponent,
    CompanyLogoUploadModalComponent,
  ],
  imports: [CommonModule, SharedModule, CustomerRoutingModule, UiComponentLibraryModule, FormsModule, ReactiveFormsModule],
  exports: [],
  providers: [],
})
export class CustomerModule {}
