<div class="content-main">
  <h2 id="address" class="e11-text-skin-primary-accent">{{ 'Company Account Information' | translate }}</h2>
  <p>{{ "View and update your company's information." | translate }}</p>

  <div class="e11-w-full e11-mb-16">
    <customer-manage-company-info id="address"></customer-manage-company-info>

    <div class="spacer-2rem"></div>

    <customer-manage-company-logo id="logo"></customer-manage-company-logo>

    <div class="spacer-2rem"></div>

    <manage-employer-settings id="disclaimer"></manage-employer-settings>
  </div>
</div>
