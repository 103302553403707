import * as configActions from './config/config.actions'
import * as configEffects from './config/config.effects'
import * as configReducer from './config/config.reducer'
import * as configSelectors from './config/config.selectors'

import * as userActions from './user/user.actions'
import * as userEffects from './user/user.effects'
import * as userReducer from './user/user.reducer'
import * as userSelectors from './user/user.selectors'

export { configActions, configEffects, configReducer, configSelectors }
export { userActions, userEffects, userReducer, userSelectors }

export const sharedEffects = { ...configEffects, ...userEffects }
export const sharedSelectors = { ...configSelectors, ...userSelectors }
export const sharedReducers = { config: configReducer, user: userReducer }
