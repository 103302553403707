import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IEmployerJobPostClosedNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class EmployerJobPostClosedNotificationHandler implements INotificationHandler<IEmployerJobPostClosedNotification, INotificationView> {
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}
  notificationType = 'employer-job-post-closed'
  toViewModel(notification: IEmployerJobPostClosedNotification): INotificationView {
    return {
      description: `The Job Post ${notification.jobTitle} has been closed.`,
      logoUrl: '',
      originalNotification: notification,
      viewed: notification.viewed,
      title: 'Job Post - Closed',
    }
  }
  async onClick(notification: IEmployerJobPostClosedNotification): Promise<boolean> {
    if (!notification.viewed) {
      await this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    const contentId = notification.contentId ?? notification.jobPostId.split('_')[0]
    return this.router.navigate(['/jobs/post/', contentId])
  }
}
