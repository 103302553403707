import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IApplicationCommentCreatedNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class ApplicationCommentNotificationHandler implements INotificationHandler<IApplicationCommentCreatedNotification, INotificationView> {
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}
  notificationType = 'application-comment-created'
  toViewModel(notification: IApplicationCommentCreatedNotification): INotificationView {
    return {
      description: `A new comment has been added on a candidate for the ${notification.jobTitle} job.`,
      logoUrl: '',
      originalNotification: notification,
      viewed: notification.viewed,
      title: 'New Comment',
    }
  }
  onClick(notification: IApplicationCommentCreatedNotification) {
    this.markAsRead(notification)
    return this.router.navigate(['/jobs/candidates/', notification.jobPostId], { queryParams: { candidateId: notification.candidateApplicationId } })
  }

  private markAsRead(notification: IApplicationCommentCreatedNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
