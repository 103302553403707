// Framework
import { ViewportScroller } from '@angular/common'
import { Component, ViewChild } from '@angular/core'

// Platform specific
import { ConfigService } from '@employer/app/services/config.service'

@Component({
  templateUrl: './customer-home.component.html',
  styleUrls: ['./customer-home.component.scss'],
})
export class CustomerHomeComponent {
  @ViewChild('templateOne') templateOne: any
  themeCurrent: any

  constructor(private configService: ConfigService, private viewportScroller: ViewportScroller) {
    this.themeCurrent = configService.config.theme
  }

  goTo(event: any) {
    // this.viewportScroller.setOffset([0, 0])
    this.viewportScroller.scrollToAnchor(event)
  }
}
