import { Component, EventEmitter, Input, Output } from '@angular/core'
import { groupByKey } from '@engineering11/utility'
import { NGXLogger } from 'ngx-logger'
import { IRequestedUpdate, RequestedUpdateFields } from '../../../model/candidate-application.model'
import { UserMode } from '../../../model/component-modes.enum'
import { MatchType, RequestedFieldChanged } from '../../../model/enum'
import { ICertification, IMatchedCertification, IMatchedSkill, ISkill } from '../../../model/interfaces'

const FilterTypes = Object.freeze({ ...MatchType, NoMatch: 'NoMatch' as const })
type FilterKeys = keyof typeof FilterTypes
@Component({
  selector: 'skills-result',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class CandidateSkillsResultComponent {
  filterTypeEnum = FilterTypes
  userModeEnum = UserMode

  filterTypes = Object.values(FilterTypes)
  filterCurrent: FilterKeys = MatchType.Exact

  translationFilterTypes = {
    Exact: 'Exact',
    Partial: 'Partial',
    NotListed: 'Other',
    NoMatch: 'Missing',
  }

  openRequest?: RequestedUpdateFields

  groupedSkills: Record<string, ISkill[]> = Object.values(FilterTypes).reduce((prv, nxt) => ({ ...prv, [nxt]: [] }), {})
  groupedCertifications: Record<string, ICertification[]> = Object.values(FilterTypes).reduce((prv, nxt) => ({ ...prv, [nxt]: [] }), {})

  // Needed for empty state
  matchedSkills!: IMatchedSkill[]
  matchedCerts!: IMatchedCertification[]

  constructor(private logger: NGXLogger) {}

  @Output()
  requestFromComponent = new EventEmitter()

  @Input() requestsDisabled = false

  @Input() requests: IRequestedUpdate[] = []

  @Input()
  userMode: UserMode = UserMode.Candidate

  @Input()
  set skills(matchedSkills: IMatchedSkill[]) {
    this.matchedSkills = matchedSkills
    this.groupedSkills = { ...this.groupedSkills, ...groupByKey(matchedSkills, 'matchType') }
  }

  @Input()
  set missingSkills(missingSkills: ISkill[]) {
    this.groupedSkills = { ...this.groupedSkills, NoMatch: missingSkills }
  }

  @Input()
  set certifications(matchedCertifications: IMatchedCertification[]) {
    this.matchedCerts = matchedCertifications
    this.groupedCertifications = { ...this.groupedCertifications, ...groupByKey(matchedCertifications, 'matchType') }
  }

  @Input()
  set missingCertifications(missingCertifications: ICertification[]) {
    this.groupedCertifications = { ...this.groupedCertifications, NoMatch: missingCertifications }
  }

  processRequests() {
    this.openRequest = this.requests.find(
      item => item.requestedFieldChanged === RequestedFieldChanged.Skills && !item.fulfilled
    )?.requestedFieldChanged
    return this.openRequest
  }

  requestModal() {
    this.requestFromComponent.emit('Skills')
  }
}
