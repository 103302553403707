<div class="e11-w-full">
  <e11-panel>
    <div class="e11-p-4">
      <h1 class="e11-w-full e11-text-center e11-text-skin-primary-accent e11-text-5xl e11-mb-8">
        4<i class="far fa-frown"></i>4 - {{ 'Page not found' | translate }}
      </h1>
      <h3 class="e11-w-full e11-text-center">{{ "Well, ...there isn't any pretending that didn't just happen." | translate }}</h3>
    </div>
  </e11-panel>

  <e11-panel>
    <div class="e11-p-4 e11-text-center">
      <h3 class="e11-mb-4">{{ "Let's see if we can get back on track..." }}</h3>
      <p>{{ 'Click on the logo in the upper left to go Home.' | translate }}</p>
      <p>{{ 'Click on your name in the upper right for options...' | translate }}</p>
      <p>
        <a href="/" class="e11-text-skin-primary-accent">{{ 'Click here if all else fails.' | translate }}</a>
      </p>
    </div>
  </e11-panel>
</div>
