import { environment } from '@employer/environments/environment'
import * as Rollbar from 'rollbar'

export const rollbarConfig: Rollbar.Configuration = {
  enabled: environment.env !== 'dev', // Don't emit when developing on stage
  accessToken: '8d26e414af054537a919a3436d43d86a',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.env,
    server: {
      root: 'webpack:///./',
    },
    client: {
      javascript: {
        source_map_enabled: true, // true by default

        // -- Add this into your configuration ---
        code_version: '0e64d29eaa1c72b1b1651c886ed9b6ca3e6bcd6b',
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig)
}
