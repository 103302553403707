<!-- Outstanding/Open Request -->
<div
  class="e11-my-4 e11-relative e11-block e11-w-full e11-border-2 e11-border-skin-app-borders e11-border-dashed e11-rounded-lg e11-p-2 e11-text-center"
  *ngIf="request"
>
  <span class="material-icons md-36 e11-mx-auto e11-text-skin-secondary">pending</span>

  <!-- <h3 class="e11-mb-0 e11-text-skin-light">
    {{ 'Candidate has been notified' | translate }}
  </h3> -->
  <div class="e11-text-sm e11-text-skin-light">
    <span *ngIf="request === requestTypes.Education">
      {{ 'We have sent a notification to the candidate to provide educational information.' | translate }}
    </span>
    <span *ngIf="request === requestTypes.References">
      {{ 'We have sent a notification to the candidate to provide references.' | translate }}
    </span>
    <span *ngIf="request === requestTypes.WorkHistory">
      {{ 'We have sent a notification to the candidate to provide work history.' | translate }}
    </span>
    <span *ngIf="request === requestTypes.Skills">
      {{ 'We have sent a notification to the candidate to provide skills.' | translate }}
    </span>
    <span *ngIf="request === requestTypes.Certifications">
      {{ 'We have sent a notification to the candidate to provide certifications.' | translate }}
    </span>
    <span *ngIf="request === requestTypes.FirstImpression || $any(request) === 'firstImpressionUrl'">
      {{ 'We have sent a notification to the candidate to provide a First Impression.' | translate }}
    </span>
  </div>
</div>
