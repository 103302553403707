export enum SkillLevel {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Expert = 'Expert',
}

export const skillLevelRank = {
  [SkillLevel.Beginner]: 0,
  [SkillLevel.Intermediate]: 1,
  [SkillLevel.Expert]: 2,
}

export enum DegreeLevel {
  NotSpecified = 'NotSpecified',
  HighSchool = 'HighSchool',
  VocationalHsDiploma = 'VocationalHsDiploma',
  VocationalDegree = 'VocationalDegree',
  Associates = 'Associates',
  Bachelors = 'Bachelors',
  Masters = 'Masters',
  PhD = 'PhD',
}

export const degreeLevelMappings = new Map<DegreeLevel, string>()
  .set(DegreeLevel.NotSpecified, 'Not Specified')
  .set(DegreeLevel.HighSchool, 'High School')
  .set(DegreeLevel.VocationalHsDiploma, 'Vocational High School Diploma')
  .set(DegreeLevel.VocationalDegree, 'Vocational Degree')
  .set(DegreeLevel.Associates, "Associate's Degree")
  .set(DegreeLevel.Bachelors, "Bachelor's Degree")
  .set(DegreeLevel.Masters, "Master's Degree")
  .set(DegreeLevel.PhD, 'Doctorate/PhD')

export enum MilitaryAffiliation {
  Army = 'Army',
  MarineCorps = 'Marine Corps',
  Navy = 'Navy',
  AirForce = 'Air Force',
  CoastGuard = 'Coast Guard',
  SpaceForce = 'Space Force',
}

export enum PayRateTypeEnum {
  Hourly = 'hourly',
  Salary = 'salary',
}

export enum MatchType {
  Exact = 'Exact',
  Partial = 'Partial',
  NotListed = 'NotListed',
}

export enum APPLICATION_STATE {
  IN_PROGRESS = 'IN_PROGRESS',
  WITHDRAWN = 'WITHDRAWN',
  NOT_INTERESTED = 'NOT_INTERESTED',
  HIRED = 'HIRED',
  OFFER_SENT = 'OFFER_SENT',
  COMMUNICATING = 'COMMUNICATING',
  INTERESTED = 'INTERESTED',
}

export enum APPLICATION_STATE_LABEL {
  IN_PROGRESS = 'In progress',
  WITHDRAWN = 'Withdrawn',
  NOT_INTERESTED = 'Not interested',
  HIRED = 'Hired',
  OFFER_SENT = 'Offer sent',
  COMMUNICATING = 'Communicating',
  INTERESTED = 'Interested',
}

// Pre-work
export enum CANDIDATE_APPLICATION_STATUS {
  NEW = 'NEW',
  UNDER_REVIEW = 'UNDER_REVIEW',
  FINAL_CONSIDERATION = 'FINAL_CONSIDERATION',
  OFFER_SENT = 'OFFER_SENT',
  HIRED = 'HIRED',
  APPLICATION_DECLINED = 'APPLICATION_DECLINED',
}

export const ARCHIVED_STATES = [APPLICATION_STATE.WITHDRAWN, APPLICATION_STATE.NOT_INTERESTED]

export const applicationStateMappings = new Map<APPLICATION_STATE, string>()
  .set(APPLICATION_STATE.IN_PROGRESS, 'Applied')
  .set(APPLICATION_STATE.COMMUNICATING, 'Communicating')
  .set(APPLICATION_STATE.WITHDRAWN, 'Withdrawn')
  .set(APPLICATION_STATE.NOT_INTERESTED, 'Not Interested')
  .set(APPLICATION_STATE.HIRED, 'Hired')
  .set(APPLICATION_STATE.OFFER_SENT, 'Offer Sent')
  .set(APPLICATION_STATE.INTERESTED, 'Interested')

export enum ReferenceType {
  Personal = 'Personal',
  Professional = 'Professional',
}
export enum ReferenceRelationship {
  Executive = 'Executive',
  Manager = 'Manager',
  Peer = 'Peer',
  Subordinate = 'Subordinate',
  Customer = 'Customer',
  Vendor = 'Vendor',
  Friend = 'Friend',
}

export enum EmployeeLocation {
  Remote = 'Remote',
  InPerson = 'InPerson',
  Both = 'Both',
  Unspecified = 'Unspecified',
}

export enum CandidateReferrals {
  Cnect = 'cnect',
  Google = 'Google',
  Referral = 'Referral',
  SocialMedia = 'Social Media',
  Other = 'Other',
}

export interface SelectedDay {
  label: string
  selected: boolean
  from: string | null
  to: string | null
}

export enum CandidateJobStatus {
  Viewed = 'Viewed',
  Applied = 'Applied',
  JobClosed = 'JobClosed',
  EmployerNotInterested = 'EmployerNotInterested',
  CandidateNotInterested = 'CandidateNotInterested',
}

export const candidateJobStatusRank = {
  [CandidateJobStatus.Viewed]: 0,
  [CandidateJobStatus.Applied]: 1,
  [CandidateJobStatus.JobClosed]: 2,
  [CandidateJobStatus.EmployerNotInterested]: 2,
  [CandidateJobStatus.CandidateNotInterested]: 2,
}

export const CANDIDATE_JOB_INACTIVE = [
  CandidateJobStatus.CandidateNotInterested,
  CandidateJobStatus.EmployerNotInterested,
  CandidateJobStatus.JobClosed,
]

export const candidateJobIsInactive = (status: CandidateJobStatus) => CANDIDATE_JOB_INACTIVE.includes(status)

// ! Be careful when changing as these appear in the DB and would require a migration
export enum RequestedFieldChanged {
  Skills = 'skills',
  Certifications = 'certifications',
  Education = 'education',
  References = 'references',
  WorkHistory = 'workHistory',
  FirstImpression = 'firstImpression',
  PersonalInformation = 'personalInformation',
  Core = 'CORE',
  Portfolio = 'portfolio',
}

export interface SelectedDay {
  label: string
  selected: boolean
  from: string | null
  to: string | null
}

export enum ExperienceLevel {
  Internship = 'Internship',
  EntryLevel = 'EntryLevel',
  Associate = 'Associate',
  MidSeniorLevel = 'MidSeniorLevel',
  SeniorLevel = 'SeniorLevel',
  Manager = 'Manager',
  Director = 'Director',
  Executive = 'Executive',
  NotApplicable = 'NotApplicable',
}

export const experienceLevelMapping = new Map<ExperienceLevel, string>()
  .set(ExperienceLevel.Internship, 'Internship')
  .set(ExperienceLevel.EntryLevel, 'Entry-Level')
  .set(ExperienceLevel.Associate, 'Associate')
  .set(ExperienceLevel.MidSeniorLevel, 'Mid-Senior Level')
  .set(ExperienceLevel.SeniorLevel, 'Senior Level')
  .set(ExperienceLevel.Manager, 'Manager')
  .set(ExperienceLevel.Director, 'Director')
  .set(ExperienceLevel.Executive, 'Executive')
  .set(ExperienceLevel.NotApplicable, 'Not Applicable')

export enum EmploymentType {
  FullTime = 'FullTime', // These should not be the display values, these represent the actual data
  PartTime = 'PartTime',
  Temporary = 'Temporary',
  Contract = 'Contract',
  Other = 'Other',
}

export const employmentTypeMappings = new Map<EmploymentType, string>()
  .set(EmploymentType.FullTime, 'Full-Time')
  .set(EmploymentType.PartTime, 'Part-Time')
  .set(EmploymentType.Temporary, 'Temporary')
  .set(EmploymentType.Contract, 'Contract')
  .set(EmploymentType.Other, 'Other')

export enum JobProfileStatus {
  Published = 'Published',
  Draft = 'Draft',
  Closed = 'Closed',
  Deleted = 'Deleted',
}

export enum JobStatusEmployer {
  Active = 'Active',
  Draft = 'Draft',
  Closed = 'Closed',
}

export enum Browser {
  Opera = 'Opera',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Firefox = 'Firefox',
  IE = 'IE',
}

export enum DialogueStatus {
  SavedAsDraft = 'Saved As Draft',
  Sent = 'Sent',
  Received = 'Received',
}

export enum JobPostIntegrations {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  SnapChat = 'SnapChat',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  CareerBuilder = 'CareerBuilder',
  Dice = 'Dice',
  GlassDoor = 'GlassDoor',
  Indeed = 'Indeed',
  Monster = 'Monster',
  ZipRecruiter = 'ZipRecruiter',
}

export enum EmployerToolbar {
  Request = 'Request',
  Dialogue = 'Dialogue',
  Messages = 'Messages',
  CandidateStatus = 'CandidateStatus',
}

export enum WebsiteLinks {
  videoTutorials = 'https://cnected.com/first-impression-how-to/',
}
