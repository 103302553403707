import { Component, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { CnectFeatures, ConversationTypes, getFeatures } from 'shared-lib'
import { Store } from '@ngrx/store'
import { NavigationEnd, Router } from '@angular/router'
import { takeUntil } from 'rxjs/operators'
import { UiScrollLockingService } from '@engineering11/web-utilities'
import { ConversationStore } from '@engineering11/messaging-web'
import { APP_NAVIGATION_LEFT_GROUPS, NavLeftService } from '@employer/app/services/nav-left.service'
import { EmployerNavigationService, NavItemNames, ROUTES } from '@employer/app/services/navigation.service'
import { Permission } from '@employer/app/config/permission.config'
import { PermissionService } from '@engineering11/permissions-web'

type Tabs = 'home' | 'messages'

@Component({
  selector: 'app-mobile-side-drawer-menu',
  templateUrl: './mobile-side-drawer-menu.component.html',
  styleUrls: ['./mobile-side-drawer-menu.component.scss'],
})
export class MobileSideDrawerMenuComponent implements OnInit, OnDestroy {
  features$: Observable<CnectFeatures | undefined> = this.store.select(getFeatures)
  selectedTab: Tabs = 'home'

  conversationTypesEnum = ConversationTypes
  unreadConversationCount$ = this.conversationStore.getUnreadConversationCount
  UNREAD_LIMIT = 9

  navigationItems = this.employerNavigationService.navigationItems

  isOpen = false

  destroy$: Subject<boolean> = new Subject<boolean>()
  canManageTeam$ = this.permissionService.userHasPermission(Permission.TEAM_MANAGE)
  canManageCompany$ = this.permissionService.userHasPermission(Permission.COMPANY_MANAGE)

  mobileSideDrawerToggledState$ = this.navLeftService.mobileSideDrawerToggledState$
  routes = ROUTES
  navItemNames = NavItemNames

  constructor(
    private store: Store,
    private scrollLockService: UiScrollLockingService,
    private navLeftService: NavLeftService,
    private employerNavigationService: EmployerNavigationService,
    private router: Router,
    private conversationStore: ConversationStore,
    private permissionService: PermissionService
  ) {
    this.navigationItems = employerNavigationService.navigationItems
  }

  ngOnInit(): void {
    this.mobileSideDrawerToggledState$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        return this.open()
      }

      this.close()
    })

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd && this.isOpen) {
        this.close(true)
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  open() {
    this.isOpen = true
    this.scrollLockService.scrollLock(true)
  }

  close(shouldEmit = false) {
    this.scrollLockService.scrollLock(false)
    this.isOpen = false

    if (shouldEmit) {
      this.navLeftService.mobileSideDrawerToggle$.next(false)
    }
  }

  getNavItem(name: NavItemNames) {
    return this.employerNavigationService.getNavItemByName(name)
  }

  isActiveNavGroup(group: APP_NAVIGATION_LEFT_GROUPS) {
    return this.navLeftService.isCurrentlyActiveGroup(group)
  }

  toggleNavGroup(group: APP_NAVIGATION_LEFT_GROUPS) {
    this.navLeftService.navToggleGroup(group, true)
  }
}
