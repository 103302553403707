import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { CnectConfig, CnectListenConfig } from '../../config'
import { ConfigActionTypes } from './config.actions'
import { ConfigActions } from './config.actions'

export interface State extends EntityState<CnectListenConfig> {
  staticConfigLoaded: boolean
  loaded: boolean
  loading: boolean
  error: [] | null
  listenConfig: CnectListenConfig | null
  staticConfig: CnectConfig | null
}

export const adapter: EntityAdapter<CnectListenConfig> = createEntityAdapter<CnectListenConfig>()

export const initialState: State = adapter.getInitialState({
  staticConfigLoaded: false,
  loaded: false,
  loading: false,
  error: null,
  listenConfig: null,
  staticConfig: null,
})

export function reducer(state = initialState, action: ConfigActions): State {
  switch (action.type) {
    case ConfigActionTypes.onInitConfig:
    case ConfigActionTypes.onGetStaticConfig:
      return { ...state, loading: true }
    case ConfigActionTypes.onGetStaticConfigSuccess:
      return { ...state, staticConfig: action.payload, loaded: true, loading: false, staticConfigLoaded: true }
    case ConfigActionTypes.onListenConfigChanged:
      return { ...state, listenConfig: action.payload, loaded: true, loading: false }
    default:
      return state
  }
}
