<div class="skill-certifications" *ngIf="matchedSkills.length > 0 || matchedCerts.length > 0; else emptyState">
  <!-- Tabs -->
  <div class="row skill-filters">
    <div class="col-xs-12 nopadding">
      <ul class="flex">
        <li
          *ngFor="let filterType of filterTypes"
          class="filter e11-flex-1 e11-p-0"
          (click)="filterCurrent = filterType"
          [ngClass]="{ active: filterCurrent == filterType }"
        >
          {{ translationFilterTypes[filterType] | translate }}
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <p *ngIf="filterCurrent == filterTypeEnum.Exact">
        {{ 'Exact matches to the certifications listed in the job post' | translate }}
      </p>
      <p *ngIf="filterCurrent == filterTypeEnum.Partial">
        {{ 'Partial matches to the certifications listed in the job post' | translate }}
      </p>

      <div *ngIf="filterCurrent == filterTypeEnum.NotListed">
        <p *ngIf="userMode === userModeEnum.Employer">
          {{ 'Certifications the candidate has that were not listed in the job post' | translate }}
        </p>
        <p *ngIf="userMode === userModeEnum.Candidate">
          {{ 'Certifications you have that were not listed in the job post' | translate }}
        </p>
      </div>

      <div *ngIf="filterCurrent == filterTypeEnum.NoMatch">
        <p *ngIf="userMode === userModeEnum.Employer">
          {{ 'Certifications listed in the job post that the candidate does not have' | translate }}
        </p>
        <p *ngIf="userMode === userModeEnum.Candidate">
          {{ 'Certifications listed in the job post that you do not have' | translate }}
        </p>
      </div>

      <!-- Certifications -->
      <!-- <h3 class="text-black">{{ 'Certifications' | translate }}</h3> -->
      <ng-container *ngIf="groupedCertifications[filterCurrent] && groupedCertifications[filterCurrent].length > 0; else noCertificate">
        <div class="candidate-attribute-wrapper">
          <div *ngFor="let certification of groupedCertifications[filterCurrent]">
            <ui-skill [skill]="certification"></ui-skill>
          </div>
        </div>
      </ng-container>

      <ng-template #noCertificate>
        <div class="text-center">
          <h4 class="color-accent-blue">{{ 'No matching certifications' | translate }}</h4>
        </div>
      </ng-template>
      <div class="clearfix"></div>
    </div>
  </div>
</div>

<!-- Pending/open request -->
<request-open *ngIf="processRequests()" [request]="openRequest"></request-open>

<ng-template #emptyState>
  <!-- Empty State / Clickable -->
  <e11-empty-state
    [icon]="'construction'"
    [iconSize]="'md-36'"
    title="{{ 'No Certifications' | translate }}"
    [clickable]="false"
    subtitle="{{ 'The candidate did not add any Certifications.' | translate }}"
  >
  </e11-empty-state>

  <div class="e11-w-full e11-pt-4 e11-text-center">
    <e11-button
      *ngIf="userMode === userModeEnum.Employer && !processRequests()"
      [style]="'pill outlined'"
      color="primary-accent"
      value="{{ 'Request Certifications' | translate }}"
      (click)="!requestsDisabled && requestModal()"
      [disabled]="requestsDisabled"
    >
    </e11-button>
  </div>
  <!-- <div class="empty-state text-center e11-border-2 e11-border-skin-app-borders e11-border-dashed e11-rounded-lg e11-pt-8" *ngIf="!openRequest">
    <h3 class="text-grey italic">{{ 'No Certifications Added' | translate }}</h3>
    <p class="text-grey" *ngIf="userMode === userModeEnum.Candidate">{{ 'You have not added any certificates' | translate }}</p>
    <p class="text-grey" *ngIf="userMode === userModeEnum.Employer">
      {{ 'Candidate has not added any skills or certifications' | translate }}
    </p>

    <e11-button
      *ngIf="userMode === userModeEnum.Employer && !processRequests()"
      [size]="'sm'"
      color="secondary"
      value="{{ 'Request Certifications' | translate }}"
      (click)="!requestsDisabled && requestModal()"
      [disabled]="requestsDisabled"
    >
    </e11-button>
    <div class="spacer-2rem"></div>
  </div> -->
</ng-template>
