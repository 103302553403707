// Framework
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { IRequestedUpdate, RequestedUpdateFields } from '../../../model/candidate-application.model'
import { UserMode } from '../../../model/component-modes.enum'
import { RequestedFieldChanged } from '../../../model/enum'
import { IReference } from '../../../model/interfaces'

// Platform specific

@Component({
  selector: 'candidate-references-profile-share',
  templateUrl: './candidate-references.component.html',
  styleUrls: ['./candidate-references.component.scss'],
})
export class CandidateReferencesProfileShareComponent implements OnInit {
  reference?: IReference
  @Input() references: IReference[] = []
  @Input() requests: IRequestedUpdate[] = []
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode
  openRequest?: RequestedUpdateFields

  constructor() {}
  ngOnInit(): void {
    this.processRequests()
  }

  processRequests() {
    this.openRequest = this.requests.find(
      item => item.requestedFieldChanged === RequestedFieldChanged.References && !item.fulfilled
    )?.requestedFieldChanged
    return this.openRequest
  }

  requestModal() {
    this.requestFromComponent.emit('References')
  }

  displayReference(reference: IReference) {
    this.reference = reference
  }

  close() {
    this.reference = undefined
  }
}
