import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { ListMode, UserMode } from '../../model/component-modes.enum'
import { ISelectableEducation } from '../../model/interfaces'
import { IRequestedUpdate, RequestedUpdateFields } from '../../model/candidate-application.model'
import { MatchType, RequestedFieldChanged } from '../../model/enum'

@Component({
  selector: 'ui-education',
  templateUrl: './ui-education.component.html',
  styleUrls: ['./ui-education.component.scss'],
})
export class UiEducationsComponent implements OnInit {
  listModeEnum = ListMode
  userModeEnum = UserMode
  openRequest?: RequestedUpdateFields

  @Input() listMode: ListMode = ListMode.Horizontal

  @Input() userMode: UserMode = UserMode.Candidate

  @Input() educations: ISelectableEducation[] = []

  @Input() requests: IRequestedUpdate[] = []

  @Input() loading = false

  @Input() selectable = false

  @Output() viewDetail = new EventEmitter()

  @Output() editView = new EventEmitter()

  @Output() addItem = new EventEmitter()

  @Output() deleteItem = new EventEmitter<ISelectableEducation>()

  @Output() selectionChange: EventEmitter<ISelectableEducation[]> = new EventEmitter()

  ngOnInit() {
    this.processRequests()
  }

  select(education: ISelectableEducation) {
    this.selectionChange.emit(this.educations)
  }

  selectAll() {
    this.educations.forEach(education => (education.selected = true))
    this.selectionChange.emit(this.educations)
  }

  deselectAll() {
    this.educations.forEach(education => (education.selected = false))
    this.selectionChange.emit(this.educations)
  }

  processRequests() {
    this.openRequest = this.requests.find(
      item => item.requestedFieldChanged === RequestedFieldChanged.Education && !item.fulfilled
    )?.requestedFieldChanged
    return this.openRequest
  }
}
