import { Injectable } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { TokenStorage } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class UserService {
  private restApiClient: RestApiClient
  constructor(tokenStorage: TokenStorage) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.user,
      token: () => tokenStorage.getAccessToken(),
      throwNon200s: true,
    })
  }

  getCandidateUserSearchKey(): Observable<string> {
    return this.restApiClient.get<string>('search-key').pipe(map(item => item.data))
  }
}
