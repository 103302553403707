import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { CandidateSearchKeyMap, SearchKeyActions, SearchKeyActionTypes } from './search-key.actions'

type SearchKey = string

export interface State extends EntityState<SearchKey> {
  loaded: boolean
  loading: boolean
  error: [] | null
  userSearchKey: string | null
  profileSearchKey: string | null
  postSearchKey: string | null
  postCandidateSearchKeyMap: CandidateSearchKeyMap | null
}

export const adapter: EntityAdapter<SearchKey> = createEntityAdapter<SearchKey>()

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  userSearchKey: null,
  profileSearchKey: null,
  postSearchKey: null,
  postCandidateSearchKeyMap: null,
})

export function reducer(state = initialState, action: SearchKeyActions): State {
  switch (action.type) {
    case SearchKeyActionTypes.getUserSearchKey:
    case SearchKeyActionTypes.getProfileSearchKey:
    case SearchKeyActionTypes.getPostCandidateSearchKey:
    case SearchKeyActionTypes.onInit:
      return {
        ...state,
        loading: true,
      }
    case SearchKeyActionTypes.onInitSuccess:
      return {
        ...state,
        loading: false,
        userSearchKey: action.payload.userSearchKey,
        profileSearchKey: action.payload.jobProfileSearchKey,
        postSearchKey: action.payload.jobPostSearchKey,
        postCandidateSearchKeyMap: action.payload.candidateSearchKeyMap,
      }
    case SearchKeyActionTypes.getUserSearchKeySuccess:
      return {
        ...state,
        loading: false,
        userSearchKey: action.payload,
      }
    case SearchKeyActionTypes.getProfileSearchKeySuccess:
      return {
        ...state,
        loading: false,
        profileSearchKey: action.payload,
      }
    case SearchKeyActionTypes.getPostSearchKeySuccess:
      return {
        ...state,
        loading: false,
        postSearchKey: action.payload,
      }
    case SearchKeyActionTypes.getPostCandidateSearchKeySuccess:
      return {
        ...state,
        loading: false,
        postCandidateSearchKeyMap: { ...state.postCandidateSearchKeyMap, [action.payload.jobPostId]: action.payload },
      }
    case SearchKeyActionTypes.reset:
      return {
        ...state,
        postSearchKey: null,
        userSearchKey: null,
        profileSearchKey: null,
      }
    default:
      return state
  }
}

export const searchKeyEntitySelectors = adapter.getSelectors()
