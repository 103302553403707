<div class="header-container e11-bg-skin-primary e11-w-full">
  <header class="e11-container-full e11-mx-auto">
    <div class="row flex">
      <div class="col-xs-8 nopadding flex-vertical-center">
        <img
          src="../../../../assets/images/logo/cnect-white.png"
          class="pointer"
          width="112"
          height="56"
          alt="{{ 'CNECT - Hiring. Reimagined' | translate }}"
          title="{{ 'CNECT - Hiring. Reimagined' | translate }}"
          aria-describedby="imgLogoCNect"
          [routerLink]="['/home']"
        />
      </div>
      <div class="col-xs-4 flex-align-center-justify-end">
        <!-- <e11-button color="secondary" (click)="signOut()" [style]="''" value="{{ 'Home' | translate }}"> </e11-button> -->
      </div>
    </div>
  </header>
</div>
