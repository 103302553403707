// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { superAuthGuard } from '@engineering11/auth-web'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'actions',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/email-actions/email-actions.module').then(m => m.EmailActionsModule),
      },
    ],
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    ...superAuthGuard(),
  },
  {
    path: 'customer',
    loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
    ...superAuthGuard(),
    // canActivate: [AuthGuard],
  },
  {
    path: 'layout-sidebar',
    loadChildren: () => import('./modules/layout-sidebar/layout-sidebar.module').then(m => m.LayoutSidebarModule),
    ...superAuthGuard(),
  },
  {
    path: 'home',

    // loadChildren: () => import('./ui/modules/home/home.module').then(m => m.HomeModule),
    loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
    ...superAuthGuard(),
    // canActivate: [AuthGuard],
  },
  {
    path: 'c',
    loadChildren: () => import('./modules/layout-container/layout-container.module').then(m => m.LayoutContainerModule),
    ...superAuthGuard(),
  },
  {
    path: 'jobs',
    loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
    ...superAuthGuard(),
    // canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
    ...superAuthGuard(),
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    ...superAuthGuard(),
  },
  {
    path: 'about',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    ...superAuthGuard(),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  // { path: '404', component: FileNotFoundComponent },
  // { path: '**', redirectTo: '404' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      // enableTracing: true, // Comment in for nice router debugging
    }),
  ],
  // providers: [{ provide: RouteReuseStrategy, useClass: CnectReuseStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
