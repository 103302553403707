<div class="container-view">
  <app-header></app-header>
  <main>
    <div class="lg:e11-hidden">
      <app-mobile-side-drawer-menu></app-mobile-side-drawer-menu>
    </div>
    <div class="e11-container-full e11-mx-auto e11-px-4">
      <div class="layout">
        <div class="layout-navigation e11-hidden lg:e11-flex">
          <app-nav-left></app-nav-left>
        </div>
        <!-- <div class="layout-body e11-min-h-[54vh]"> -->
        <div class="layout-body md:e11-pl-6">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
