import { Directive, TemplateRef, Input } from '@angular/core'

@Directive({
  selector: '[uiTemplate]',
  host: {},
})
export class UiTemplate {
  @Input() type: string | undefined

  @Input('uiTemplate') name: string | undefined

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    return this.name as string
  }
}
