<div class="header-container e11-w-full">
  <header class="header e11-container-full e11-mx-auto">
    <div class="e11-w-full e11-flex e11-items-center">
      <div class="e11-w-1/2" [routerLink]="['/home']">
        <img
          src="../../../../assets/images/logo/cnect-blue.png"
          class="pointer"
          width="240"
          alt="{{ 'CNECT - Hiring. Reimagined' | translate }}"
          title="{{ 'CNECT - Hiring. Reimagined' | translate }}"
          aria-describedby="imgLogoCNect"
        />
      </div>
      <div class="e11-w-1/2 e11-flex e11-justify-end">
        <!-- <e11-button [size]="'sm'" [color]="'secondary'" (click)="navigateToCandidate()" [style]="'pill'" value="{{ 'I am a candidate' | translate }}">
        </e11-button> -->
      </div>
    </div>
  </header>
</div>
