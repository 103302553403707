<!-- <div class="e11-w-full">
  <h1 class="nomargin-bottom">{{ 'Company Information' | translate }}</h1>
  <p class="h1-subtitle">{{ 'Please provide the following information about your company' | translate }}</p>
</div>
<e11-panel [hasBorder]="true" [hasShadow]="true">
  <div class="e11-w-full e11-text-center"> -->
<!-- Layout or SDK wrapper -->
<div class="e11-pb-16 e11-w-full e11-flex e11-justify-center">
  <div class="e11-w-3/4 md:e11-w-1/2 lg:e11-w-1/3">
    <!-- Dynamic/Conditional Panel title -->
    <h1 class="e11-text-center e11-text-skin-primary e11-font-skin-century-gothic e11-mb-4">{{ 'Company Information' | translate }}</h1>
    <p class="e11-text-center">{{ 'Please provide the following information about your company.' | translate }}</p>

    <e11-panel [hasBorder]="true" [hasShadow]="false" [contentClassOverrides]="'e11-px-8 e11-py-6'">
      <registration-company-sdk (onError)="onError($event)" (onSubmit)="onSubmit($event)"></registration-company-sdk>
      <div class="loader" *ngIf="loading" class="active">
        <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary-accent'"></e11-loader-dots>
      </div>
    </e11-panel>
  </div>
</div>
