<div class="container-view">
  <app-header></app-header>
  <!-- <app-header-secondary></app-header-secondary> -->

  <main>
    <!-- This is the main content area for the application, including the space for the dynamic left side navugation panel -->
    <!-- <app-nav-side></app-nav-side> -->

    <!-- <div class="container-content" [ngClass]="{'nav-open': sidebarStatus}"> -->
    <div class="e11-container-full container-content block employer-margin-top">
      <loader-view></loader-view>
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- <app-footer [sideNav]="sidebarStatus"></app-footer> -->
  <app-footer></app-footer>
</div>
