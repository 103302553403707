// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Platform specific
import { CustomerComponent } from './customer.component'
import { CustomerHomeComponent } from './views/home/customer-home.component'
import { FileNotFoundComponent } from '../_404/404.component'

import { CustomerLocationDetailComponent } from './views/locations/location-detail/location-detail.component'
import { CustomerLocationListComponent } from './views/locations/location-list/location-list.component'
import { CustomerDepartmentsListComponent } from './views/departments/list/list.component'
import { CustomerDepartmentDetailComponent } from './views/departments/detail/detail.component'
import { CareersPageComponent } from './views/careers-page/careers-page.component'
import { PermissionsGuard } from '@employer/app/guards/permissions.guard'
import { Permission } from '@employer/app/config/permission.config'

const routes: Routes = [
  {
    path: '',
    component: CustomerComponent,
    canActivate: [PermissionsGuard],
    data: { permission: Permission.COMPANY_MANAGE },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CustomerHomeComponent,
      },
      {
        path: 'home',
        component: CustomerHomeComponent,
      },
      {
        path: 'departments',
        component: CustomerDepartmentsListComponent,
      },
      {
        path: 'departments/detail',
        component: CustomerDepartmentDetailComponent,
      },
      {
        path: 'locations',
        component: CustomerLocationListComponent,
      },
      {
        path: 'locations/detail',
        component: CustomerLocationDetailComponent,
      },
      {
        path: 'careers',
        component: CareersPageComponent,
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
