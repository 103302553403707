import { Injectable } from '@angular/core'
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public generalLoading: Subject<boolean> = new Subject()
  constructor(private router: Router) {
    this.watchRouterChanges()
  }

  public setGeneralLoadingStatus(value: boolean): LoadingService {
    this.generalLoading.next(value)
    return this
  }

  private watchRouterChanges(): void {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.setGeneralLoadingStatus(true)
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.setGeneralLoadingStatus(false)
        }
      },
      () => {
        this.setGeneralLoadingStatus(false)
      }
    )
  }
}
