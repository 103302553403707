<!-- Empty State -->
<!-- Populated state -->
<div class="e11-grid e11-grid-cols-1 lg:e11-grid-cols-2">
  <div class="e11-mb-6" *ngFor="let reference of references" (click)="userMode === userModeEnum.Employer && displayReference(reference)">
    <h4 class="">{{ reference?.referenceType }}</h4>
    <div>{{ reference?.firstName }} {{ reference?.lastName }}</div>
    <div>{{ 'Company' | translate }}: {{ reference?.companyName }}</div>
    <div>{{ 'Relationship' | translate }}: {{ reference?.referenceRelationship }}</div>
    <div *ngIf="reference?.email">{{ reference?.email }}</div>
    <div *ngIf="reference?.phone">{{ reference?.phone }}</div>
  </div>
</div>
