// Framework
import { Component, OnDestroy } from '@angular/core'

// Platform specific
import { TokenStorage } from 'shared-lib'

// SDK
import { IAuthService } from '@engineering11/auth-web'

// Third party
import { Subject } from 'rxjs'

@Component({
  selector: 'app-header-landing',
  templateUrl: './header-landing.component.html',
  styleUrls: ['./header-landing.component.scss'],
})
export class AppHeaderLandingComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>()

  loggedIn = false

  constructor(private tokenStorage: TokenStorage, private authService: IAuthService) {
    this.authService.user$.subscribe(user => {
      this.loggedIn = user ? true : false
    })
  }
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  signOut(): void {
    this.authService.signOut()
    this.tokenStorage.clear()
  }
}
