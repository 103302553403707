import { Injectable } from '@angular/core'
import { IJobApplicationView, IJobPostApplicationViews } from '@employer/app/models/job-application-views.model'
import { isNotNil, omit } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { combineLatest, distinctUntilChanged, filter, from, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs'
import { getCurrentUserId } from 'shared-lib'
import { buildApplicationViewsId, JobApplicationViewsRepository } from '../services/job-application-views.repository'

export interface JobApplicationViewsState extends EntityState<IJobPostApplicationViews> {
  lastApplicationViewed: string | null
}

export const applicationViewsAdapter: EntityAdapter<IJobPostApplicationViews> = createEntityAdapter<IJobPostApplicationViews>({
  selectId: views => views.jobPostId, // Because these will be unique per user
})

const initialState: JobApplicationViewsState = applicationViewsAdapter.getInitialState({ lastApplicationViewed: null })
const applicationViewsSelectors = applicationViewsAdapter.getSelectors()

@Injectable({
  providedIn: 'root',
})
export class JobApplicationViewsStore extends ComponentStore<JobApplicationViewsState> {
  private userId$ = this.store.pipe(getCurrentUserId).pipe(filter(isNotNil), distinctUntilChanged())

  constructor(
    private store: Store,
    private applicationViewsRepository: JobApplicationViewsRepository,
    private logger: E11Logger,
    private errorHandler: E11ErrorHandlerService
  ) {
    super(initialState)
  }

  // Selectors
  readonly applicationViewsForJob$ = (jobPostId: string) => this.select(s => applicationViewsSelectors.selectEntities(s)[jobPostId])
  readonly applicationViewedForJob$ = (jobPostId: string, applicationId: string) =>
    this.select(s => applicationViewsSelectors.selectEntities(s)[jobPostId]?.applicationIds.includes(applicationId) ?? false)

  readonly lastApplicationViewed$ = this.select(s => s.lastApplicationViewed)

  // Effects

  readonly onGetForJobPostIfNotFetched = this.effect((jobPostId$: Observable<string>) =>
    jobPostId$.pipe(
      switchMap(jobPostId => {
        return this.applicationViewsForJob$(jobPostId).pipe(
          take(1),
          tap(views => {
            if (!views) this.onGetForJobPost(jobPostId)
            else this.logger.debug(`Store: views already loaded for job ${jobPostId}, no need to re-fetch`)
          })
        )
      })
    )
  )

  readonly onGetForJobPost = this.effect((jobPostId$: Observable<string>) =>
    combineLatest([jobPostId$, this.userId$]).pipe(
      switchMap(([jobPostId, userId]) => {
        return this.applicationViewsRepository.getForUserByJob(jobPostId, userId).pipe(
          tapResponse(
            response => {
              if (response) this.onViewsFetchedForJob(response)
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly onMarkApplicationViewed = this.effect((view$: Observable<Omit<IJobApplicationView, 'userId'>>) =>
    combineLatest([this.userId$, view$]).pipe(
      map(([userId, view]) => ({ ...view, userId })),
      mergeMap(view => {
        this.updateLastApplicationViewed(view.applicationId)
        // skip write if already written
        const alreadyViewed$ = this.applicationViewsForJob$(view.jobPostId).pipe(
          map(views => views?.applicationIds?.includes(view.applicationId) ?? false),
          take(1)
        )
        return alreadyViewed$.pipe(
          switchMap(alreadyViewed => {
            if (alreadyViewed) {
              this.logger.debug(`Store onMarkApplicationViewed: already viewed application ${view.applicationId} for job ${view.jobPostId}`)
              return of()
            }
            return from(this.applicationViewsRepository.markApplicationViewed(view)).pipe(
              tapResponse(
                response => this.onApplicationViewed(view),
                (error: Error) => this.errorHandler.handleError(error)
              )
            )
          })
        )
      })
    )
  )

  // Reducers

  private readonly onViewsFetchedForJob = this.updater((state, views: IJobPostApplicationViews) =>
    applicationViewsAdapter.setOne(views, { ...state, loaded: true })
  )

  private readonly onApplicationViewed = this.updater((state, view: IJobApplicationView) => {
    const views = state.entities[view.jobPostId]

    const newViews: IJobPostApplicationViews = views
      ? { ...views, applicationIds: views.applicationIds.concat([view.applicationId]) }
      : viewToViews(view)
    return applicationViewsAdapter.upsertOne(newViews, state)
  })

  private updateLastApplicationViewed = this.updater((state, applicationId: string) => ({ ...state, lastApplicationViewed: applicationId }))
}

function viewToViews(view: IJobApplicationView): IJobPostApplicationViews {
  const id = buildApplicationViewsId(view)
  return { ...omit(view, 'applicationId'), id, applicationIds: [view.applicationId] }
}
