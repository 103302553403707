<!-- Empty State -->
<e11-empty-state
  *ngIf="!loading && workHistories.length === 0"
  [icon]="'work_outline'"
  [iconSize]="'md-36'"
  title="{{ 'No Work History' | translate }}"
  subtitle="{{
    userMode === userModeEnum.Candidate ? 'Add some work experience to your profile.' : ('Candidate did not supply any Work History.' | translate)
  }}"
  [clickable]="userMode === userModeEnum.Candidate"
  (click)="userMode === userModeEnum.Candidate && this.addItem.emit()"
>
</e11-empty-state>

<ng-container *ngIf="workHistories.length > 0">
  <ng-container *ngIf="listMode === listModeEnum.Horizontal">
    <p class="e11-flex e11-items-center e11-text-skin-light">
      <span class="material-icons-outlined md-18 e11-text-skin-secondary e11-mr-1"> info </span
      >{{ 'Adding and removing items here only affect this application.' | translate }}
    </p>
    <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 lg:e11-grid-cols-3 xl:e11-grid-cols-4 e11-gap-4">
      <ng-container *ngFor="let workHistory of workHistories">
        <ui-work-history-line-item-card
          [workHistory]="workHistory"
          (viewDetail)="viewDetail.emit($event)"
          (editView)="editView.emit($event)"
          [removable]="true"
          (deleteItem)="deleteItem.emit($event)"
        >
        </ui-work-history-line-item-card>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="listMode === listModeEnum.Vertical">
    <div class="e11-block">
      <ng-container *ngFor="let workHistory of workHistories">
        <ui-work-history-line-item
          [userMode]="userMode"
          [removable]="true"
          [workHistory]="workHistory"
          (viewDetail)="viewDetail.emit($event)"
          (editView)="editView.emit($event)"
          (deleteItem)="deleteItem.emit($event)"
        >
        </ui-work-history-line-item>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
